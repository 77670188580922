import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useGetClicksQuery } from "../../../../slices/apiSlices/advertiserApi";

const Clicks = () => {
	const [filterText, setFilterText] = useState("");
	const { data, error, isLoading, isSuccess } =
		useGetClicksQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});

	if (isLoading) {
		return (
			<div id="route-view" className="col-md-12">
				Loading...
			</div>
		);
	}
	if (error) {
		return (
			<div id="route-view" className="col-md-12">
				{" "}
				{error?.data?.msg}
			</div>
		);
	}

	if (!isSuccess) {
		return (
			<div id="route-view" className="col-md-12">
				Unable to fetch data.
			</div>
		);
	}
	const userData = data?.data;

	const formattedData = Array.isArray(userData)
		? userData?.map((user, index) => {
			return {
				id: index + 1,
				_id: user?._id,
				name: (
					<Link to={``} className="profile-view">
						{user?.name}
					</Link>
				),
				clicks: user?.clicks,
				conversions: user?.conversions,
			};
		})
		: [];
	const columns = [
		{
			name: "ID",
			selector: (row) => row.id,
			sortable: true,
		},
		{
			name: "Offer",
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: "Clicks",
			selector: (row) => row.clicks,
			sortable: true,
		},
		{
			name: "Conversions",
			selector: (row) => row.conversions,
		},
	];

	const handleFilter = (event) => {
		const value = event.target.value || "";
		setFilterText(value);
	};

	const filteredData = formattedData.filter((item) => {
		const name = item.name.props.children || "";

		return name.toLowerCase().includes(filterText.toLowerCase());
	});

	return (
		<div id="route-view" className="col-md-12">
			<div data-v-a5b54c2a="">
				<h2 data-v-a5b54c2a="" className="heading">
					Clicks
				</h2>
				<div data-v-a5b54c2a="" className="row mt-4">
					<div data-v-a5b54c2a="" className="col-md-12">
						<div data-v-a5b54c2a="" className="dataTables_wrapper dt-bootstrap">
							<div className="row form-inputs">
								<div className="col-sm-4">
									<div className="alignCenter">
										<input
											data-v-a5b54c2a=""
											type="text"
											className="date-range-picker"
										/>
										<button
											data-v-a5b54c2a=""
											className="btn btn-default btn-sm clear-filter-button btn-outline-primary input-button-sm"
										>
											Generate Report
										</button>
									</div>
								</div>
								<div className="offset-sm-4 col-sm-4">
									<input
										type="text"
										placeholder="Search Name"
										className="form-control"
										value={filterText}
										onChange={handleFilter}
									/>
								</div>
							</div>
							<div className="dataTable">
								<DataTable
									title=""
									columns={columns}
									data={filteredData}
									pagination
									highlightOnHover
									fixedHeader
									paginationPerPage={10}
									customStyles={{
										header: {
											style: {
												display: "flex",
												justifyContent: "center",
											},
										},
										rows: {
											style: {
												display: "flex",
												justifyContent: "center",
											},
										},
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Clicks;