import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import {
	useAdvertiserAddOfferMutation,
} from "../../../../slices/apiSlices/advertiserApi";
import dayjs from "dayjs";
import {
	useGetAllCountryQuery,
	useGetAllDevicesQuery,
	useGetAllMediaTypesQuery,
	useGetAllPriceFormatsQuery,
	useGetAllVerticlesQuery,
	useGetPredefinedUrlMutation,
	useGetAllTrackingDomainQuery,
	useUploadAttachmentMutation,
} from "../../../../slices/apiSlices/otherApi";
import { CircularProgress } from "@mui/material";

function AddOffers() {
	const { data: devices } = useGetAllDevicesQuery(null, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});
	const { data: trackingDomain } =
		useGetAllTrackingDomainQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});
	const { data: price_formats } =
		useGetAllPriceFormatsQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});
	const { data: verticles } =
		useGetAllVerticlesQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});
	const { data: media_types } =
		useGetAllMediaTypesQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});
	const { data: countries } = useGetAllCountryQuery(null, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});

	const navigate = useNavigate();
	// const dispatch = useDispatch();
	const [advertiserAddOffer, { isLoading: AddLoading }] = useAdvertiserAddOfferMutation();
	const [getPredefinedUrl] = useGetPredefinedUrlMutation();
	const [uploadAttachment] = useUploadAttachmentMutation();
	// const [updateImage] = useUpdateImageMutation();

	const [selectedCountry, setSelectedCountry] = useState([]);
	const [selectedMediaTypes, setSelectedMediaTypes] = useState([]);
	const [offerDetailsOpen, setOfferDetailsOpen] = useState(true);
	const [revenueOpen, setRevenueOpen] = useState(false);
	const [payoutOpen, setPayoutOpen] = useState(false);
	const [settingsOpen, setSettingsOpen] = useState(false);
	const [trackingOpen, setTrackingOpen] = useState(false);
	const [resourceOpen, setResourceOpen] = useState(false);
	const [selectAllMediaTypes, setSelectAllMediaTypes] = useState(false);
	const [selectAllCountries, setSelectAllCountries] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [enableCaps, setEnableCaps] = useState(false);
	const [imageUpload, setImageUpload] = useState({});
	const [isLoadingImage, setIsLoadingImage] = useState(false);
	const imgBaseUrlS3 = process.env.REACT_APP_IMAGE_BASE_URL;
	const imgBaseUrl = process.env.REACT_APP_API_URL;

	const [error, setError] = useState({});

	const [formData, setFormData] = useState({
		name: "",
		currency: "",
		devices: "",
		verticle_id: "",
		media_type: "",
		countries: "",
		image: "",
		description: "",
		preview_url: "",
		destination_url: "",
		resources: {},
		expiry_date: "",
		internal_notes: "",
		revenue_type: "rpc",
		revenue_value: "",
		visibility: "public",
		subId: "",
		require_terms_conditions: "disable",
		caps: "disable",
		offer_caps: {
			click_caps: { daily: 0, weekly: 0, monthly: 0, global: 0 },
			conversion_caps: { daily: 0, weekly: 0, monthly: 0, global: 0 },
			payout_caps: { daily: 0, weekly: 0, monthly: 0, global: 0 },
		},
		tracking: {
			tracking_domain: "",
			conversion_method: "cookie_based",
			force_ssl: 1,
			enable_deep_link: 1,
			unique_session_identifier: "cookies",
			unique_session_duration: 0,
			unique_session_interval: "hours",
			enable_duplicate_filter: 0,
		},
	});

	const [resourceInputs, setResourceInputs] = useState([
		{ type: "", value: "" },
	]);

	const attachmentUpload = async (formData) => {
		const { data } = await uploadAttachment(formData);
		return data;
	};

	const handleFileChange = async (index, e) => {
		const file = e.target.files[0];
		if (!file) return;

		setIsLoadingImage(true);
		const formData = new FormData();
		formData.append("attachment", file);
		formData.append("old_file_path", resourceInputs[index].value);
		formData.append("type_of_attachment", resourceInputs[index].type);
		try {
			const data = await attachmentUpload(formData);
			if (data.success === false) {
				toast.error(data.errors[0].message);
			} else {
				toast.success(data.message);
				const filePath = data?.data || null; //  server returns the file path
				const updatedResources = [...resourceInputs];
				updatedResources[index].value = filePath;
				setResourceInputs(updatedResources);
			}
		} catch (error) {
			console.error("Error uploading file:", error);
		} finally {
			setIsLoadingImage(false);
		}
	};

	const handleResourceChange = (index, key, value) => {
		const updatedResources = [...resourceInputs];
		updatedResources[index][key] = value;
		setResourceInputs(updatedResources);
	};

	const handleAddResource = () => {
		setResourceInputs([...resourceInputs, { type: "", value: "" }]);
	};

	const handleRemoveResource = async (index) => {
		const updatedResources = resourceInputs.filter((_, i) => i !== index);
		setResourceInputs(updatedResources);
		if (resourceInputs[index].value) {
			const formData = new FormData();
			formData.append("attachment", null);
			formData.append("old_file_path", resourceInputs[index].value);
			formData.append("type_of_attachment", resourceInputs[index].type);
			const data = await attachmentUpload(formData);
			if (data.success === false) {
				toast.error(data.message);
			} else {
				toast.success(data.message);
			}
		}
	};

	const colourStyles = {
		control: (styles) => ({ ...styles, backgroundColor: "white" }),
		multiValue: (styles) => {
			return {
				...styles,
				backgroundColor: "#609bbd",
				color: "white !important",
			};
		},
		multiValueLabel: (styles) => {
			return {
				...styles,
				color: "white !important",
			};
		},
	};
	const mediaOptions = [];
	const countriesOptions = [];
	const deviceOptions = [];

	useEffect(() => {
		countries?.data.forEach((element) => {
			countriesOptions.push({
				color: "#609bbd",
				value: element.name,
				label: element.name,
			});
		});
		media_types?.data?.forEach((element) => {
			mediaOptions.push({
				color: "#609bbd",
				value: element._id,
				label: element.name,
			});
		});
		devices?.data.forEach((element) => {
			deviceOptions.push({
				color: "#609bbd",
				value: element._id,
				label: element.name,
			});
		});
	}, [countriesOptions, mediaOptions, deviceOptions]);

	useEffect(() => {
		if (selectAllCountries) {
			setFormData({
				...formData,
				countries: [],
			});
		} else {
			setFormData({
				...formData,
				countries: "",
			});
		}
	}, [selectAllCountries]);

	// useEffect(() => {
	// 	if (imageUpload?.url_to_save?.length > 0) {
	// 		setFormData({
	// 			...formData,
	// 			image: imgBaseUrlS3 + imageUpload.url_to_save,
	// 		});
	// 	}
	// }, [imageUpload]);

	useEffect(() => {
		if (imageUpload) {
			setFormData({
				...formData,
				image: imageUpload,
			});
		}
	}, [imageUpload]);

	useEffect(() => {
		if (selectAllMediaTypes) {
			setFormData({
				...formData,
				media_type: [],
			});
		} else {
			setFormData({
				...formData,
				media_type: "",
			});
		}
	}, [selectAllMediaTypes]);

	const handleDateChange = (date) => {
		const currentDate = dayjs();
		if (
			dayjs(date).isAfter(currentDate) ||
			dayjs(date).isSame(currentDate, "day")
		) {
			if (error?.data) {
				setError((prevError) => ({
					...prevError,
					data: prevError?.data?.filter((item) => item.field !== "expiry_date"),
				}));
			}
			setFormData({
				...formData,
				expiry_date: dayjs(date).format("MM-DD-YYYY"),
			});
		}
	};

	const handleCapsChange = (e) => {
		const newValue = e.target.value === "enable";
		setEnableCaps(newValue);
		if (error?.data) {
			setError((prevError) => ({
				...prevError,
				data: prevError?.data?.filter((item) => item.field !== "caps"),
			}));
		}
		setFormData({
			...formData,
			caps: e.target.value,
		});
	};
	const handledeviceChange = (event) => {
		let values = new Set();
		if (error?.data) {
			setError((prevError) => ({
				...prevError,
				data: prevError?.data?.filter((item) => item.field !== "devices"),
			}));
		}
		setFormData({
			...formData,
			devices: "",
		});
		event.forEach((e) => {
			values.add(e.value);
			setFormData({
				...formData,
				devices: [...values],
			});
		});
	};

	const toggleMediaTypes = () => {
		if (error?.data) {
			setError((prevError) => ({
				...prevError,
				data: prevError?.data?.filter((item) => item.field !== "media_type"),
			}));
		}
		setSelectAllMediaTypes(!selectAllMediaTypes);
		setSelectedMediaTypes(null);
	};
	const handleMediaTypesChange = (event) => {
		let values = new Set();
		if (error?.data) {
			setError((prevError) => ({
				...prevError,
				data: prevError?.data?.filter((item) => item.field !== "media_type"),
			}));
		}
		setFormData({
			...formData,
			media_type: "",
		});
		event.forEach((e) => {
			values.add(e.value);
			setFormData({
				...formData,
				media_type: [...values],
			});
		});
	};

	const toggleCountries = () => {
		if (error?.data) {
			setError((prevError) => ({
				...prevError,
				data: prevError?.data?.filter((item) => item.field !== "countries"),
			}));
		}
		setSelectAllCountries(!selectAllCountries);
		setSelectedCountry(null);
	};
	const handleCountriesChange = (event) => {
		let values = new Set();
		if (error?.data) {
			setError((prevError) => ({
				...prevError,
				data: prevError?.data?.filter((item) => item.field !== "countries"),
			}));
		}
		setFormData({
			...formData,
			countries: "",
		});
		event.forEach((e) => {
			const countryIsoCode = countries?.data?.find(
				(country) => country.name === e.value
			)?.isoCode;
			values.add(countryIsoCode);
			setFormData({
				...formData,
				countries: [...values],
			});
		});
	};

	const convertToRevenue = (price) => {
		const revenueValue = price.name.replace('c', 'r');
		const revenueName = price.name;
		const revenueFullName = price.full_name;
		return { ...price, name: revenueName, full_name: revenueFullName, value: revenueValue };
	};

	// Map the array with the conversion function
	const revenueOptions = price_formats?.data.map((price, index) => {
		const revenuePrice = convertToRevenue(price);
		return (
			<option key={index} value={revenuePrice.value}>
				{revenuePrice.full_name}-({revenuePrice.name.toUpperCase()})
			</option>
		);
	});
	// const handleImage = async (event) => {
	// 	if (error?.data) {
	// 		setError((prevError) => ({
	// 			...prevError,
	// 			data: prevError?.data?.filter((item) => item.field !== "image"),
	// 		}));
	// 	}
	// 	setIsLoadingImage(true);
	// 	const file = event.target.files[0];
	// 	if (file) {
	// 		if (Object.keys(imageUpload).length) {
	// 			if (imageUpload && imageUpload.expiresAt >= new Date().getTime()) {
	// 				try {
	// 					await uploadImageToS3(imageUpload.image_upload_url, file);
	// 					setPreviewImage(
	// 						`${imgBaseUrlS3}${
	// 							imageUpload.url_to_save
	// 						}?timestamp=${new Date().getTime()}`
	// 					);
	// 				} catch (error) {
	// 					console.error("Error uploading image", error);
	// 				} finally {
	// 					setIsLoadingImage(false);
	// 				}
	// 			} else {
	// 				try {
	// 					const { data } = await getPredefinedUrl(file.type);
	// 					if (data.success) {
	// 						const { image_upload_url, url_to_save, expiresAt } = data.data;
	// 						await uploadImageToS3(image_upload_url, file);
	// 						setImageUpload({
	// 							image_upload_url,
	// 							url_to_save,
	// 							expiresAt,
	// 						});
	// 						setPreviewImage(imgBaseUrlS3 + url_to_save);
	// 					}
	// 				} catch (error) {
	// 					console.error("Error uploading image", error);
	// 				} finally {
	// 					setIsLoadingImage(false);
	// 				}
	// 			}
	// 		} else {
	// 			try {
	// 				const { data } = await getPredefinedUrl(file.type);
	// 				if (data.success) {
	// 					const { image_upload_url, url_to_save, expiresAt } = data.data;
	// 					await uploadImageToS3(image_upload_url, file);
	// 					setImageUpload({
	// 						image_upload_url,
	// 						url_to_save,
	// 						expiresAt,
	// 					});
	// 					setPreviewImage(imgBaseUrlS3 + url_to_save);
	// 				}
	// 			} catch (error) {
	// 				console.error("Error uploading image", error);
	// 			} finally {
	// 				setIsLoadingImage(false);
	// 			}
	// 		}
	// 	}
	// };
	const thumnailimagepath = formData?.image;
	const handleImage = async (event) => {
		const file = event.target.files[0];
		setIsLoadingImage(true);
		const formData = new FormData();
		formData.append("attachment", file);
		formData.append("old_file_path", thumnailimagepath);
		formData.append("type_of_attachment", "images");
		try {
			const data = await attachmentUpload(formData);

			if (data.success === false) {
				toast.error(data.errors[0].message);
			} else {
				toast.success(data.message);
				const filePath = data?.data || null; //  server returns the file path
				setImageUpload(filePath);
				setPreviewImage(imgBaseUrl + filePath);
			}
		} catch (error) {
			console.error("Error uploading file:", error);
		} finally {
			setIsLoadingImage(false);
		}
	};

	const uploadImageToS3 = async (uploadUrl, file) => {
		try {
			await fetch(uploadUrl, {
				method: "PUT",
				body: file,
			});
		} catch (error) {
			console.error("Image upload failed", error);
		}
	};

	const toggleOfferDetails = () => {
		setOfferDetailsOpen(!offerDetailsOpen);
	};
	const toggleRevenue = () => {
		setRevenueOpen(!revenueOpen);
	};

	const togglePayout = () => {
		setPayoutOpen(!payoutOpen);
	};
	const toggleSettings = () => {
		setSettingsOpen(!settingsOpen);
	};
	const toggleTracking = () => {
		setTrackingOpen(!trackingOpen);
	};

	const toggleResource = () => {
		setResourceOpen(!resourceOpen);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (error?.data) {
			setError((prevError) => {
				const updatedError = {
					...prevError,
					data: prevError?.data?.filter((item) => item.field !== name),
				};
				return updatedError;
			});
		}
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const resourceData = {
			images: resourceInputs
				.filter((res) => res.type === "images")
				.map((res) => res.value),
			video: resourceInputs.find((res) => res.type === "video")?.value,
			docs: resourceInputs.find((res) => res.type === "docs")?.value,
			landing_page: resourceInputs
				.filter((res) => res.type === "landing_page")
				.map((res) => res.value),
		};
		const updatedFormData = {
			...formData,
			resources: resourceData,
		};

		if (updatedFormData.caps === "enable") {
			// If caps is enabled, include offer_caps in form data
			const res = await advertiserAddOffer({ updatedFormData });
			if (res.error && res.error.data.errors) {
				const errors = res.error.data.errors;
				setError({ data: errors });
				if (errors.length > 4) {
					toast.error("Please fill in all the required fields.");
				} else {
					errors.forEach((err) => toast.error(err.message));
				}
			} else {
				if (res?.data?.success === true) {
					toast.success(res.data.msg);
					navigate("/advertiser/offer");
				} else {
					toast.info(res.error.data.msg);
				}
			}
		} else {
			// If caps is disabled, exclude offer_caps from form data
			const { offer_caps, ...formDataWithoutOfferCaps } = updatedFormData;
			const res = await advertiserAddOffer({
				updatedFormData: formDataWithoutOfferCaps
			});

			// Handle the response as needed
			if (res.error && res.error.data.errors) {
				const errors = res.error.data.errors;
				setError({ data: errors });
				if (errors.length > 4) {
					toast.error("Please fill in all the required fields.");
				} else {
					errors.forEach((err) => toast.error(err.message));
				}
			} else {
				if (res?.data?.success === true) {
					toast.success(res.data.msg);
					navigate("/advertiser/offer");
				} else {
					toast.info(res.error.data.msg);
				}
			}
		}
	};

	const renderInputField = (resource, index) => {
		return (
			<div key={index} className="form-group row">
				<div className="col-3">
					<label className="required">Resource Type</label>
					<select
						value={resource.type}
						onChange={(e) =>
							handleResourceChange(index, "type", e.target.value)
						}
						className="form-control"
					>
						<option value="">Select Resource Type</option>
						<option value="landing_page">Landing Page</option>
						<option value="images">Images</option>
						<option value="video">Video</option>
						<option value="docs">Docs</option>
					</select>
				</div>
				<div className="col-5">
					<label className="required">Resource Value</label>
					{resource.type === "images" || resource.type === "landing_page" ? (
						<div className="input-group">
							<input
								type="file"
								onChange={(e) => handleFileChange(index, e)}
								className="form-control"
							// value={resource.value}
							/>
						</div>
					) : (
						<input
							type="text"
							value={resource.value}
							onChange={(e) =>
								handleResourceChange(index, "value", e.target.value)
							}
							className="form-control"
							disabled={!resource.type}
						/>
					)}
				</div>
				{/* <div className="col-2">
					{resource.value && (
						<div className="">
							{resource.value.endsWith(".zip") ? (
								<p>ZIP file uploaded: {imgBaseUrl + resource.value}</p>
							) : (
								<img
									src={imgBaseUrl + resource.value}
									alt="Offer Image"
									height="120"
									width="120"
									style={{
										display: `${
											resource.value.length >= 1 ? "inline" : "none"
										}`,
										objectFit: "contain",
									}}
								/>
							)}
						</div>
					)}
				</div> */}
				<div className="col-2">
					{resource.value &&
						(resource.type === "images" ||
							resource.type === "landing_page") && (
							<div className="">
								{resource.value.endsWith(".zip") ? (
									<p>File Uploaded</p>
								) : (
									<img
										src={imgBaseUrl + resource.value}
										alt="Offer Image"
										height="120"
										width="100%"
										style={{
											display: `${resource.value.length >= 1 ? "inline" : "none"
												}`,
											objectFit: "contain",
										}}
									/>
								)}
							</div>
						)}
				</div>
				<div className="col-2">
					<button
						type="button"
						onClick={() => handleRemoveResource(index)}
						className="btn btn-danger"
					>
						-
					</button>
				</div>
			</div>
		);
	};

	const renderError = (fieldName) => {
		const errorItem = error?.data?.find((item) => item.field === fieldName);
		return errorItem ? (
			<div key={fieldName} className="text-danger">
				{errorItem.message}
			</div>
		) : null;
	};

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading offer">Add Offer</h2>
				<form className="offer" onSubmit={handleSubmit}>
					<div className="form-panel offer">
						<h3
							onClick={toggleOfferDetails}
							className={offerDetailsOpen ? "heading" : "heading collapsed"}
							style={
								offerDetailsOpen
									? {
										cursor: "pointer",
										color: "black",
										display: "flex",
										justifyContent: "space-between",
									}
									: {
										cursor: "pointer",
										display: "flex",
										justifyContent: "space-between",
									}
							}
						>
							<span>
								Details
								<small className="text-muted">
									(Fill out the important details of offer)
								</small>
							</span>
							<i>
								<FontAwesomeIcon
									className="icon"
									icon={offerDetailsOpen ? faMinus : faPlus}
								/>
							</i>
						</h3>
						<Collapse in={offerDetailsOpen} className="">
							<div id="offerDetails" className="">
								<div className="form-group">
									<label className="required">Name / Offer Title</label>
									<input
										className={`form-control border  ${formData.name.trim() === "" ? "border-danger" : ""
											}`}
										name="name"
										value={formData.name}
										onChange={handleChange}
									/>
									<small className="form-text info-text">
										{renderError("name")}
									</small>
								</div>
								<div className="form-group row">
									<div className="col-sm-6">
										<label className="required">Price Format</label>
										<select
											className={`form-control border  ${formData.currency.trim() === "" ? "border-danger" : ""
												}`}
											name="currency"
											value={formData.currency}
											onChange={handleChange}
										>
											<option disabled="disabled" value="">
												Select Price Format
											</option>
											{price_formats?.data.map((price, index) => (
												<option key={index} value={price._id}>
													{price.name.toUpperCase()} {price.full_name}
												</option>
											))}
										</select>
										<small className="form-text info-text">
											{renderError("currency")}
										</small>
									</div>
									<div className="col-sm-6">
										<label className="required">Devices / Channels</label>
										<Select
											isMulti
											name="devices"
											options={deviceOptions}
											className="basic-multi-select"
											classNamePrefix="select"
											styles={colourStyles}
											onChange={(event) => {
												handledeviceChange(event);
											}}
										/>
										<small className="form-text info-text">
											{renderError("devices")}
										</small>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-sm-6">
										<label className="required">Verticle</label>
										<select
											className="form-control"
											name="verticle_id"
											value={formData.verticle_id}
											onChange={handleChange}
										>
											<option disabled="disabled" value="">
												Select Verticle
											</option>
											{verticles?.data.map((vert, index) => (
												<option key={index} value={vert._id}>
													{vert.name}
												</option>
											))}
										</select>
										<small className="form-text info-text">
											{renderError("verticle_id")}
										</small>
									</div>
									<div className="col-sm-6">
										<div
											style={{
												width: "100%",
												display: "flex",
												flexDirection: "column",
											}}
										>
											<div style={{ width: "100%" }}>
												<label className="required">Media Types</label>
												<span className="pull-right">
													<label htmlFor="allMediaTypes">
														<input
															id="allMediaTypes"
															type="checkbox"
															name="all-media-types"
															checked={selectAllMediaTypes}
															style={{
																height: "unset",
															}}
															onChange={toggleMediaTypes}
														/>
														All
													</label>
												</span>
											</div>
											<Select
												isMulti
												name="media_type"
												options={mediaOptions}
												className="basic-multi-select"
												classNamePrefix="select"
												value={selectAllMediaTypes ? null : selectedMediaTypes}
												styles={colourStyles}
												onChange={(event) => {
													setSelectedMediaTypes(event);
													handleMediaTypesChange(event);
												}}
												isDisabled={selectAllMediaTypes ? true : false}
												placeholder={
													selectAllMediaTypes
														? "All Selected"
														: "Selected media type"
												}
											/>
										</div>
										<small className="form-text info-text">
											{renderError("media_type")}
										</small>
									</div>
								</div>
								<div className="form-group">
									<div
										style={{
											width: "100%",
											display: "flex",
											flexDirection: "column",
										}}
									>
										<div style={{ width: "100%" }}>
											<label className="required">Target Countries</label>
											<span className="pull-right">
												<label htmlFor="allcountries">
													<input
														id="allMediaTypes"
														type="checkbox"
														name="all-countries"
														checked={selectAllCountries}
														style={{
															height: "unset",
														}}
														onChange={toggleCountries}
													/>
													All
												</label>
											</span>
										</div>
										<Select
											isMulti
											name="countries"
											options={countriesOptions}
											className="basic-multi-select"
											classNamePrefix="select"
											value={selectAllCountries ? null : selectedCountry}
											styles={colourStyles}
											onChange={(event) => {
												setSelectedCountry(event);
												handleCountriesChange(event);
											}}
											isDisabled={selectAllCountries ? true : false}
											placeholder={
												selectAllCountries
													? "All Selected"
													: "Selected Countries"
											}
										/>
									</div>
									<small className="form-text info-text">
										{renderError("countries")}
									</small>
								</div>
								<div className="form-group row justify-content-center">
									<div className="col-sm-6">
										<label className="required">Thumbnail</label>
										<input
											type="file"
											name="image"
											onChange={(event) => handleImage(event)}
											className="form-control"
										/>
										<small className="form-text info-text">
											{renderError("image")}
										</small>
									</div>
									<div className="col-sm-6 text-center">
										{isLoadingImage ? (
											<div>
												<CircularProgress style={{ color: "#609bbd" }} />
											</div>
										) : (
											<img
												src={previewImage}
												alt="Offer Image"
												height="120"
												width="220"
												style={{
													display: `${previewImage.length >= 1 ? "inline" : "none"
														}`,
													objectFit: "contain",
												}}
											/>
										)}
									</div>
								</div>
								<div className="form-group">
									<label className="required">Description</label>
									<textarea
										rows="7"
										name="description"
										value={formData.description}
										onChange={handleChange}
										className="form-control"
									></textarea>
									<small className="form-text info-text">
										{renderError("description")}
									</small>
								</div>
								<div className="form-group">
									<label className="required">Preview URL</label>
									<textarea
										rows="3"
										name="preview_url"
										value={formData.preview_url}
										onChange={handleChange}
										className="form-control"
									></textarea>
									<small className="form-text info-text">
										{renderError("preview_url")}
									</small>
									<small className="form-text info-text">
										Link to landing page with no geotargeting so publishers can
										see landing page example
									</small>
								</div>
								<div className="form-group">
									<label className="required">Base Destination URL</label>
									<textarea
										rows="3"
										name="destination_url"
										value={formData.destination_url}
										onChange={handleChange}
										className="form-control"
									></textarea>
									<small className="form-text info-text">
										{renderError("destination_url")}
									</small>
									<small className="form-text info-text">
										The Offer URL where traffic will be directed to. You must
										specify at least the Default Offer URL
									</small>
								</div>
								<div className="form-group">
									<label className="required">Sub ID</label>
									<input
										rows="3"
										name="subId"
										value={formData.subId}
										onChange={handleChange}
										className="form-control"
									></input>
									<small className="form-text info-text">
										{renderError("subId")}
									</small>
								</div>
								<div className="form-group">
									<label className="">Expiry Date</label>
									<div className="form-inline">
										<input
											name="expiry_date"
											type="date"
											min={new Date().toISOString().split("T")[0]}
											onChange={(event) => {
												handleDateChange(event.target.value);
											}}
										/>
									</div>
									<small className="form-text info-text">
										{renderError("expiry_date")}
									</small>
									<small className="form-text info-text">
										Offer will expire at 11:59 pm of selected date
									</small>
								</div>
								<div className="form-group">
									<label>Internal Notes</label>
									<textarea
										rows="3"
										name="internal_notes"
										value={formData.internal_notes}
										onChange={handleChange}
										className="form-control"
									></textarea>
									<small className="form-text info-text">
										{renderError("internal_notes")}
									</small>
									<small className="form-text info-text">
										The contents of this note will not be displayed to
										publishers
									</small>
								</div>
							</div>
						</Collapse>
					</div>
					<div className="form-panel offer">
						<h3
							onClick={toggleRevenue}
							className={revenueOpen ? "heading" : "heading collapsed"}
							style={
								revenueOpen
									? {
										cursor: "pointer",
										color: "black",
										display: "flex",
										justifyContent: "space-between",
									}
									: {
										cursor: "pointer",
										display: "flex",
										justifyContent: "space-between",
									}
							}
						>
							<span>
								Payout
								<small className="text-muted">
									(The amount paid by advertisers per conversion)
								</small>
							</span>
							<i>
								<FontAwesomeIcon
									className="icon"
									icon={revenueOpen ? faMinus : faPlus}
								/>
							</i>
						</h3>
						<Collapse in={revenueOpen} className="">
							<div id="revenue" className="">
								<div className="form-group">
									<label className="required">Payout Type</label>
									<select
										name="revenue_type"
										value={formData.revenue_type}
										onChange={handleChange}
										className="form-control"
									>
										{revenueOptions}
									</select>
									<small className="form-text info-text">
										{renderError("revenue_type")}
									</small>
								</div>
								<div className="form-group row">
									<div className="col-5">
										<label className="required">
											Payout Per Conversion Amount
										</label>
										<div className="input-group w-25">
											<div className="input-group-prepend">
												<small className="input-group-text">$</small>
											</div>
											<input
												type="number"
												placeholder="0.00"
												className="form-control after"
												name="revenue_value"
												value={formData.revenue_value}
												onChange={handleChange}
											/>
										</div>
										<small className="form-text info-text">
											{renderError("revenue_value")}
										</small>
									</div>
								</div>
							</div>
						</Collapse>
					</div>
					<div className="form-panel offer">
						<h3
							onClick={toggleResource}
							className={resourceOpen ? "heading" : "heading collapsed"}
							style={
								resourceOpen
									? {
										cursor: "pointer",
										color: "black",
										display: "flex",
										justifyContent: "space-between",
									}
									: {
										cursor: "pointer",
										display: "flex",
										justifyContent: "space-between",
									}
							}
						>
							<span>Resources</span>
							<i>
								<FontAwesomeIcon
									className="icon"
									icon={resourceOpen ? faMinus : faPlus}
								/>
							</i>
						</h3>
						<Collapse in={resourceOpen} className="">
							<div id="resource" className="">
								<div className="form-group">
									<div>
										{resourceInputs.map((resource, index) =>
											renderInputField(resource, index)
										)}
									</div>
									<div style={{ paddingLeft: "20px" }}>
										<button
											type="button"
											onClick={handleAddResource}
											className="btn btn-primary mb-3"
										>
											+
										</button>
									</div>
								</div>
							</div>
						</Collapse>
					</div>
					<div className="form-panel offer">
						<h3
							onClick={toggleSettings}
							className={settingsOpen ? "heading" : "heading collapsed"}
							style={
								settingsOpen
									? {
										cursor: "pointer",
										color: "black",
										display: "flex",
										justifyContent: "space-between",
									}
									: {
										cursor: "pointer",
										display: "flex",
										justifyContent: "space-between",
									}
							}
						>
							<span>
								Settings
								<small className="text-muted">
									(Control how publishers are able to access your offer)
								</small>
							</span>
							<i>
								<FontAwesomeIcon
									className="icon"
									icon={settingsOpen ? faMinus : faPlus}
								/>
							</i>
						</h3>
						<Collapse in={settingsOpen} className="">
							<div id="settings">
								<div className="form-group">
									<label className="required">Visibility</label>
									<select
										name="visibility"
										value={formData.visibility}
										onChange={handleChange}
										className="form-control"
									>
										<option value="public">Public</option>
										<option value="private">Private</option>
										<option value="apply">Apply to Run</option>
									</select>
									<small className="form-text info-text">
										{renderError("visibility")}
									</small>
									<small className="form-text info-text">
										1. Setting an offer to private hides the offer from
										publishers and allows you to grant access to specific
										publishers
									</small>
									<small className="form-text info-text">
										2. Requires publishers to apply and get approval before
										pushing traffic to this offer, select "Apply to Run"
									</small>
								</div>
								<div className="form-group">
									<label className="required">Terms &amp; Conditions</label>
									<select
										name="require_terms_conditions"
										value={formData.require_terms_conditions}
										onChange={handleChange}
										className="form-control"
									>
										<option value="enable">Enable</option>
										<option value="disable">Disable</option>
									</select>
									<small className="form-text info-text">
										{renderError("require_terms_conditions")}
									</small>
									<small className="form-text info-text">
										Requires publishers to read and explicitly accept the
										additional Terms and Conditions specified
									</small>
								</div>
								<div className="form-group">
									<label className="required">Caps</label>
									<select
										name="caps"
										className="form-control"
										onChange={(e) => handleCapsChange(e)}
										value={formData.caps}
									>
										<option value="enable">Enable</option>
										<option value="disable">Disable</option>
									</select>
									<small className="form-text info-text">
										{renderError("caps")}
									</small>
									<small className="form-text info-text">
										Set limits or budgets. Any traffic over the cap amounts will
										be marked as invalid clicks
									</small>
								</div>
								{enableCaps ? (
									<>
										<div className="form-group">
											<label>Click Caps</label>
											<div className="caps-row">
												<div className="caps-item">
													<div className="custom-control custom-checkbox small mt-1">
														<input
															type="checkbox"
															id="click_capsDaily"
															className="custom-control-input"
														/>
														<label
															htmlFor="click_capsDaily"
															className="custom-control-label m-0"
														>
															Daily
														</label>
													</div>
													<input
														type="text"
														name="clicksCapsDaily"
														onChange={(event) => {
															setFormData({
																...formData,
																offer_caps: {
																	...formData.offer_caps,
																	click_caps: {
																		...formData.offer_caps.click_caps,
																		daily: event.target.value,
																	},
																},
															});
														}}
														value={formData.offer_caps.click_caps.daily}
													/>
												</div>
												<div className="caps-item">
													<div className="custom-control custom-checkbox small mt-1">
														<input
															type="checkbox"
															id="click_capsWeekly"
															className="custom-control-input"
														/>
														<label
															htmlFor="click_capsWeekly"
															className="custom-control-label m-0"
														>
															Weekly
														</label>
													</div>
													<input
														type="text"
														name="clicksCapsWeekly"
														onChange={(event) => {
															setFormData({
																...formData,
																offer_caps: {
																	...formData.offer_caps,
																	click_caps: {
																		...formData.offer_caps.click_caps,
																		weekly: event.target.value,
																	},
																},
															});
														}}
														value={formData.offer_caps.click_caps.weekly}
													/>
												</div>
												<div className="caps-item">
													<div className="custom-control custom-checkbox small mt-1">
														<input
															type="checkbox"
															id="click_capsMonthly"
															className="custom-control-input"
														/>
														<label
															htmlFor="click_capsMonthly"
															className="custom-control-label m-0 text-l"
														>
															Monthly
														</label>
													</div>
													<input
														type="text"
														name="clicksCapsMonthly"
														onChange={(event) => {
															setFormData({
																...formData,
																offer_caps: {
																	...formData.offer_caps,
																	click_caps: {
																		...formData.offer_caps.click_caps,
																		monthly: event.target.value,
																	},
																},
															});
														}}
														value={formData.offer_caps.click_caps.monthly}
													/>
												</div>
												<div className="caps-item">
													<div className="custom-control custom-checkbox small mt-1">
														<input
															type="checkbox"
															id="click_capsGlobal"
															className="custom-control-input"
														/>
														<label
															htmlFor="click_capsGlobal"
															className="custom-control-label m-0"
														>
															Global
														</label>
													</div>
													<input
														type="text"
														name="clicksCapsGlobal"
														onChange={(event) => {
															setFormData({
																...formData,
																offer_caps: {
																	...formData.offer_caps,
																	click_caps: {
																		...formData.offer_caps.click_caps,
																		global: event.target.value,
																	},
																},
															});
														}}
														value={formData.offer_caps.click_caps.global}
													/>
												</div>
											</div>
										</div>
										<div className="form-group">
											<label>Conversion Caps</label>
											<div className="caps-row">
												<div className="caps-item">
													<div className="custom-control custom-checkbox small mt-1">
														<input
															type="checkbox"
															id="conversion_capsDaily"
															className="custom-control-input"
														/>
														<label
															htmlFor="conversion_capsDaily"
															className="custom-control-label m-0"
														>
															Daily
														</label>
													</div>
													<input
														type="text"
														name="conversion_capsDaily"
														onChange={(event) => {
															setFormData({
																...formData,
																offer_caps: {
																	...formData.offer_caps,
																	conversion_caps: {
																		...formData.offer_caps.conversion_caps,
																		daily: event.target.value,
																	},
																},
															});
														}}
														value={formData.offer_caps.conversion_caps.daily}
													/>
												</div>
												<div className="caps-item">
													<div className="custom-control custom-checkbox small mt-1">
														<input
															type="checkbox"
															id="conversion_capsWeekly"
															className="custom-control-input"
														/>
														<label
															htmlFor="conversion_capsWeekly"
															className="custom-control-label m-0"
														>
															Weekly
														</label>
													</div>
													<input
														type="text"
														name="conversion_capsWeekly"
														onChange={(event) => {
															setFormData({
																...formData,
																offer_caps: {
																	...formData.offer_caps,
																	conversion_caps: {
																		...formData.offer_caps.conversion_caps,
																		weekly: event.target.value,
																	},
																},
															});
														}}
														value={formData.offer_caps.conversion_caps.weekly}
													/>
												</div>
												<div className="caps-item">
													<div className="custom-control custom-checkbox small mt-1">
														<input
															type="checkbox"
															id="conversion_capsMonthly"
															className="custom-control-input"
														/>
														<label
															htmlFor="conversion_capsMonthly"
															className="custom-control-label m-0 text-l"
														>
															Monthly
														</label>
													</div>
													<input
														type="text"
														name="conversion_capsMonthly"
														onChange={(event) => {
															setFormData({
																...formData,
																offer_caps: {
																	...formData.offer_caps,
																	conversion_caps: {
																		...formData.offer_caps.conversion_caps,
																		monthly: event.target.value,
																	},
																},
															});
														}}
														value={formData.offer_caps.conversion_caps.monthly}
													/>
												</div>
												<div className="caps-item">
													<div className="custom-control custom-checkbox small mt-1">
														<input
															type="checkbox"
															id="conversion_capsGlobal"
															className="custom-control-input"
														/>
														<label
															htmlFor="conversion_capsGlobal"
															className="custom-control-label m-0"
														>
															Global
														</label>
													</div>
													<input
														type="text"
														name="conversion_capsGlobal"
														onChange={(event) => {
															setFormData({
																...formData,
																offer_caps: {
																	...formData.offer_caps,
																	conversion_caps: {
																		...formData.offer_caps.conversion_caps,
																		global: event.target.value,
																	},
																},
															});
														}}
														value={formData.offer_caps.conversion_caps.global}
													/>
												</div>
											</div>
										</div>
										<div className="form-group">
											<label>Payout Caps</label>
											<div className="caps-row">
												<div className="caps-item">
													<div className="custom-control custom-checkbox small mt-1">
														<input
															type="checkbox"
															id="revenue_capsDaily"
															className="custom-control-input"
														/>
														<label
															htmlFor="revenue_capsDaily"
															className="custom-control-label m-0"
														>
															Daily
														</label>
													</div>
													<input
														type="text"
														name="revenue_capsDaily"
														onChange={(event) => {
															setFormData({
																...formData,
																offer_caps: {
																	...formData.offer_caps,
																	revenue_caps: {
																		...formData.offer_caps.revenue_caps,
																		daily: event.target.value,
																	},
																},
															});
														}}
														value={formData.offer_caps.revenue_caps.daily}
													/>
												</div>
												<div className="caps-item">
													<div className="custom-control custom-checkbox small mt-1">
														<input
															type="checkbox"
															id="revenue_capsWeekly"
															className="custom-control-input"
														/>
														<label
															htmlFor="revenue_capsWeekly"
															className="custom-control-label m-0"
														>
															Weekly
														</label>
													</div>
													<input
														type="text"
														name="revenue_capsWeekly"
														onChange={(event) => {
															setFormData({
																...formData,
																offer_caps: {
																	...formData.offer_caps,
																	revenue_caps: {
																		...formData.offer_caps.revenue_caps,
																		weekly: event.target.value,
																	},
																},
															});
														}}
														value={formData.offer_caps.revenue_caps.weekly}
													/>
												</div>
												<div className="caps-item">
													<div className="custom-control custom-checkbox small mt-1">
														<input
															type="checkbox"
															id="revenue_capsMonthly"
															className="custom-control-input"
														/>
														<label
															htmlFor="revenue_capsMonthly"
															className="custom-control-label m-0 text-l"
														>
															Monthly
														</label>
													</div>
													<input
														type="text"
														name="revenue_capsMonthly"
														onChange={(event) => {
															setFormData({
																...formData,
																offer_caps: {
																	...formData.offer_caps,
																	revenue_caps: {
																		...formData.offer_caps.revenue_caps,
																		monthly: event.target.value,
																	},
																},
															});
														}}
														value={formData.offer_caps.revenue_caps.monthly}
													/>
												</div>
												<div className="caps-item">
													<div className="custom-control custom-checkbox small mt-1">
														<input
															type="checkbox"
															id="revenue_capsGlobal"
															className="custom-control-input"
														/>
														<label
															htmlFor="revenue_capsGlobal"
															className="custom-control-label m-0"
														>
															Global
														</label>
													</div>
													<input
														type="text"
														name="revenue_capsGlobal"
														onChange={(event) => {
															setFormData({
																...formData,
																offer_caps: {
																	...formData.offer_caps,
																	revenue_caps: {
																		...formData.offer_caps.revenue_caps,
																		global: event.target.value,
																	},
																},
															});
														}}
														value={formData.offer_caps.revenue_caps.global}
													/>
												</div>
											</div>
										</div>
									</>
								) : null}
							</div>
						</Collapse>
					</div>
					<div className="form-panel offer">
						<h3
							onClick={toggleTracking}
							className={trackingOpen ? "heading" : "heading collapsed"}
							style={
								trackingOpen
									? {
										cursor: "pointer",
										color: "black",
										display: "flex",
										justifyContent: "space-between",
									}
									: {
										cursor: "pointer",
										display: "flex",
										justifyContent: "space-between",
									}
							}
						>
							<span>
								Tracking
								<small className="text-muted">
									(Control how publishers are able to access your offer)
								</small>
							</span>
							<i>
								<FontAwesomeIcon
									className="icon"
									icon={trackingOpen ? faMinus : faPlus}
								/>
							</i>
						</h3>
						<Collapse in={trackingOpen} className="">
							<div id="tracking" className="">
								<div className="form-group">
									<label className="required">Tracking Domain</label>
									<select
										name="tracking_domain"
										onChange={(event) => {
											setFormData({
												...formData,
												tracking: {
													...formData.tracking,
													tracking_domain: event.target.value,
												},
											});
										}}
										value={formData.tracking.tracking_domain}
										className="form-control"
									>
										<option value="">Select Tracking Domain</option>
										{trackingDomain?.data?.map((item) => (
											<option key={item._id} value={item._id}>
												{item.domain}
											</option>
										))}
									</select>
									<small className="form-text info-text">
										{renderError("tracking_domain")}
									</small>
								</div>
								<div className="form-group">
									<label className="required">Conversion Tracking</label>
									<select
										name="conversion_method"
										onChange={(event) => {
											setFormData({
												...formData,
												tracking: {
													...formData.tracking,
													conversion_method: event.target.value,
												},
											});
										}}
										value={formData.tracking.conversion_method}
										className="form-control"
									>
										<option value="cookie_based">Cookie Based</option>
										<option value="iframe_http">HTTP iFrame Pixel</option>
										<option value="iframe_https">HTTPS iFrame Pixel</option>
										<option value="image_http">HTTP Image Pixel</option>
										<option value="image_https">HTTPS iFrame Pixel</option>
										<option value="server_postback">Server Postback</option>
										<option value="javascript">JavaScript</option>
									</select>
									<small className="form-text info-text">
										{renderError("conversion_method")}
									</small>
									<small className="form-text info-text">
										iFrame and Image conversion pixels use client-based cookie
										or cookieless tracking while Server Postback uses
										server-based cookieless tracking URLs
									</small>
								</div>
								<div className="form-group">
									<label>Force SSL</label>
									<select
										name="force_ssl"
										onChange={(event) => {
											setFormData({
												...formData,
												tracking: {
													...formData.tracking,
													force_ssl: event.target.value,
												},
											});
										}}
										value={formData.tracking.force_ssl}
										className="form-control"
									>
										<option value="1">Enable</option>
										<option value="0">Disable</option>
									</select>
									<small className="form-text info-text">
										Recommended - Improves tracking and security by setting
										tracking links to always use the more secure https://
										instead of http://
									</small>
								</div>
								<div className="form-group">
									<label>Enable Deep Links</label>
									<select
										name="enable_deep_link"
										onChange={(event) => {
											setFormData({
												...formData,
												tracking: {
													...formData.tracking,
													enable_deep_link: event.target.value,
												},
											});
										}}
										value={formData.tracking.enable_deep_link}
										className="form-control"
									>
										<option value="1">Enable</option>
										<option value="0">Disable</option>
									</select>
									<small className="form-text info-text">
										Enabling allows publishers to send their traffic to target
										URLs by adding &amp;url=[target URL] inside of their
										tracking links, instead of your destination URL
									</small>
								</div>
								<div className="form-group row">
									<div className="col-sm-4">
										<label className="required">
											Unique Session Identifier
										</label>
										<select
											name="unique_session_identifier"
											onChange={(event) => {
												setFormData({
													...formData,
													tracking: {
														...formData.tracking,
														unique_session_identifier: event.target.value,
													},
												});
											}}
											value={formData.tracking.unique_session_identifier}
											className="form-control"
										>
											<option value="cookies">Cookies</option>
											<option value="ip">IP Address</option>
											<option value="ip_user_agent">
												IP Address + User Agent
											</option>
										</select>
									</div>
									<div className="col-sm-4">
										<label className="">Session Duration</label>
										<input
											name="unique_session_duration"
											onChange={(event) => {
												if (error?.data) {
													setError((prevError) => ({
														...prevError,
														data: prevError?.data?.filter(
															(item) => item.field !== "unique_session_duration"
														),
													}));
												}
												setFormData({
													...formData,
													tracking: {
														...formData.tracking,
														unique_session_duration: event.target.value,
													},
												});
											}}
											value={formData.tracking.unique_session_duration}
											type="number"
										/>
										<small className="form-text info-text">
											{renderError("unique_session_duration")}
										</small>
									</div>
									<div className="col-sm-4">
										<label className="required">Session Interval</label>
										<select
											name="unique_session_interval"
											onChange={(event) => {
												setFormData({
													...formData,
													tracking: {
														...formData.tracking,
														unique_session_interval: event.target.value,
													},
												});
											}}
											value={formData.tracking.unique_session_interval}
											className="form-control"
										>
											<option value="hours">Hours</option>
											<option value="days">Days</option>
										</select>
										<small className="form-text info-text">
											{renderError("unique_session_interval")}
										</small>
									</div>
									<div className="col-sm-12">
										<small className="form-text info-text">
											1. Unique Session Identifier sets the method used for
											determining clicks as unique. Choosing Cookie is
											recommended.
										</small>
										<small className="form-text info-text">
											2. Unique Session Duration &amp; Interval tell that the
											click will be considered a duplicate click. Setting to 0
											will not track all clicks as unique.
										</small>
									</div>
								</div>
								<div className="form-group">
									<label>Enable Duplicate Clicks Filter</label>
									<select
										name="enable_duplicate_filter"
										onChange={(event) => {
											setFormData({
												...formData,
												tracking: {
													...formData.tracking,
													enable_duplicate_filter: event.target.value,
												},
											});
										}}
										value={formData.tracking.enable_duplicate_filter}
										className="form-control"
									>
										<option value="1">Enable</option>
										<option value="0">Disable</option>
									</select>
									<small className="form-text info-text">
										Enabling will treat all duplicate clicks (sharing the same
										transaction_id or cookie) as invalid clicks
									</small>
								</div>
							</div>
						</Collapse>
						<div className="form-submit-bar">
							<div>
								<Link
									to={"/advertiser/offer"}
									className="btn btn-sm btn-light text-muted mr-2 px-3"
								>
									Cancel
								</Link>
								<button
									type="submit"
									className="mr-2 px-3 btn btn-sm btn-submit"
									disabled={AddLoading}
								>
									{AddLoading ? "Adding..." : "Add"}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
			<style>
				{`
    h3 {
        margin-bottom: 0 !important;
    }
    .icon{
      font-size: 22px !important;
    }
    .form-control{
      border: 1px solid #ced4da !important;
    }

    .select2-container{
      width: 100% !important;
    }
    .pull-right{
      float: right !important;
      // margin-bottom: -6px !important;
    }
`}
			</style>
		</div>
	);
}

export default AddOffers;
