import React, { useEffect, useRef, useState } from "react";
import "../Common.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faHistory,
  faLink,
  faMoneyBill1Wave,
  faSearch,
  faSignOutAlt,
  faUserCircle,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { getUserData, useLogout } from "../../../utils/Utility";
import useUserData from "../../../hooks/useUserData";
import NotificationsBox from "./Notification/NotificationsBox";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../../slices/customSlices/commonSlice";

function Navbar() {
  const location = useLocation();
  const currentPath = location.pathname;
  const { adminData, advertiserData, publisherData } = useUserData();
  const data = getUserData(currentPath, adminData, advertiserData, publisherData);
  const notificationDropRef = useRef(null);
  const logoIconRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const handleLogout = useLogout();
  const dispatch = useDispatch();

  const openNotifications = () => {
    setIsOpen(!isOpen);
  };
  const userData = data.user
  const basePath = data.basePath

  const closeNotifications = (event) => {
    if (
      isOpen &&
      notificationDropRef.current &&
      !notificationDropRef.current.contains(event.target) &&
      !logoIconRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeNotifications);
    return () => {
      document.removeEventListener("click", closeNotifications);
    };
  }, [isOpen]);

  return (
    <nav
      id="topmenu"
      className="navbar navbar-light w-100 fixed-top navbar-expand-md"
    >
      <button className="sidebar-button"
        onClick={() => dispatch(toggleSidebar())}
      >
        <span className="navbar-toggler-icon"> </span>
      </button>
      <a href="#" className="navbar-brand bg-white">
        <img src="/asset/logo-base.png" />
      </a>
      <div
        id="navbarSupportedContent"
        className="collapse navbar-collapse d-flex align-items-center"
      >
        <div
          className="px-3"
          style={{
            margin: !search ? "0 0 0 auto" : "0",
            width: !search ? "unset" : "100%",
          }}
        >
          <form className={` my-lg-0 search ${!search ? "form-inline" : ""}`}>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <FontAwesomeIcon color="white" icon={faSearch} />
                </div>
              </div>
              <input
                type="search"
                onFocus={() => {
                  setSearch(true);
                }}
                onBlur={() => {
                  setSearch(false);
                }}
                className="form-control mr-sm-2"
              />
            </div>
          </form>
        </div>
        <div className="top-control pr-3">
          <div className="dropdown profile">
            <NotificationsBox />
          </div>
        </div>
        <div className="top-control pr-3">
          <div className="dropdown profile">
            <span
              onClick={openNotifications}
              ref={logoIconRef}
              className="icon dropdown-btn"
            >
              <FontAwesomeIcon
                color="#609bbd"
                style={{ fontSize: "25px" }}
                icon={faUserCircle}
              />
            </span>
            <div
              className={`dropdown-menu dropdown-menu-right shadow ${isOpen ? "active" : ""
                }`}
              ref={notificationDropRef}
            >
              <div className="d-flex flex-column p-3">
                <span className="text-uppercase font-weight-bold mb-2">
                  {userData?.name}
                </span>
                <span className="text-muted">{userData?.email}</span>
              </div>
              <div className="dropdown-divider m-0 p-0"></div>
              <Link
                to={`account/${userData?._id}`}
                className="dropdown-item px-3"
              >
                <FontAwesomeIcon color="#609bbd" icon={faUserCog} />
                My Account
              </Link>
              {basePath === "/publisher" && (
                <>
                  <Link to={`/publisher/payments`} className="dropdown-item px-3">
                    <FontAwesomeIcon color="#609bbd" icon={faMoneyBill1Wave} />
                    Payment Method
                  </Link>
                  <Link
                    to={`/publisher/payments-history`}
                    className="dropdown-item px-3"
                  >
                    <FontAwesomeIcon color="#609bbd" icon={faHistory} />
                    Payment History
                  </Link>
                  <Link
                    to={`/publisher/postbackpixel`}
                    className="dropdown-item px-3"
                  >
                    <FontAwesomeIcon color="#609bbd" icon={faLink} />
                    PostBack Pixels
                  </Link>
                </>
              )}
              <a href="#" className="dropdown-item px-3" onClick={handleLogout}>
                <FontAwesomeIcon color="#609bbd" icon={faSignOutAlt} />
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
