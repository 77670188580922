import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useAdminGetAdvertiserQuery } from "../../../../slices/apiSlices/adminApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const AdvertiserView = () => {
	const { advertiserId } = useParams();
	const { data, isLoading, isSuccess, error } =
		useAdminGetAdvertiserQuery({
			advertiserId,
		}, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});


	if (isLoading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>Error: {error?.data?.msg}</div>;
	}

	if (!isSuccess) {
		return <div>Unable to fetch data.</div>;
	}
	const advertiser = data && data?.data[0];

	// Function to format date in desired format
	const formatCreationDate = (dateString) => {
		const formattedDate = new Date(dateString).toLocaleString(); // Adjust this as per your desired date format
		return formattedDate;
	};

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">
					Advertiser Account Snapshot ( {advertiser?.name} )
				</h2>{" "}
				<div className="my-account-main">
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Account Info</span>
								</div>{" "}
								<div className="right">
									<Link to={`/admin/advertisers/edit/${advertiserId}`}>
										<i className="fas fa-edit"></i> Edit
									</Link>
								</div>
							</div>{" "}
							<div className="body">
								<div className="body-row">
									<div className="left">ID</div>{" "}
									<div className="right">{advertiser?._id}</div>
								</div>
								<div className="body-row">
									<div className="left">Name</div>{" "}
									<div className="right">{advertiser?.name}</div>
								</div>
								<div className="body-row">
									<div className="left">Status</div>{" "}
									<div className="right">
										<div>
											{advertiser?.status === "active" ? (
												<div>
													<FontAwesomeIcon
														data-toggle="dropdown"
														className="border-0 text-success"
														icon={faCircle}
													/>{" "}
													Active
												</div>
											) : (
												<div>
													<FontAwesomeIcon
														data-toggle="dropdown"
														className="border-0 text-warning"
														icon={faCircle}
													/>{" "}
													Pending
												</div>
											)}
										</div>
									</div>
								</div>

								<div className="body-row">
									<div className="left">Total Advertisers</div>{" "}
									<div className="right">
										{advertiser?.users ? (
											advertiser?.users
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Creation Date</div>{" "}
									<div className="right">
										{formatCreationDate(advertiser?.createdAt)}
									</div>
								</div>
							</div>{" "}
						</div>{" "}
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Location</span>
								</div>{" "}
							</div>{" "}
							<div className="body">
								<div className="body-row">
									<div className="left">Country</div>{" "}
									<div className="right">
										{advertiser?.country} <small> - {advertiser?.flag}</small>
									</div>
								</div>
								<div className="body-row">
									<div className="left">Address 1</div>{" "}
									<div className="right">{advertiser?.address_1}</div>
								</div>
								<div className="body-row">
									<div className="left">Address 2</div>{" "}
									<div className="right">{advertiser?.address_2}</div>
								</div>
								<div className="body-row">
									<div className="left">State</div>{" "}
									<div className="right">{advertiser?.state}</div>
								</div>
								<div className="body-row">
									<div className="left">City</div>{" "}
									<div className="right">{advertiser?.city}</div>
								</div>
								<div className="body-row">
									<div className="left">Postal Code</div>{" "}
									<div className="right">{advertiser?.zipcode}</div>
								</div>
							</div>{" "}
						</div>
					</div>{" "}
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Account Manager</span>
								</div>{" "}
							</div>{" "}
							<div className="body">
								<div className="body-row">
									<div className="left">ID</div>{" "}
									<div className="right">{advertiser?.manager_data?._id}</div>
								</div>
								<div className="body-row">
									<div className="left">Name</div>{" "}
									<div className="right">
										Mr. {advertiser?.manager_data?.name}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Role</div>{" "}
									<div className="right">{advertiser?.role_data?.role}</div>
								</div>
								<div className="body-row">
									<div className="left">Permanent Number</div>{" "}
									<div className="right">
										{advertiser?.manager_data?.cell_phone ? (
											advertiser?.manager_data?.cell_phone
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Work Number</div>{" "}
									<div className="right">
										{advertiser?.manager_data?.work_phone ? (
											advertiser?.manager_data.work_phone
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">IM</div>{" "}
									<div className="right">
										{" "}
										{advertiser?.manager_data?.im ? (
											advertiser?.manager_data.im
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">IM Identifier/Username</div>{" "}
									<div className="right">
										{" "}
										{advertiser?.manager_data?.im_identifier ? (
											advertiser?.manager_data.im_identifier
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
							</div>{" "}
						</div>{" "}
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Advertiser Users</span>
								</div>{" "}
								<div className="right">
									<Link to={`/admin/advertisers/users/${advertiserId}`}>
										<i className="fas fa-edit"></i> Show All
									</Link>
								</div>
							</div>{" "}
							<div className="body">
								<div className="body-row-counter">
									<div className="list-counter">
										<div className="left">Total Advertisers</div>{" "}
										<div className="right">
											{" "}
											{advertiser?.users ? (
												advertiser?.users
											) : (
												<i className="text-muted small">N/A</i>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdvertiserView;
