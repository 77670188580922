import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAdminGetAllRoleQuery } from "../../../../../slices/apiSlices/adminApi";
import PermissionBadge from "./PermissionBadge";

const Roles = () => {
	const { data, isLoading, isSuccess, refetch, error } =
		useAdminGetAllRoleQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});

	if (isLoading) {
		return (
			<div id="route-view" className="col-md-12">
				Loading...
			</div>
		);
	}
	if (error) {
		return (
			<div id="route-view" className="col-md-12">
				{" "}
				{error?.data?.msg}
			</div>
		);
	}

	if (!isSuccess) {
		return (
			<div id="route-view" className="col-md-12">
				Unable to fetch data.
			</div>
		);
	}
	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">
					Roles
					<div className="side-link">
						<Link to={`/admin/roles/add`} className="btn btn-side">
							<i className="fas fa-plus"></i> Add Role
						</Link>
					</div>
				</h2>
				<div className="dataTable">
					<div className="table-responsive">
						{isLoading ? (
							<p>Loading...</p>
						) : (
							<table className="table table-sm table-head table-fixed">
								<thead
									style={{
										position: "sticky",
										top: 0,
										backgroundColor: "#fafafa",
									}}
								>
									<tr>
										<th style={{ position: "sticky", top: "-1px" }} width="20%">
											ID
										</th>
										<th style={{ position: "sticky", top: "-1px" }} width="40%">
											Name
										</th>
										<th style={{ position: "sticky", top: "-1px" }} width="60%">
											Permissions
										</th>
									</tr>
								</thead>
								<tbody>
									{data?.data &&
										data?.data?.map((data, index) => (
											<tr key={data._id}>
												<td>{index + 1}</td>
												<td>
													<Link to={`/admin/roles/edit/${data._id}`}>
														{data.role}
													</Link>
												</td>
												<td>
													{Array.from(
														new Set(
															data.permissions.map((permission) =>
																permission.replace(/_[^_]+$/, "").toLowerCase()
															)
														)
													).map((keyword, key) => (
														<PermissionBadge
															key={key}
															permissions={data.permissions}
															keyword={keyword}
														/>
													))}
												</td>
											</tr>
										))}
								</tbody>
							</table>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Roles;
