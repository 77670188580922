import React from 'react';
import AdminSidebar from "../../panels/admin/Sidebar";
import AdvertiserSidebar from "../../panels/advertiser/Sidebar";
import PublisherSidebar from "../../panels/publisher/Sidebar";
import { useLocation } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  if (currentPath.startsWith('/admin')) {
    return <AdminSidebar />;
  } else if (currentPath.startsWith('/publisher')) {
    return <PublisherSidebar />;
  } else if (currentPath.startsWith('/advertiser')) {
    return <AdvertiserSidebar />;
  }

  return null; // Fallback in case the path doesn't match any known case
}

export default Sidebar;
