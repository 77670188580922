import React from "react";
import { useGetPaymentHistoryQuery } from "../../../../slices/apiSlices/publisherApi";
import { formatDate } from "../../../../utils/Utility"
const PubPaymentHistory = () => {

	const { data, isSuccess, isLoading, error } =
		useGetPaymentHistoryQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});

	if (isLoading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>Error: {error?.data?.msg}</div>;
	}

	if (!isSuccess) {
		return <div>Unable to fetch data.</div>;
	}

	const paymentHistory = data?.data;



	const formatKey = (obj) => {
		return obj
			.split("_")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	};

	return (
		<div id="route-view" className="col-md-12">
			<div
				paymentHistory-v-a5b54c2a=""
				className="paymentHistoryTables_wrapper dt-bootstrap"
			>
				<h2 className="main-heading">Payment History</h2>
				<table className="table" style={{ background: "white" }}>
					<thead>
						<tr>
							<th>Mode of Payment</th>
							<th>Currency</th>
							<th>Paid Amount</th>
							<th>Invoice Interval</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>
						{paymentHistory?.map((payment) => (
							<tr key={payment._id}>
								<td>{formatKey(payment.mode_of_payment)}</td>
								<td>{payment.mode_of_transaction.currency}</td>
								<td>{payment.payable_amount}</td>
								<td>
									{formatDate(payment.invoice_start_date)} -{" "}
									{formatDate(payment.invoice_end_date)}
								</td>
								<td>{formatDate(payment.createdAt)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default PubPaymentHistory;
