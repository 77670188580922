import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "bootstrap";
import { Link } from "react-router-dom";
import useUserData from "../../../hooks/useUserData";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../../slices/customSlices/commonSlice";

const Sidebar = () => {
  const { adminData } = useUserData();
  const collapsed = useSelector((state) => state.common.collapsed);

  const endDate = new Date().toISOString().split("T")[0];
  const firstDayOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    2
  );
  const startDate = firstDayOfMonth.toISOString().split("T")[0];
  const dispatch = useDispatch();

  const [toggleState, setToggleState] = useState({
    dashboard: false,
    offersNav: false,
    publishersNav: false,
    advertisersNav: false,
    analyticsNav: false,
    reportingNav: false,
    controlNav: false,
    trackingNav: false,
    conversionsnav: false,
  });

  const isadmin = adminData.user.is_super_admin;

  const permissions = adminData.user.permissions || [];


  useEffect(() => {
    Object.keys(toggleState).forEach((key) => {
      const myCollapse = document.getElementById(key);
      if (myCollapse) {
        const bsCollapse = new Collapse(myCollapse, { toggle: false });
        toggleState[key] ? bsCollapse.show() : bsCollapse.hide();
      }
    });
  }, [toggleState]); // Remove toggleState from here

  useEffect(() => {
    if (collapsed) {
      setToggleState({
        dashboard: false,
        offersNav: false,
        publishersNav: false,
        advertisersNav: false,
        analyticsNav: false,
        reportingNav: false,
        controlNav: false,
        trackingNav: false,
        conversionsnav: false,
      });
    }
  }, [collapsed]);

  const handleToggle = (key) => {
    if (collapsed) {
      dispatch(toggleSidebar());
    }
    setToggleState((prevState) => {
      const updatedState = {};
      Object.keys(prevState)?.forEach((prevStateKey) => {
        updatedState[prevStateKey] =
          prevStateKey === key ? !prevState[key] : false;
      });
      return updatedState;
    });
  };

  return (<div>
    <div id="accordion" style={{ flex: "1 1 0%" }}>
      <div className="card">
        <div className="card-header">
          <Link
            onClick={() => handleToggle("dashboard")}
            to={`/admin/dashboard/?start_date=${startDate}&end_date=${endDate}`}
          >
            <i className="custom-icon">
              <img src="/asset/icons/dashboard.svg" />
              {!collapsed && <span>Dashboard</span>}
            </i>
          </Link>
        </div>
      </div>
      {(permissions?.includes("offers_add") ||
        permissions?.includes("offers_view")) && (
          <div className="card">
            <div className="card-header folded">
              <a
                href="#"
                data-toggle="collapse"
                data-target="#offersNav"
                className={toggleState.offersNav ? "" : "collapsed"}
                aria-expanded="false"
                onClick={() => handleToggle("offersNav")}
              >
                <i className="custom-icon" >
                  <img src="/asset/icons/box-open.svg" />
                  {!collapsed && <span>Offers
                    <FontAwesomeIcon className="arrow" icon={faChevronDown} />
                  </span>}
                </i>
              </a>
              <div
                id="offersNav"
                data-parent="#accordion"
                className="collapse"
              >
                <div className="list-group">
                  {permissions?.includes("offers_view") && (
                    <Link
                      to={`/admin/offers`}
                      exact=""
                      className="list-group-item list-group-item-action"
                    >
                      Manage
                    </Link>
                  )}
                  {permissions?.includes("offers_add") && (
                    <Link
                      to={`/admin/offers/add`}
                      exact=""
                      className="list-group-item list-group-item-action"
                    >
                      Add
                    </Link>
                  )}
                  {permissions?.includes("offers_view") && (
                    <Link
                      to={`/admin/offers/allofferapplications`}
                      exact=""
                      className="list-group-item list-group-item-action"
                    >
                      All offers Applications
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      {(permissions?.includes("publishers_add") ||
        permissions?.includes("publishers_view")) && (
          <div className="card">
            <div className="card-header folded">
              <a
                data-toggle="collapse"
                data-target="#publishersNav"
                className={toggleState.publishersNav ? "" : "collapsed"}
                onClick={() => handleToggle("publishersNav")}
              >
                <i className="custom-icon">
                  <img src="/asset/icons/affiliate.svg" />
                  {!collapsed && <span>Publishers
                    <FontAwesomeIcon className="arrow" icon={faChevronDown} />
                  </span>}
                </i>
              </a>
              <div
                id="publishersNav"
                data-parent="#accordion"
                className="collapse"
              >
                <div className="list-group">
                  {isadmin ? (
                    <Link
                      to={`/admin/due-payments/all`}
                      exact=""
                      className="list-group-item list-group-item-action"
                    >
                      Due Payment
                    </Link>
                  ) : null}
                  {permissions?.includes("publishers_view") && (
                    <Link
                      to={`/admin/publisher-accounts`}
                      exact=""
                      className="list-group-item list-group-item-action"
                    >
                      Manage
                    </Link>
                  )}

                  {permissions?.includes("publishers_add") && (
                    <Link
                      to={`/admin/publisher-accounts/add`}
                      exact=""
                      className="list-group-item list-group-item-action"
                    >
                      Add
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      {(permissions?.includes("advertisers_add") ||
        permissions?.includes("advertisers_view")) && (
          <div className="card">
            <div className="card-header folded">
              <a
                href="#"
                data-toggle="collapse"
                data-target="#advertisersNav"
                className={toggleState.advertisersNav ? "" : "collapsed"}
                onClick={() => handleToggle("advertisersNav")}
              >
                <i className="custom-icon">
                  <img src="/asset/icons/banner-ad.svg" />
                  {!collapsed && <span>Advertisers
                    <FontAwesomeIcon className="arrow" icon={faChevronDown} />
                  </span>}
                </i>
              </a>
              <div
                id="advertisersNav"
                data-parent="#accordion"
                className="collapse"
              >
                <div className="list-group">
                  {permissions?.includes("advertisers_view") && (
                    <Link
                      to={`/admin/advertisers`}
                      exact=""
                      className="list-group-item list-group-item-action"
                    >
                      Manage
                    </Link>
                  )}
                  {permissions?.includes("advertisers_add") && (
                    <Link
                      to={`/admin/advertisers/add`}
                      exact=""
                      className="list-group-item list-group-item-action"
                    >
                      Add
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      <div className="card" style={{ display: "none" }}>
        <div className="card-header folded">
          <a
            href="#"
            data-toggle="collapse"
            data-target="#analyticsNav"
            className={toggleState.analyticsNav ? "" : "collapsed"}
            onClick={() => handleToggle("analyticsNav")}
          >
            <i className="custom-icon">
              <img src="/asset/icons/analytics.svg" />
              {!collapsed && <span>Analytics
                <FontAwesomeIcon className="arrow" icon={faChevronDown} />
              </span>}
            </i>
          </a>
          <div
            id="analyticsNav"
            data-parent="#accordion"
            className="collapse"
          >
            <div className="list-group">
              <a
                href="#"
                className="list-group-item list-group-item-action"
              >
                Charts
              </a>
              <a
                href="#"
                className="list-group-item list-group-item-action"
              >
                Bar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header folded">
          <a
            href="#"
            data-toggle="collapse"
            data-target="#reportingNav"
            className={toggleState.reportingNav ? "" : "collapsed"}
            onClick={() => handleToggle("reportingNav")}
          >
            <i className="custom-icon">
              <img src="/asset/icons/reporting.svg" />
              {!collapsed && <span>Reporting
                <FontAwesomeIcon className="arrow" icon={faChevronDown} />
              </span>}
            </i>
          </a>
          <div
            id="reportingNav"
            data-parent="#accordion"
            className="collapse"
          >
            <div className="list-group">
              {permissions?.includes("offers_view") && (
                <Link
                  to={`#`}
                  className="list-group-item list-group-item-action"
                >
                  Offers
                </Link>
              )}
              {permissions?.includes("p_view") && (
                <Link
                  to={`#`}
                  className="list-group-item list-group-item-action"
                >
                  Publishers
                </Link>
              )}
              {permissions?.includes("advertisers_view") && (
                <Link
                  to={`#`}
                  className="list-group-item list-group-item-action"
                >
                  Advertisers
                </Link>
              )}

              <Link
                to={`/admin/reporting/clicks/?start_date=${startDate}&end_date=${endDate}`}
                exact=""
                className="list-group-item list-group-item-action"
              >
                Clicks
              </Link>
            </div>
          </div>
        </div>
      </div>
      {(permissions?.includes("users_view") ||
        permissions?.includes("roles_view")) && (
          <div className="card">
            <div className="card-header folded">
              <a
                href="#"
                data-toggle="collapse"
                data-target="#controlNav"
                className={toggleState.controlNav ? "" : "collapsed"}
                onClick={() => handleToggle("controlNav")}
              >
                <i className="custom-icon">
                  <img src="/asset/icons/control.svg" />
                  {!collapsed && <span>Control Panal
                    <FontAwesomeIcon className="arrow" icon={faChevronDown} />
                  </span>}
                </i>
              </a>
              <div
                id="controlNav"
                data-parent="#accordion"
                className="collapse"
              >
                <div className="list-group">
                  {permissions?.includes("users_view") && (
                    <Link
                      to={"/admin/accounts"}
                      exact=""
                      className="list-group-item list-group-item-action"
                    >
                      Accounts
                    </Link>
                  )}
                  {permissions?.includes("roles_view") && (
                    <Link
                      to={`/admin/roles`}
                      exact=""
                      className="list-group-item list-group-item-action"
                    >
                      Roles
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      {(permissions?.includes("tracking_domains_view") ||
        permissions?.includes("tracking_domains_add")) && (
          <div className="card">
            <div className="card-header folded">
              <a
                href="#"
                data-toggle="collapse"
                data-target="#trackingNav"
                className={toggleState.trackingNav ? "" : "collapsed"}
                aria-expanded="false"
                onClick={() => handleToggle("trackingNav")}
              >
                <i className="custom-icon">
                  <img src="/asset/icons/trck.svg" />
                  {!collapsed && <span>Tracking Domains
                    <FontAwesomeIcon className="arrow" icon={faChevronDown} />
                  </span>}
                </i>
              </a>
              <div
                id="trackingNav"
                data-parent="#accordion"
                className="collapse"
              >
                <div className="list-group">
                  {permissions?.includes("tracking_domains_view") && (
                    <Link
                      to={`/admin/tracking-domains`}
                      exact=""
                      className="list-group-item list-group-item-action"
                    >
                      Manage
                    </Link>
                  )}
                  {permissions?.includes("tracking_domains_add") && (
                    <Link
                      to={`/admin/tracking-domains/add`}
                      exact=""
                      className="list-group-item list-group-item-action"
                    >
                      Add
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      {permissions?.includes("conversions_view") && (
        <div className="card">
          <div className="card-header">
            <Link
              onClick={() => handleToggle("conversionsnav")}
              to={`/admin/conversions`}
            >
              <i className="custom-icon">
                <img src="/asset/icons/conversion.svg" />
                {!collapsed && <span>Conversions</span>}
              </i>
            </Link>
          </div>
        </div>
      )}
    </div>
    <div id="sidenavFooter" className="">
      <p> ©2024 AdspayingMedia.com </p>
      <FontAwesomeIcon icon={faArrowAltCircleLeft} />
    </div>
  </div>
  );
};

export default Sidebar;
