import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLoginAdminMutation } from '../../../slices/apiSlices/adminApi';
import { login as setUserData } from '../../../slices/customSlices/adminSlice';
import { useLogout } from "../../../utils/Utility";

function AdminLogin({ adminData }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginUser, { isLoading }] = useLoginAdminMutation();

  const handleLogout = useLogout();
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://adspayingmedia.com/styles.css';
    link.classList.add("loginStyles");
    document.head.appendChild(link);
    document.body.style.paddingTop = '0px';
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);
    const actualData = {
      email: data.get("email"),
      password: data.get("password"),
    };

    if (!actualData.email || !actualData.password) {
      toast.error("All fields are required");
      return;
    }

    try {
      const res = await loginUser(actualData);
      if (res.data && res.data.success) {
        toast.success(res.data.msg);
        dispatch(setUserData({ user: res.data.data }));
        navigate("/admin/dashboard");
      } else if (res.data && !res.data.success) {
        const errorMessage = res?.data?.msg || "Something went wrong!";
        toast.info(errorMessage);
      } else {
        toast.error("An unexpected error occurred!");
      }
    } catch (error) {
      toast.error(`An unexpected error occurred: ${error.message}`);
    }
  };

  const handleCancel = (formId) => {
    const form = document.getElementById(formId);
    form.reset();
  };

  return (
    <div className="bg-white xl:w-1/3 text-black p-16">
      {adminData.isAuthenticated ? <div style={{ textAlign: 'center' }}>
        <p style={{ fontSize: '20px', margin: '40px' }}>hii! {adminData.user.name}</p>
        <p style={{ fontSize: '24px', margin: '40px' }}>You are already logged in</p>
        <div>
          <button onClick={(e) => { e.preventDefault(); navigate('/admin/dashboard'); }}
            className="btn btn-primary py-2 font-medium text-white text-xl mr-2">Go To Dashboard</button>
          <button onClick={(e) => { e.preventDefault(); handleLogout('admin'); }}
            className="btn btn-secondary py-2 font-medium text-xl">Logout</button>
        </div>
      </div> : (<div><h1 className="font-medium text-3xl text-center">Log In </h1>

        <div id="publisher-login-form">
          <form id="publisher-form" method="post" onSubmit={(e) => handleSubmit(e)}>
            <div className="mb-5">
              <label htmlFor="publisher_email">Email Address</label>
              <br />
              <input
                className="bg-input mt-2"
                type="text"
                id="email"
                name="email"
                required
              />
            </div>
            <div className="mb-5">
              <label htmlFor="publisher_password">Password</label>
              <br />
              <input
                className="bg-input mt-2"
                type="password"
                id="password"
                name="password"
                required
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary py-2 font-medium text-white text-xl mr-2"
              >
                &nbsp;&nbsp;&nbsp;&nbsp;{isLoading ? 'Loading...' : 'Log In'}&nbsp;&nbsp;&nbsp;&nbsp;
              </button>
              <button
                type="button"
                className="btn bg-input py-2 font-medium text-gray-500 text-xl hidden md:inline"
                onClick={() => handleCancel('publisher-form')}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Cancel&nbsp;&nbsp;&nbsp;&nbsp;
              </button>
              <p className="text-gray-500 text-center mt-5">
                {' '}
              </p>
            </div>
          </form>
        </div></div>)}
    </div>
  );
}

export default AdminLogin;
