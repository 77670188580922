import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLoginAdvertiserMutation } from '../../../slices/apiSlices/advertiserApi';
import { useLoginPublisherMutation } from '../../../slices/apiSlices/publisherApi';
import { login as setAdvertiserData } from '../../../slices/customSlices/advertiserSlice';
import { login as setPublisherData } from '../../../slices/customSlices/publisherSlice';
import AdminLogin from "./AdminLogin";
import useUserData from '../../../hooks/useUserData';
import { useLogout } from '../../../utils/Utility';

function Login() {
  const location = useLocation();
  const handleLogout = useLogout();
  const { adminData, advertiserData, publisherData } = useUserData();
  const [activeForm, setActiveForm] = useState('publisher'); // State to manage which form is active
  const [isMenuVisible, setMenuVisible] = useState(false); // State for the toggle menu

  // Function to parse query parameters
  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    return {
      who: queryParams.get('who'),
    };
  };

  const queryParams = getQueryParams();

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://adspayingmedia.com/styles.css';
    link.classList.add("loginStyles");
    document.head.appendChild(link);
    document.body.style.paddingTop = '0px';

    const mainElement = document.querySelector('main');
    if (mainElement) {
      mainElement.style.height = '88vh';
    }
    if (queryParams?.who && queryParams.who === 'admin') {
      setActiveForm('admin');
    }
    // Set initial active form based on query parameters
    if (queryParams?.who && queryParams.who === 'advertiser') {
      setActiveForm('advertiser');
    }

    return () => {
      document.head.removeChild(link); // Clean up the link when the component is unmounted
    };
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginAdvertiser, { isLoading: isLoadingAdvertiser }] = useLoginAdvertiserMutation();
  const [loginPublisher, { isLoading: isLoadingPublisher }] = useLoginPublisherMutation();

  const handleLoginSubmit = async (e, loginUser, isPublisher) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const actualData = {
      email: data.get('email'),
      password: data.get('password'),
    };

    if (!actualData.email || !actualData.password) {
      toast.error("All fields are required");
      return;
    }

    try {
      const res = await loginUser(actualData);
      if (res.data && res.data.success) {
        toast.success(res.data.msg);
        dispatch(isPublisher ? setPublisherData({ user: res.data.data }) : setAdvertiserData({ user: res.data.data }));
        navigate(isPublisher ? '/publisher/dashboard' : '/advertiser/dashboard');
      } else if (res.data && !res.data.success) {
        const errorMessage = res?.data?.msg || "Something went wrong!";
        toast.info(errorMessage);
      } else {
        toast.error("An unexpected error occurred!");
      }
    } catch (error) {
      toast.error(`An unexpected error occurred: ${error.message}`);
    }
  };

  const handleCancel = (formId) => {
    const form = document.getElementById(formId);
    form.reset();
  };

  return (
    <div className="font-body text-white red-gradient" style={{ height: "100vh" }}>
      <div className="bg-my-black">
        <div className="container mx-auto">
          <nav className="md:flex md:justify-between md:items-center py-4">
            <div className="flex justify-between items-center">
              <div className="px-4 cursor-pointer md:hidden">
                <svg
                  onClick={() => setMenuVisible(!isMenuVisible)} // Toggle menu visibility
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </div>
              <h1 className="text-2xl font-bold">
                <a href="/">
                  <img src="https://adspayingmedia.com/images/adspayingmedia-logo.png" alt="Logo" width={120} />
                </a>
              </h1>
            </div>
            <ul className={`ul-hidden ${isMenuVisible ? '' : 'hidden'} lg:flex`}>
              <li className="mr-4 p-2">
                <a href="https://adspayingmedia.com/">
                  <span>Home</span>
                </a>
              </li>
              <li className="mr-4 p-2">
                <a href="https://adspayingmedia.com/publisher.php">
                  <span>Publishers</span>
                </a>
              </li>
              <li className="mr-4 p-2">
                <a href="https://adspayingmedia.com/advertiser.php">
                  <span>Advertisers</span>
                </a>
              </li>
              <li className="mr-4 p-2">
                <a href="https://adspayingmedia.com/blog">
                  <span>Blog</span>
                </a>
              </li>
              <li className="mr-4 p-2">
                <a href="https://adspayingmedia.com/contact-us.php">
                  <span>Contact</span>
                </a>
              </li>
            </ul>
            <ul className={`ul-hidden ${isMenuVisible ? '' : 'hidden'} lg:flex`}>
              <li className="mr-4 p-2">
                <a href="#">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </a>
              </li>
              <li className="mr-4 p-2">
                <a className="btn btn-primary text-sm py-2" href="/">
                  &nbsp;&nbsp;&nbsp;&nbsp;Log In&nbsp;&nbsp;&nbsp;&nbsp;
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <main className="pt-16 flex justify-center items-center" >

        {activeForm === 'admin' ? <AdminLogin adminData={adminData} /> : <div className="bg-white xl:w-1/3 text-black p-16">
          <h1 className="font-medium text-3xl text-center">Log In As</h1>
          <ul className="flex justify-center mt-5 mb-8">
            <li className="mr-2">
              <button
                id="publisher-login-button"
                className={`text-center ${activeForm === 'publisher' ? 'border-b-2 border-gradient text-black' : 'text-gray-500'} rounded py-2 px-4`}
                onClick={() => setActiveForm('publisher')}
              >
                Publisher
              </button>
            </li>
            <li className="mr-2">
              <button
                id="advertiser-login-button"
                className={`text-center ${activeForm === 'advertiser' ? 'border-b-2 border-gradient text-black' : 'text-gray-500'} py-2 px-4`}
                onClick={() => setActiveForm('advertiser')}
              >
                Advertiser
              </button>
            </li>
          </ul>
          {activeForm === 'publisher' && (publisherData.isAuthenticated ? <div style={{ textAlign: 'center' }}>
            <p style={{ fontSize: '20px', margin: '40px' }}>hii! {publisherData.user.name}</p>
            <p style={{ fontSize: '24px', margin: '40px' }}>You are already logged in</p>
            <div>
              <button onClick={(e) => { e.preventDefault(); navigate('/publisher/dashboard'); }}
                className="btn btn-primary py-2 font-medium text-white text-xl mr-2">Go To Dashboard</button>
              <button onClick={(e) => { e.preventDefault(); handleLogout('publisher'); }}
                className="btn btn-secondary py-2 font-medium text-xl">Logout</button>
            </div>
          </div> :
            <div id="publisher-login-form">
              <form id="publisher-form" method="post" onSubmit={(e) => handleLoginSubmit(e, loginPublisher, true)}>
                <div className="mb-5">
                  <label htmlFor="publisher_email">Email Address</label>
                  <br />
                  <input
                    className="bg-input mt-2"
                    type="text"
                    id="email"
                    name="email"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="publisher_password">Password</label>
                  <br />
                  <input
                    className="bg-input mt-2"
                    type="password"
                    id="password"
                    name="password"
                    required
                  />
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary py-2 font-medium text-white text-xl mr-2"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;{isLoadingPublisher ? 'Loading...' : 'Log In'}&nbsp;&nbsp;&nbsp;&nbsp;
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary py-2 font-medium text-xl"
                    onClick={() => handleCancel('publisher-form')}
                  >
                    Cancel
                  </button>
                  <div className="mt-2">
                    <a className="text-gray-500" href="#">
                      <small>Forgot Password?</small>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          )}
          {activeForm === 'advertiser' && (advertiserData.isAuthenticated ? <div style={{ textAlign: 'center' }}>
            <p style={{ fontSize: '20px', margin: '40px' }}>hii! {advertiserData.user.name}</p>
            <p style={{ fontSize: '24px', margin: '40px' }}>You are already logged in</p>
            <div>
              <button onClick={(e) => { e.preventDefault(); navigate('/advertiser/dashboard'); }}
                className="btn btn-primary py-2 font-medium text-white text-xl mr-2">Go To Dashboard</button>
              <button onClick={(e) => { e.preventDefault(); handleLogout('advertiser'); }}
                className="btn btn-secondary py-2 font-medium text-xl">Logout</button>
            </div>
          </div> :
            <div id="advertiser-login-form">
              <form id="advertiser-form" method="post" onSubmit={(e) => handleLoginSubmit(e, loginAdvertiser, false)}>
                <div className="mb-5">
                  <label htmlFor="advertiser_email">Email Address</label>
                  <br />
                  <input
                    className="bg-input mt-2"
                    type="text"
                    id="email"
                    name="email"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="advertiser_password">Password</label>
                  <br />
                  <input
                    className="bg-input mt-2"
                    type="password"
                    id="password"
                    name="password"
                    required
                  />
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary py-2 font-medium text-white text-xl mr-2"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;{isLoadingAdvertiser ? 'Loading...' : 'Log In'}&nbsp;&nbsp;&nbsp;&nbsp;
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary py-2 font-medium text-xl"
                    onClick={() => handleCancel('advertiser-form')}
                  >
                    Cancel
                  </button>
                  <div className="mt-2">
                    <a className="text-gray-500" href="#">
                      <small>Forgot Password?</small>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>}
      </main>
    </div>
  );
}

export default Login;
