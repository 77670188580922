import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
	useAdminAdvertiserAddUserMutation,
	useAdminAdvertiserGetAllUsersQuery,
} from "../../../../../slices/apiSlices/adminApi";

const AdvertiserAddUser = () => {
	const { advertiserId } = useParams();
	const navigate = useNavigate();
	const [adminAdvertiserAddUser, { isLoading: AddLoading }] =
		useAdminAdvertiserAddUserMutation();
	const {
		data,
		isLoading,
		error: resErr,
		isSuccess,
	} = useAdminAdvertiserGetAllUsersQuery({
		advertiserId,
	}, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});

	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		title: "",
		cell_phone: "",
		work_phone: "",
		advertiser_account_id: advertiserId,
		email: "",
		password: "",
		password_confirmation: "",
	});
	const [error, setError] = useState();

	if (isLoading) {
		return <div>Loading...</div>;
	}
	if (resErr) {
		return <div>Error: {resErr?.data?.msg}</div>;
	}

	if (!isSuccess) {
		return <div>Unable to fetch data.</div>;
	}
	const advertiserAccName = data?.data[0]?.advertiser_account?.name;

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (error?.data) {
			setError((prevError) => {
				const updatedError = {
					...prevError,
					data: prevError?.data?.filter((item) => item.field !== name),
				};
				return updatedError;
			});
		}
		setFormData({
			...formData,
			[name]: value,
		});
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		const res = await adminAdvertiserAddUser({ formData });
		if (res.error && res.error.data.errors) {
			const errors = res.error.data.errors;
			setError({ data: errors });
			if (errors.length > 4) {
				toast.error("Please fill in all the required fields.");
			} else {
				errors.forEach((err) => toast.error(err.message));
			}
		} else {
			if (res.data.success === true) {
				toast.success(res.data.msg);
				// dispatch(setAdminAdvertiserUSerInfo(advertiserUserData));
				navigate(`/admin/advertisers/users/${advertiserId}`);
			} else {
				toast.error(res.data.msg);
				setError({
					msg: res.data.msg,
				});
			}
		}
	};

	const renderError = (fieldName) => {
		const errorItem = error?.data?.find((item) => item.field === fieldName);
		return errorItem ? (
			<div key={fieldName} className="text-danger">
				{errorItem.message}
			</div>
		) : null;
	};

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">
					Add Advertiser User ({advertiserAccName})
					<small className="form-text text-danger">
						{renderError("advertiser_account_id")}
					</small>
				</h2>
				<form onSubmit={handleSubmit}>
					<div className="form-panel">
						<h3 className="heading">User Details</h3>{" "}
						<div className="form-group">
							<label htmlFor="" className="required">
								First Name
							</label>{" "}
							<input
								type="text"
								className={`form-control border  ${formData.first_name.trim() === "" ? "border-danger" : ""
									}`}
								name="first_name"
								value={formData.first_name}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("first_name")}
							</small>
						</div>{" "}
						<div className="form-group">
							<label htmlFor="" className="required">
								Last Name
							</label>{" "}
							<input
								type="text"
								className={`form-control border  ${formData.last_name.trim() === "" ? "border-danger" : ""
									}`}
								name="last_name"
								value={formData.last_name}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("last_name")}
							</small>
						</div>{" "}
						<div className="form-group">
							<label htmlFor="">Title</label>{" "}
							<select
								id=""
								className="form-control"
								name="title"
								value={formData.title}
								onChange={handleChange}
							>
								<option value="" defaultValue disabled="disabled">
									Select Title
								</option>{" "}
								<option value="Mr.">Mr.</option>{" "}
								<option value="Mrs.">Mrs.</option>{" "}
								<option value="Miss">Miss</option>
							</select>
							<small className="form-text text-danger">
								{renderError("title")}
							</small>
						</div>{" "}
						<div className="form-group">
							<label htmlFor="" className="required">
								Email
							</label>{" "}
							<input
								type="text"
								className={`form-control border  ${formData.email.trim() === "" ? "border-danger" : ""
									}`}
								name="email"
								value={formData.email}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("email")}
							</small>
						</div>{" "}
						<div className="form-group row">
							<div className="col-md-6">
								<label htmlFor="" className="required">
									Permanent Phone
								</label>{" "}
								<input
									type="text"
									className={`form-control border ${formData.cell_phone.toString().trim() === ""
										? "border-danger"
										: ""
										}`}
									name="cell_phone"
									value={formData.cell_phone}
									onChange={handleChange}
									onKeyPress={(e) => {
										const isNumeric = /^[0-9]*$/.test(e.key);
										if (!isNumeric) {
											e.preventDefault();
										}
									}}
								/>
								<small className="form-text text-danger">
									{renderError("cell_phone")}
								</small>
							</div>{" "}
							<div className="col-md-6">
								<label htmlFor="">Work Phone</label>{" "}
								<input
									type="text"
									className="form-control"
									name="work_phone"
									value={formData.work_phone}
									onChange={handleChange}
									onKeyPress={(e) => {
										const isNumeric = /^[0-9]*$/.test(e.key);
										if (!isNumeric) {
											e.preventDefault();
										}
									}}
								/>
							</div>
						</div>{" "}
						<div className="form-group">
							<label htmlFor="" className="required">
								Password
							</label>{" "}
							<input
								type="text"
								className={`form-control border  ${formData.password.trim() === "" ? "border-danger" : ""
									}`}
								name="password"
								value={formData.password}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("password")}
							</small>
						</div>{" "}
						<div className="form-group">
							<label htmlFor="" className="required">
								Confirm Password
							</label>{" "}
							<input
								type="text"
								className={`form-control border  ${formData.password_confirmation.trim() === ""
									? "border-danger"
									: ""
									}`}
								name="password_confirmation"
								value={formData.password_confirmation}
								onChange={handleChange}
							/>{" "}
							<small className="form-text text-danger">
								{renderError("password_confirmation")}
							</small>
							<small className="form-text info-text">
								Password must contain at least 8 characters
							</small>
						</div>
						<span className="form-text text-info required">
							User status can only be changed after adding. By default{" "}
							<b className="text-success font-weight-bold">'Active'</b> status
							will be assigned.
						</span>
						<div className="form-submit-bar">
							<div>
								<Link
									to={`/admin/advertisers/users/${advertiserId}`}
									className="btn btn-sm btn-light text-muted mr-2 px-3"
								>
									Cancel
								</Link>{" "}
								<button
									type="submit"
									className="mr-2 px-3 btn btn-sm btn-submit"
									disabled={AddLoading}
								>
									{AddLoading ? "Adding..." : "Add"}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AdvertiserAddUser;
