
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout as logoutAdmin } from '../slices/customSlices/adminSlice';
import { logout as logoutAdvertiser } from '../slices/customSlices/advertiserSlice';
import { logout as logoutPublisher } from '../slices/customSlices/publisherSlice'
import { useEffect } from 'react';

export const useLogout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminChannel = new BroadcastChannel('admin_channel');
  const advertiserChannel = new BroadcastChannel('advertiser_channel');
  const publisherChannel = new BroadcastChannel('publisher_channel');
  const currentPath = location.pathname;

  useEffect(() => {
    const handleLogout = (message) => {
      if (message.data === 'logout') {
        // Perform logout for the specific role based on the current path
        if (currentPath.startsWith('/admin')) {
          dispatch(logoutAdmin());
          navigate('/admin/login');
        } else if (currentPath.startsWith('/advertiser')) {
          dispatch(logoutAdvertiser());
          navigate('/advertiser/login');
        } else if (currentPath.startsWith('/publisher')) {
          dispatch(logoutPublisher());
          navigate('/publisher/login');
        }
      }
    };

    // Attach listeners to the appropriate channel based on the current path
    if (currentPath.startsWith('/admin')) {
      adminChannel.addEventListener('message', handleLogout);
    } else if (currentPath.startsWith('/advertiser')) {
      advertiserChannel.addEventListener('message', handleLogout);
    } else if (currentPath.startsWith('/publisher')) {
      publisherChannel.addEventListener('message', handleLogout);
    }

    return () => {
      // Clean up listeners
      adminChannel.removeEventListener('message', handleLogout);
      advertiserChannel.removeEventListener('message', handleLogout);
      publisherChannel.removeEventListener('message', handleLogout);
    };
  }, [currentPath, dispatch, navigate]);

  const handleLogout = (userType = "") => {
    // Broadcast logout only to the specific role's channel
    if (currentPath.startsWith('/admin') || userType === 'admin') {
      dispatch(logoutAdmin());
      navigate('/admin/login');
      adminChannel.postMessage('logout');
    } else if (currentPath.startsWith('/publisher') || userType === 'publisher') {
      dispatch(logoutPublisher());
      navigate('/publisher/login');
      publisherChannel.postMessage('logout');
    } else if (currentPath.startsWith('/advertiser') || userType === 'advertiser') {
      dispatch(logoutAdvertiser());
      navigate('/advertiser/login');
      advertiserChannel.postMessage('logout');
    }
  };

  return handleLogout;
};

export const getUserData = (pathname, adminData, advertiserData, publisherData) => {

  if (pathname.startsWith('/admin')) {
    return adminData;
  } else if (pathname.startsWith('/publisher')) {
    return publisherData;
  } else if (pathname.startsWith('/advertiser')) {
    return advertiserData;
  }
  return null;
};

export const formatTimeStampDate = (dateString) => {
  if (!dateString) return { date: '', time: '' };

  // Remove the 'T' and 'Z' characters
  const [date, timeWithMs] = dateString.replace('Z', '').split('T');
  // Extract time without milliseconds
  const [time] = timeWithMs.split('.');

  return { date, time };
};

export const timeAgo = (timestamp) => {
  const now = new Date();
  const createdDate = new Date(timestamp);
  const seconds = Math.floor((now - createdDate) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return `${interval} year${interval === 1 ? "" : "s"} ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return `${interval} month${interval === 1 ? "" : "s"} ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return `${interval} day${interval === 1 ? "" : "s"} ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return `${interval} hour${interval === 1 ? "" : "s"} ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return `${interval} minute${interval === 1 ? "" : "s"} ago`;
  }
  return "Just now";
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
};
