import React from "react";
import { useAdminGetPaymentHistoryQuery } from "../../../../slices/apiSlices/adminApi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const PaymentHistory = () => {
	const { userId } = useParams();

	const { data, isSuccess, isLoading, error } =
		useAdminGetPaymentHistoryQuery({
			userId,
		}, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});

	if (isLoading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>Error: {error?.data?.msg}</div>;
	}

	if (!isSuccess) {
		return <div>Unable to fetch data.</div>;
	}

	const paymentHistory = data?.data?.transferred_amount;

	const formatKey = (obj) => {
		if (!obj) return ''; // Handle undefined or null values
		return obj
			.split("_")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	};

	return (
		<div id="route-view" className="col-md-12">
			<div
				paymentHistory-v-a5b54c2a=""
				className="paymentHistoryTables_wrapper dt-bootstrap"
			>
				<h2 className="main-heading">Payment History</h2>
				<table className="table" style={{ background: "white" }}>
					<thead>
						<tr>
							<th>Mode of Payment</th>
							<th>Transferred By</th>
							<th>Currency</th>
							<th>Paid Amount</th>
							<th>Transaction ID</th>
							<th>Payment Reason</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>
						{paymentHistory?.map((payment) => (
							<tr key={payment._id}>
								<td>{formatKey(payment.mode_of_payment)}</td>
								<td>{payment.transferred_by}</td>
								<td>{payment.currency}</td>
								<td>{payment.paid_amount}</td>
								<td>{payment.transaction_id}</td>
								<td>{payment.payment_reason}</td>
								<td>{new Date(payment.createdAt).toLocaleString()}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default PaymentHistory;
