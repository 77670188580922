import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { faCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { useGetAllTrackingDomainQuery } from "../../../../slices/apiSlices/adminApi";

const Domains = () => {
	const [filterText, setFilterText] = useState("");

	const { data, isLoading, error, isSuccess } = useGetAllTrackingDomainQuery(null, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});

	if (isLoading) {
		return (
			<div id="route-view" className="col-md-12">
				Loading...
			</div>
		);
	}
	if (error) {
		return (
			<div id="route-view" className="col-md-12">
				{" "}
				{error?.data?.msg}
			</div>
		);
	}

	if (!isSuccess) {
		return (
			<div id="route-view" className="col-md-12">
				Unable to fetch data.
			</div>
		);
	}
	const userData = data?.data;

	const formattedData = Array.isArray(userData)
		? userData?.map((user, index) => {
			return {
				id: index + 1,
				_id: user?._id,
				name: (
					<Link
						to={`/admin/tracking-domains/edit/${user?._id}`}
						className="profile-view"
					>
						{user?.domain}
					</Link>
				),
				status: user?.status,
				createdAt: user?.createdAt,
				updatedAt: user?.updatedAt,
			};
		})
		: [];

	const columns = [
		{
			name: "ID",
			selector: (row) => row.id,
			sortable: true,
		},
		{
			name: "Name",
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: "Status",
			selector: (row) => row.status,
			cell: (row) => (
				<div>
					{row.status === "active" ? (
						<div>
							<FontAwesomeIcon
								data-toggle="dropdown"
								className="border-0 text-success"
								icon={faCircle}
							/>{" "}
							Active
						</div>
					) : (
						<div>
							<FontAwesomeIcon
								data-toggle="dropdown"
								className="border-0 text-muted"
								icon={faCircle}
							/>{" "}
							Inactive
						</div>
					)}
				</div>
			),
		},
		{
			name: "Creation Date",
			selector: (row) => row.createdAt,
			sortable: true,
		},
		{
			name: "Updated Date",
			selector: (row) => row.updatedAt,
		},
		// {
		// 	name: "",
		// 	cell: (row) => (
		// 		<div className="row-edit text-center">
		// 			<div className="dropdown dropleft show">
		// 				<FontAwesomeIcon
		// 					data-toggle="dropdown"
		// 					className="faCog"
		// 					icon={faCog}
		// 					onClick={() => toggleDropdownVisibility(row.id - 1)}
		// 				/>
		// 				<div
		// 					id={`row-dropdown${row.id - 1}`}
		// 					className={`dropdown-menu dt-dropdown rounded-0 `}
		// 					style={{
		// 						position: "absolute",
		// 						transform: "translate3d(-162px, 0px, 0px)",
		// 						top: "0px",
		// 						left: "0px",
		// 						willChange: "transform",
		// 					}}
		// 				>
		// 					<Link
		// 						to={`/admin/tracking-domains/edit/${row._id}`}
		// 						className="dropdown-item"
		// 					>
		// 						Edit
		// 					</Link>
		// 					{/* <Link
		// 						to={`/admin/tracking-domains/${row._id}`}
		// 						className="dropdown-item"
		// 					>
		// 						View Domain
		// 					</Link> */}
		// 				</div>
		// 			</div>
		// 		</div>
		// 	),
		// },
	];

	const toggleDropdownVisibility = (index, event) => {
		document.getElementById(`row-dropdown${index}`).classList.toggle("show");

		document.querySelectorAll(`.dt-dropdown`).forEach((dropdown) => {
			if (
				dropdown.getAttribute("id") !=
				event?.currentTarget.nextElementSibling.getAttribute("id")
			) {
				dropdown.classList.remove("show");
			}
		});
	};

	// Function to handle search input changes
	const handleFilter = (event) => {
		const value = event.target.value || "";
		setFilterText(value);
	};

	// Apply search filter to the data
	const filteredData = formattedData.filter((item) => {
		const name = item.name.props.children || "";
		const Id = String(item.id) || "";
		const status = item.status; // Assuming status is a boolean property

		return (
			name.toLowerCase().includes(filterText.toLowerCase()) ||
			Id.toLowerCase().includes(filterText.toLowerCase()) ||
			(status ? "Active" : "Pending")
				.toLowerCase()
				.includes(filterText.toLowerCase())
		);
	});

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">
					Tracking Domains
					<div className="side-link">
						<Link to={`/admin/tracking-domains/add`} className="btn btn-side">
							<FontAwesomeIcon icon={faPlus} /> Add New Domain
						</Link>
					</div>
				</h2>
				<div className="table-supplies">
					<div className="top-filters">
						<div className="search-bar w-25 ml-auto">
							<input
								type="text"
								placeholder="Search... ID, Name, Status , etc"
								className="form-control"
								value={filterText}
								onChange={handleFilter}
							/>
						</div>
					</div>
					<div className="dataTable offers-datatable">
						<DataTable
							customStyles={{
								tableWrapper: {
									style: {
										height: '400px',
									},
								},
							}}
							columns={columns}
							data={filteredData}
							pagination
							highlightOnHover
							fixedHeader
							responsive
							paginationPerPage={10}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Domains;
