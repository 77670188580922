import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../../components/common/Layout';
import ErrorPage from '../../components/common/Errorpage/index';
import Dashboard from '../../components/panels/publisher/Dashboard/Dashboard';
import Offers from '../../components/panels/publisher/Offers/Offers';
import OfferSnapshot from '../../components/panels/publisher/Offers/OfferSnapshot';
import ResourceTabs from '../../components/panels/publisher/Offers/ResourceTabs';
import PaymentForm from '../../components/panels/publisher/Payment/PaymentForm';
import PubPaymentHistory from '../../components/panels/publisher/Payment/PubPaymentHistory';
import Clicks from '../../components/panels/publisher/Reporting/Clicks';
import PostbackPixels from '../../components/panels/publisher/Reporting/PostbackPixels';
import MyAccount from '../../components/panels/publisher/MyAccount';
import Notifications from '../../components/common/Layout/Notification/Notifictions';


const PublisherRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="offer" element={<Offers />} />
        <Route path="offer/snapshot/:offerId" element={<OfferSnapshot />} />
        <Route path="offer/snapshot/:offerId/resources" element={<ResourceTabs />} />
        <Route path="payments" element={<PaymentForm />} />
        <Route path="payments-history" element={<PubPaymentHistory />} />
        <Route path="postbackPixel" element={<PostbackPixels />} />
        <Route path="account/:userId" element={<MyAccount />} />
        <Route path="reporting/clicks" element={<Clicks />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>

    </Routes>
  );
};

export default PublisherRoutes;
