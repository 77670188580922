import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAddPostBackUrlMutation, useGetPostBackUrlQuery } from '../../../../slices/apiSlices/publisherApi';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const PostbackPixels = () => {
  const [addPostBackUrl, { isLoading: addLoading }] = useAddPostBackUrlMutation();
  const { data, refetch, error: fetchError } = useGetPostBackUrlQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const [formData, setFormData] = useState({
    postback_url: ''
  });

  useEffect(() => {
    if (data?.data) {
      setFormData({ postback_url: data.data.postback_url || '' });
    }
    if (fetchError) {
      toast.error("Error fetching data.");
    }
  }, [data, fetchError]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await addPostBackUrl(
        formData
      );

      if (res?.data?.success) {
        toast.success(res.data.message);
        refetch();
      } else {
        toast.error(res?.data?.message || "Failed to add postback URL.");
      }
    } catch (error) {
      toast.error("An error occurred while adding the postback URL.");
      console.error("Error adding postback URL:", error);
    }
  };

  return (
    <div id="route-view" className="">
      <div>
        <form onSubmit={handleSubmit}>
          <div
            className="form-panel"
            style={{
              maxWidth: "850px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <h3 className="heading">Add Postback Pixels</h3>
            <div className="" style={{
              padding: "8%",
              paddingTop: "5px",
            }}>
              <h5 style={{ color: "gray" }}>Points to be considered:</h5>
              <ul>
                <li><strong>{"{SUBID}"}</strong> - Your passed subid</li>
                <li><strong>{"{COMMISSION_AMOUNT}"}</strong> - Your Sale commission amount</li>
              </ul>
              <label className="required">Postback Url</label>
              <input
                required
                className={`form-control border ${formData.postback_url.trim() === "" ? "border-danger" : ""}`}
                name="postback_url"
                value={formData.postback_url}
                onChange={handleChange}
              ></input>
            </div>
            <div style={{ left: 0, width: "100%" }} className="form-submit-bar">
              <div>
                <Link
                  to={"/admin"}
                  className="btn btn-sm btn-light text-muted mr-2 px-3"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="mr-2 px-3 btn btn-sm btn-submit"
                  disabled={addLoading}
                >
                  {addLoading ? "Adding..." : "Add"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostbackPixels;
