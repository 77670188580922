import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import adminReducer from '../slices/customSlices/adminSlice';
import publisherReducer from '../slices/customSlices/publisherSlice';
import advertiserReducer from '../slices/customSlices/advertiserSlice';
import commonReducer from '../slices/customSlices/commonSlice';
import { adminApi } from '../slices/apiSlices/adminApi';
import { publisherApi } from '../slices/apiSlices/publisherApi';
import { advertiserApi } from '../slices/apiSlices/advertiserApi';
import tokenExpirationMiddleware from './tokenExpirationMiddleware';
import { otherApi } from '../slices/apiSlices/otherApi';

// Persist configuration for auth state
const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['isAuthenticated', 'user'], // Persist only authentication details
};

// Persist configurations for different slices
const adminPersistConfig = {
  ...authPersistConfig,
  key: 'admin',
};

const publisherPersistConfig = {
  ...authPersistConfig,
  key: 'publisher',
};

const advertiserPersistConfig = {
  ...authPersistConfig,
  key: 'advertiser',
};

// Configure the store with reducers and middleware
const store = configureStore({
  reducer: {
    // Persisted reducers
    admin: persistReducer(adminPersistConfig, adminReducer),
    publisher: persistReducer(publisherPersistConfig, publisherReducer),
    advertiser: persistReducer(advertiserPersistConfig, advertiserReducer),

    // Non-persisted common reducer
    common: commonReducer,

    // RTK Query reducers for APIs
    [adminApi.reducerPath]: adminApi.reducer,
    [advertiserApi.reducerPath]: advertiserApi.reducer,
    [publisherApi.reducerPath]: publisherApi.reducer,
    [otherApi.reducerPath]: otherApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      adminApi.middleware,
      advertiserApi.middleware,
      publisherApi.middleware,
      otherApi.middleware,
      tokenExpirationMiddleware
    ), // Add all RTK Query middlewares
});

// Persistor for persisting the store
export const persistor = persistStore(store);

export default store;
