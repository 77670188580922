import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    unReadCount: null,
    collapsed: false,
  },
  reducers: {
    setUnReadCount: (state, action) => {
      state.unReadCount = action.payload;
    },
    toggleSidebar: (state) => {
      state.collapsed = !state.collapsed;
    },
  },
});

export const { setUnReadCount, toggleSidebar } = commonSlice.actions;
export default commonSlice.reducer;
