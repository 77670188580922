import { useSelector } from 'react-redux';

const useAuthStatus = () => {
  const isAdminAuthenticated = useSelector((state) => state.admin.isAuthenticated);
  const isAdvertiserAuthenticated = useSelector((state) => state.advertiser.isAuthenticated);
  const isPublisherAuthenticated = useSelector((state) => state.publisher.isAuthenticated);

  return {
    isAdminAuthenticated,
    isAdvertiserAuthenticated,
    isPublisherAuthenticated,
  };
};

export default useAuthStatus;
