import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddTrackingDomainMutation } from "../../../../slices/apiSlices/adminApi";

const AddDomain = () => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		domain: "",
		status: "",
	});
	const [error, setError] = useState({});
	const [AddTrackingDomain, { isLoading: AddLoading }] =
		useAddTrackingDomainMutation();

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (error?.data) {
			setError((prevError) => {
				const updatedError = {
					...prevError,
					data: prevError?.data?.filter((item) => item.field !== name),
				};
				return updatedError;
			});
		}
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setError("");
		const res = await AddTrackingDomain(formData);
		if (res.error && res.error.data.errors) {
			const errors = res.error.data.errors;
			setError({ data: errors });
			if (errors.length > 4) {
				toast.error("Please fill in all the required fields.");
			} else {
				errors.forEach((err) => toast.error(err.message));
			}
		}

		if (res.error) {
			return (
				<div id="route-view" className="col-md-12">
					{" "}
					{res?.error?.data?.msg}
				</div>
			);
		} else {
			if (res.data.success === true) {
				toast.success(res.data.msg);
				navigate("/admin/tracking-domains");
			} else {
				toast.info(res.data.msg);
				setError({
					msg: res.data.msg,
				});
			}
		}
	};
	const renderError = (fieldName) => {
		const errorItem = error?.data?.find((item) => item.field === fieldName);
		return errorItem ? (
			<div key={fieldName} className="text-danger">
				{errorItem.message}
			</div>
		) : null;
	};

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">Add Domain</h2>
				<form className="form-panel" onSubmit={handleSubmit}>
					<div className="form-group row">
						<div className="col-sm-5">
							<label className="required">Enter Domain Name</label>{" "}
							<input
								type="text"
								className={`form-control border  ${formData.domain.trim() === "" ? "border-danger" : ""
									}`}
								name="domain"
								value={formData.domain}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("domain")}
							</small>
						</div>
					</div>
					<div className="form-group row">
						<div className="col-sm-6">
							<label className="required">Status</label>
							<select
								className="form-control"
								name="status"
								value={formData.status}
								onChange={handleChange}
							>
								<option value="" defaultValue disabled="disabled">
									Select Status
								</option>
								<option value="active">Active</option>
								<option value="inactive">Inactive</option>
							</select>
							<small className="form-text text-danger">
								{renderError("status")}
							</small>
						</div>
					</div>
					<div className="form-submit-bar">
						{/* <div>
							{error?.data?.map((err, index) => (
								<p className="errors show" key={index}>
									{err?.message}
								</p>
							))}
						</div> */}
						<div>
							<Link
								to={"/admin/tracking-domains"}
								className="btn btn-sm btn-light text-muted mr-2 px-3"
							>
								Cancel
							</Link>{" "}
							<button type="submit" className="mr-2 px-3 btn btn-sm btn-submit">
								{AddLoading ? "Adding..." : "Add"}
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AddDomain;
