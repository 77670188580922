import { logout as logoutAdmin } from '../slices/customSlices/adminSlice';
import { logout as logoutPublisher } from '../slices/customSlices/publisherSlice';
import { logout as logoutAdvertiser } from "../slices/customSlices/advertiserSlice";

const tokenExpirationMiddleware = (store) => (next) => (action) => {
  if (action.type.endsWith('/rejected')) {

    const { error, payload } = action;

    if (error.message === "Rejected" && payload.status === 401) {
      alert('Your session is expired. Please log in again');
      // Token expired, dispatch logout actions
      const { pathname } = window.location;

      if (pathname.startsWith('/admin')) {
        store.dispatch(logoutAdmin());
        window.location.href = '/admin/login';
      } else if (pathname.startsWith('/advertiser')) {
        store.dispatch(logoutAdvertiser());
        window.location.href = '/advertiser/login';
      } else if (pathname.startsWith('/publisher')) {
        store.dispatch(logoutPublisher());
        window.location.href = '/publisher/login';
      }
    }
  }

  return next(action);
};

export default tokenExpirationMiddleware;
