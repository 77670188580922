import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
	useAdminPublisherGetUserQuery,
	useAdminPublisherUpdateUserMutation,
} from "../../../../../slices/apiSlices/adminApi";
import { Link } from "react-router-dom";

const PublisherUpdateUser = () => {
	const { userId } = useParams();
	const [togglePasswords, setTogglePasswords] = useState(false);
	const [error, setError] = useState({});
	const [adminPublisherUpdateUser, { isLoading: UpdateLoading }] =
		useAdminPublisherUpdateUserMutation();
	const {
		data,
		isSuccess,
		isLoading,
		error: resErr,
	} = useAdminPublisherGetUserQuery({
		userId,
	}, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});

	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		status: "",
		title: "",
		cell_phone: "",
		work_phone: "",
		email: "",
		isPasswordChange: togglePasswords || "",
		password: "",
		password_confirmation: "",
		updated_id: userId,
	});

	useEffect(() => {

		const userData = data?.data;
		setFormData({
			first_name: userData?.first_name || "",
			last_name: userData?.last_name || "",
			status: userData?.status || "",
			title: userData?.title || "",
			cell_phone: userData?.cell_phone || "",
			work_phone: userData?.work_phone || "",
			email: userData?.email || "",
			isPasswordChange: togglePasswords || "",
			password: "",
			password_confirmation: "",
			updated_id: userId,
		});
	}, [data]);

	if (isLoading) {
		return <div>Loading...</div>;
	}
	if (resErr) {
		return <div>Error: {resErr?.data?.msg}</div>;
	}

	if (!isSuccess) {
		return <div>Unable to fetch data.</div>;
	}

	const handleTogglePassword = () => {
		setTogglePasswords(!togglePasswords);
		setFormData({
			...formData,
			isPasswordChange: !togglePasswords,
		});
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		if (error?.data) {
			setError((prevError) => {
				const updatedError = {
					...prevError,
					data: prevError?.data?.filter((item) => item.field !== name),
				};
				return updatedError;
			});
		}
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const res = await adminPublisherUpdateUser({ formData });
		if (res.error && res.error.data.errors) {
			const errors = res.error.data.errors;
			setError({ data: errors });
			if (errors.length > 4) {
				toast.error("Please fill in all the required fields.");
			} else {
				errors.forEach((err) => toast.error(err.message));
			}
		} else {
			if (res.data.success === true) {
				toast.success(res.data.msg);
				window.history.go(-1);
			} else {
				toast.info(res.data.msg);
				console.log(res.data.msg, res.data.status);
				setError({
					msg: res.data.msg,
				});
			}
		}
	};
	const renderError = (fieldName) => {
		const errorItem = error?.data?.find((item) => item.field === fieldName);
		return errorItem ? (
			<div key={fieldName} className="text-danger">
				{errorItem.message}
			</div>
		) : null;
	};
	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">Update Publisher User</h2>
				<form onSubmit={handleSubmit}>
					<div className="form-panel">
						<h3 className="heading">Update Details</h3>
						<div className="form-group">
							<label htmlFor="" className="required">
								First Name
							</label>{" "}
							<input
								type="text"
								className={`form-control border  ${formData.first_name.trim() === "" ? "border-danger" : ""
									}`}
								name="first_name"
								value={formData.first_name}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("first_name")}
							</small>
						</div>{" "}
						<div className="form-group">
							<label htmlFor="" className="required">
								Last Name
							</label>{" "}
							<input
								type="text"
								className={`form-control border  ${formData.last_name.trim() === "" ? "border-danger" : ""
									}`}
								name="last_name"
								value={formData.last_name}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("last_name")}
							</small>
						</div>{" "}
						<div className="form-group">
							<label htmlFor="">Title</label>{" "}
							<select
								id=""
								className="form-control"
								name="title"
								value={formData.title}
								onChange={handleChange}
							>
								<option value="" defaultValue disabled="disabled">
									Select Title
								</option>{" "}
								<option value="Mr.">Mr.</option>{" "}
								<option value="Mrs.">Mrs.</option>{" "}
								<option value="Miss">Miss</option>
							</select>
							<small className="form-text text-danger">
								{renderError("title")}
							</small>
						</div>
						<div className="form-group">
							<label htmlFor="" className="required">
								Email
							</label>{" "}
							<input
								type="text"
								className={`form-control border  ${formData.email.trim() === "" ? "border-danger" : ""
									}`}
								name="email"
								value={formData.email}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("email")}
							</small>
						</div>
						<div className="form-group row">
							<div className="col-md-6">
								<label htmlFor="" className="required">
									Permanent Phone
								</label>{" "}
								<input
									type="text"
									className={`form-control border ${formData.cell_phone.toString().trim() === ""
										? "border-danger"
										: ""
										}`}
									name="cell_phone"
									value={formData.cell_phone}
									onChange={handleChange}
									onKeyPress={(e) => {
										const isNumeric = /^[0-9]*$/.test(e.key);
										if (!isNumeric) {
											e.preventDefault();
										}
									}}
								/>
								<small className="form-text text-danger">
									{renderError("cell_phone")}
								</small>
							</div>{" "}
							<div className="col-md-6">
								<label htmlFor="">Work Phone</label>{" "}
								<input
									type="text"
									className="form-control"
									name="work_phone"
									value={formData.work_phone}
									onChange={handleChange}
									onKeyPress={(e) => {
										const isNumeric = /^[0-9]*$/.test(e.key);
										if (!isNumeric) {
											e.preventDefault();
										}
									}}
								/>
							</div>
						</div>
						<div className="form-group">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									id="cb-pass"
									className="custom-control-input"
									checked={!togglePasswords}
									value={true}
									onChange={handleTogglePassword}
								/>{" "}
								<label
									htmlFor="cb-pass"
									className="custom-control-label m-0 d-inline"
								>
									Keep Current Password
								</label>
							</div>
						</div>
						{togglePasswords && (
							<div>
								<div className="form-group">
									<label htmlFor="" className="required">
										Password
									</label>{" "}
									<input
										type="text"
										className={`form-control border  ${formData.password.trim() === "" ? "border-danger" : ""
											}`}
										name="password"
										value={formData.password}
										onChange={handleChange}
									/>
									<small className="form-text text-danger">
										{renderError("password")}
									</small>
								</div>
								<div className="form-group">
									<label htmlFor="" className="required">
										Confirm Password
									</label>{" "}
									<input
										type="text"
										className={`form-control border  ${formData.password_confirmation.trim() === ""
											? "border-danger"
											: ""
											}`}
										name="password_confirmation"
										value={formData.password_confirmation}
										onChange={handleChange}
									/>{" "}
									<small className="form-text text-danger">
										{renderError("password_confirmation")}
									</small>
									<small className="form-text info-text">
										Password must contain at least 8 characters
									</small>
								</div>
							</div>
						)}
					</div>
					<div className="form-panel">
						<h3 className="heading">Settings</h3>
						<div className="form-group">
							<label htmlFor="" className="required">
								Status
							</label>
							<select
								className="form-control"
								name="status"
								value={formData.status}
								onChange={handleChange}
							>
								<option value=""></option>
								<option value="active">Active</option>
								<option value="pending">Pending</option>
								<option value="inactive">Inactive</option>
								<option value="suspended">Suspended</option>
							</select>
							<small className="form-text text-danger">
								{renderError("status")}
							</small>
						</div>
						<div className="form-submit-bar">
							<div>
								<Link
									onClick={() => window.history.go(-1)}
									className="btn btn-sm btn-light text-muted mr-2 px-3"
								>
									Cancel
								</Link>{" "}
								<button
									type="submit"
									className="mr-2 px-3 btn btn-sm btn-submit"
									disabled={UpdateLoading}
								>
									{UpdateLoading ? "Updating..." : "Update"}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PublisherUpdateUser;
