import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoutes = ({ restricted, isAuthenticated, path, loginPath, ...rest }) => {

  return (
    // If the route is restricted (like login) and user is authenticated, redirect to a different page
    isAuthenticated && restricted ? <Navigate to={path} /> : <Navigate to={loginPath} />
  );
};

export default PublicRoutes;
