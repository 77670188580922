import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

const QuickStats = ({ paymentData }) => {
	const graphData = paymentData?.graph_data;

	const options = {
		title: "Amount in months",
		legend: { position: "bottom" },
		bars: "vertical",
		bar: { groupWidth: "50%" },
		chartArea: {
			width: "60%",
			height: "80%",
		},
		hAxis: {
			gridlines: { count: 0 },
			minorGridlines: { count: 0 },
		},
		colors: ["#17a2b8"],
	};

	// const [chartData, setChartData] = useState(null);
	const statsdata = paymentData || "";

	// useEffect(() => {
	// 	if (graphData) {
	// 		setChartData(graphData);
	// 	}
	// }, []);
	return (
		<div className="quickstats">
			<div className="quickstats-header">
				<span style={{ paddingRight: "8px" }}>$</span> Quick Stats
			</div>
			<div>
				<div className="quickstats-top">
					<div className="quickstats-card">
						<span className="quickstats-amount">
							${statsdata.last_30_days_earnings}
						</span>
						<span className="quickstats-description">Commission 30d</span>
					</div>
					<div className="quickstats-card">
						<span className="quickstats-amount">
							${statsdata.last_transferred_amount}
						</span>
						<span className="quickstats-description">Last trasfer</span>
					</div>
					<div className="quickstats-card">
						<span className="quickstats-amount">
							${statsdata.total_remaining_balance}
						</span>
						<span className="quickstats-description">Balance</span>
					</div>
				</div>
				<div className="quickstats-middle">
					<div className="middle-right">
						<div className="graph-card">
							{graphData ? (
								<Chart
									chartType="BarChart"
									width="100%"
									height="400px"
									data={graphData}
									options={options}
								/>
							) : (
								<div className="chart-container">
									Commission graph will be shown here
								</div>
							)}
						</div>
					</div>
					<div className="left">
						<div className="conversion-card">
							<span className="conversion-rate">
								{statsdata?.conversion_rate || "00"}%
							</span>
							<span className="quickstats-description">
								Conversion Rate(Last 30 days)
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default QuickStats;
