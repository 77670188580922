import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useSearchParams } from "react-router-dom";
import { useGetClicksQuery } from "../../../../slices/apiSlices/adminApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

function Clicks() {
	const end_Date = new Date().toISOString().split("T")[0];
	const firstDayOfMonth = new Date(
		new Date().getFullYear(),
		new Date().getMonth(),
		2
	);
	const start_Date = firstDayOfMonth.toISOString().split("T")[0];

	const [searchParams, setSearchParams] = useSearchParams();
	const startDateParam = searchParams.get("start_date");
	const endDateParam = searchParams.get("end_date");

	const [startDate, setStartDate] = useState(start_Date);
	const [endDate, setEndDate] = useState(end_Date);

	const [filterText, setFilterText] = useState("");
	const { data, error, isLoading, isSuccess } = useGetClicksQuery({
		startDate,
		endDate,
	}, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});

	useEffect(() => {
		const fetchData = async () => {
			setStartDate(startDateParam || startDate);
			setEndDate(endDateParam || endDate);
		};
		fetchData();
	}, [startDate, endDate, startDateParam, endDateParam]);

	if (isLoading) {
		return (
			<div id="route-view" className="col-md-12">
				Loading...
			</div>
		);
	}
	if (error) {
		return (
			<div id="route-view" className="col-md-12">
				{" "}
				{error?.data?.msg}
			</div>
		);
	}

	if (!isSuccess) {
		return (
			<div id="route-view" className="col-md-12">
				Unable to fetch data.
			</div>
		);
	}
	const userData = data?.data;

	const formattedData = Array.isArray(userData)
		? userData?.map((user, index) => {
			return {
				id: index + 1,
				_id: user?._id,
				name: (
					<Link
						className="profile-view"
						onClick={() =>
							window.open(`/admin/offers/snapshot/${user._id}`, "_blank")
						}
					>
						{user?.name}
					</Link>
				),
				clicks: user?.clicks !== undefined ? user.clicks : 0,
				conversions: user?.conversions !== undefined ? user.conversions : 0,
				conversionRate: user?.conversions
					? ((user?.conversions / user?.clicks) * 100).toFixed(2) + "%"
					: "N/A",
				commission: user?.commission !== undefined ? "$" + user.commission : "$0",
			};
		})
		: [];

	const columns = [
		{
			name: "ID",
			selector: (row) => row.id,
			sortable: true,
		},
		{
			name: "Offer",
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: "Clicks",
			selector: (row) => row.clicks,
			sortable: true,
		},
		{
			name: "Conversions",
			selector: (row) => row.conversions,
		},
		{
			name: "Commissions",
			selector: (row) => row.commission,
		},
		{
			name: "Conversion rate",
			selector: (row) => row.conversionRate,
		},
		{
			name: "",
			cell: (row) => (
				<div className="row-edit text-center">
					<div className="dropdown dropleft show">
						<FontAwesomeIcon
							data-toggle="dropdown"
							className="faCog"
							icon={faCog}
							onClick={(event) => toggleDropdownVisibility(row.id - 1, event)}
						/>
						<div
							id={`row-dropdown${row.id - 1}`}
							className={`dropdown-menu dt-dropdown rounded-0 `}
							style={{
								position: "absolute",
								transform: "translate3d(-162px, 0px, 0px)",
								top: "0px",
								left: "0px",
								willChange: "transform",
							}}
						>
							<Link
								to={`/admin/reporting/clicks/${row._id}/?start_date=${startDateParam}&end_date=${endDateParam}`}
								className="dropdown-item"
							>
								Clicks by publishers
							</Link>
						</div>
					</div>
				</div>
			),
		},
	];

	const toggleDropdownVisibility = (index, event) => {
		document.getElementById(`row-dropdown${index}`).classList.toggle("show");

		document.querySelectorAll(`.dt-dropdown`).forEach((dropdown) => {
			if (
				dropdown.getAttribute("id") !=
				event?.currentTarget.nextElementSibling.getAttribute("id")
			) {
				dropdown.classList.remove("show");
			}
		});
	};

	const handleFilter = (event) => {
		const value = event.target.value || "";
		setFilterText(value);
	};

	const filteredData = formattedData.filter((item) => {
		const name = item.name.props.children || "";
		return name.toLowerCase().includes(filterText.toLowerCase());
	});
	const handleStartDateChange = (date) => {
		setStartDate(date);
		updateURL(date, endDate);
	};

	const handleEndDateChange = (date) => {
		setEndDate(date);
		updateURL(startDate, date);
	};

	const updateURL = (startDate, endDate) => {
		setSearchParams(
			`?${new URLSearchParams({ start_date: startDate, end_date: endDate })}`
		);
	};

	return (
		<div id="route-view" className="col-md-12">
			<div data-v-a5b54c2a="">
				<h2 data-v-a5b54c2a="" className="heading">
					Clicks
				</h2>
				<div className="admin-date-filters">
					<input
						name="start_date"
						type="date"
						value={startDate}
						onChange={(event) => handleStartDateChange(event.target.value)}
					/>
					<span>-</span>
					<input
						name="end_date"
						type="date"
						value={endDate}
						onChange={(event) => handleEndDateChange(event.target.value)}
					/>
				</div>
				<div data-v-a5b54c2a="" className="row mt-4">
					<div data-v-a5b54c2a="" className="col-md-12">
						<div data-v-a5b54c2a="" className="dataTables_wrapper dt-bootstrap">
							<div className="row form-inputs">
								<div className="col-sm-4">
									<div className="alignCenter">
										<input
											data-v-a5b54c2a=""
											type="text"
											className="date-range-picker"
										/>
										<button
											data-v-a5b54c2a=""
											className="btn btn-default btn-sm clear-filter-button btn-outline-primary input-button-sm"
										>
											Generate Report
										</button>
									</div>
								</div>
								<div className="offset-sm-4 col-sm-4">
									<input
										type="text"
										placeholder="Search"
										className="form-control"
										value={filterText}
										onChange={handleFilter}
									/>
								</div>
							</div>
							<div className="dataTable">
								<DataTable
									title=""
									columns={columns}
									data={filteredData}
									pagination
									highlightOnHover
									fixedHeader
									paginationPerPage={10}
									customStyles={{
										tableWrapper: {
											style: {
												height: '60vh'
											},
										},
										header: {
											style: {
												display: "flex",
												justifyContent: "center",
											},
										},
										rows: {
											style: {
												display: "flex",
												justifyContent: "center",
											},
										},
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Clicks;
