import React from 'react'
import { useParams } from 'react-router-dom';
import { useAdminGetClickDetailsQuery } from "../../../../slices/apiSlices/adminApi";

const ClickDetails = () => {
  const { clickId } = useParams();
  const { data, isLoading, isSuccess, error } = useAdminGetClickDetailsQuery({
    clickId,
  }, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error?.data?.msg}</div>;
  }

  if (!isSuccess) {
    return <div>Unable to fetch data.</div>;
  }
  const clickDetail = data?.data[0] || {};
  const { ip_info, received_parameters, sent_parameters, ...otherDetails } = clickDetail;

  const formatKey = (key) => {
    return key
      .replace(/_/g, ' ') // Replace underscores with spaces
      .split(' ') // Split the string by spaces
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join the words back together
  };

  const renderDetails = (details, format = true) => {
    return Object.keys(details).map((key) => (
      <div className="body-row" key={key}>
        <div className="left">
          <span style={{ wordBreak: 'break-all', wordWrap: 'break-word', fontWeight: format ? "bold" : "normal" }}>
            {format ? formatKey(key) : key}
          </span>
        </div>
        <div className="right">
          <span style={{ wordBreak: 'break-all', wordWrap: 'break-word' }}>{details[key]}</span>
        </div>
      </div>
    ));
  };

  return (
    <div id="route-view" className="col-md-12">
      <div>
        <h2 className="main-heading">Click Details</h2>
        <div className="my-account-main">
          <div className="my-account">
            <div className="account shadow">
              <div className="head">
                <div className="left">
                  <span>General Information</span>
                </div>
              </div>
              <div className="body">
                {renderDetails(otherDetails)}
              </div>
            </div>
          </div>
          <div className="my-account">
            <div className="account shadow">
              <div className="head">
                <div className="left">
                  <span>IP Information</span>
                </div>
              </div>
              <div className="body">
                {renderDetails(ip_info)}
              </div>
            </div>
            <div className="account shadow">
              <div className="head">
                <div className="left">
                  <span>Received Parameters</span>
                </div>
              </div>
              <div className="body">
                {renderDetails(received_parameters, false)}
              </div>
            </div>
            <div className="account shadow">
              <div className="head">
                <div className="left">
                  <span>Sent Parameters</span>
                </div>
              </div>
              <div className="body">
                {renderDetails(sent_parameters, false)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClickDetails