import { useSelector } from "react-redux";

const useUserData = () => {
  const adminData = useSelector((state) => state.admin);
  const advertiserData = useSelector((state) => state.advertiser);
  const publisherData = useSelector((state) => state.publisher);

  return {
    adminData,
    advertiserData,
    publisherData,
  };
};

export default useUserData;
