import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { faCircle, faCog, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useGetAllAdminQuery } from "../../../../../slices/apiSlices/adminApi";

const Accounts = () => {
	const [filterText, setFilterText] = useState("");
	const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);

	const { data, isLoading, isSuccess, error } =
		useGetAllAdminQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});

	if (isLoading) {
		return (
			<div id="route-view" className="col-md-12">
				Loading...
			</div>
		);
	}
	if (error) {
		return (
			<div id="route-view" className="col-md-12">
				{" "}
				{error?.data?.msg}
			</div>
		);
	}

	if (!isSuccess) {
		return (
			<div id="route-view" className="col-md-12">
				Unable to fetch data.
			</div>
		);
	}
	const userData = data?.data;

	const formattedData = Array.isArray(userData)
		? userData?.map((user, index) => {
			return {
				id: index + 1,
				_id: user?._id,
				title: user?.title,
				name: (
					<Link to={`/admin/account/${user?._id}`} className="profile-view">
						{user?.name}
					</Link>
				),
				email: user?.email,
				status: user?.status,
				role: user?.role_data.role,
				contactNo: user?.cell_phone,
			};
		})
		: [];

	const columns = [
		{
			name: "ID",
			selector: (row) => row.id,
			sortable: true,
			width: "10%",
		},
		{
			name: "Title",
			selector: (row) => row.title,
			sortable: true,
			width: "10%",
		},
		{
			name: "Name",
			selector: (row) => row.name,
			sortable: true,
			width: "20%",
		},
		{
			name: "Status",
			selector: (row) => row.status,
			cell: (row) => (
				<div>
					{row.status == "active" ? (
						<div style={{ textTransform: "capitalize" }}>
							<FontAwesomeIcon
								data-toggle="dropdown"
								className="border-0 text-success"
								icon={faCircle}
							/>{" "}
							{row.status}
						</div>
					) : (
						<div style={{ textTransform: "capitalize" }}>
							<FontAwesomeIcon
								data-toggle="dropdown"
								className="border-0 text-warning"
								icon={faCircle}
							/>{" "}
							{row.status}
						</div>
					)}
				</div>
			),
			width: "10%",
		},
		{
			name: "Email",
			selector: (row) => row.email,
			sortable: true,
			width: "15%",
		},
		{
			name: "Role",
			selector: (row) => row.role,
			width: "10%",
		},
		{
			name: "Contact No",
			selector: (row) => row.contactNo,
			width: "15%",
		},
		{
			name: "",
			cell: (row) => (
				<div className="row-edit text-center">
					<div className="dropdown show">
						<FontAwesomeIcon
							data-toggle="dropdown"
							className="faCog"
							icon={faCog}
							onClick={() => toggleDropdownVisibility(row.id - 1)}
						/>
						<div
							id={`row-dropdown${row.id - 1}`}
							// className={`dropdown-menu dt-dropdown rounded-0 `}
							className={`dropdown-menu rounded-0 ${activeDropdownIndex === row.id - 1 ? "show" : ""}`}
							style={{
								position: "absolute",
								transform: "translate3d(-79px, 18px, 0px)",
								top: "0px",
								left: "0px",
								willChange: "transform",
							}}
						>
							<Link
								to={`/admin/account/edit/${row._id}`}
								className="dropdown-item"
							>
								Edit
							</Link>
						</div>
					</div>
				</div>
			),
			width: "10%",
		},
	];

	const toggleDropdownVisibility = (index) => {
		if (activeDropdownIndex === index) {
			setActiveDropdownIndex(null);
		} else {
			// Otherwise, open the clicked row's dropdown and close any other open dropdown
			setActiveDropdownIndex(index);
		}
	};

	// Function to handle search input changes
	const handleFilter = (event) => {
		const value = event.target.value || "";
		setFilterText(value);
	};

	// Apply search filter to the data
	const filteredData = formattedData.filter((item) => {
		const name = item.name.props.children || "";
		const accountId = String(item.id) || "";
		const manager = item.email || "";
		const country = item.role || "";
		const status = item.status; // Assuming status is a boolean property

		return (
			name.toLowerCase().includes(filterText.toLowerCase()) ||
			accountId.toLowerCase().includes(filterText.toLowerCase()) ||
			manager.toLowerCase().includes(filterText.toLowerCase()) ||
			country.toLowerCase().includes(filterText.toLowerCase()) ||
			(status ? "Active" : "Pending")
				.toLowerCase()
				.includes(filterText.toLowerCase())
		);
	});

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">
					Accounts
					<div className="side-link">
						<Link to={`/admin/accounts/add`} className="btn btn-side">
							<FontAwesomeIcon icon={faPlus} /> Add New Account
						</Link>
					</div>
				</h2>
				<div className="table-supplies">
					<div className="top-filters">
						<div className="search-bar w-25 ml-auto">
							<input
								type="text"
								placeholder="Search... ID, Name, Status , etc"
								className="form-control"
								value={filterText}
								onChange={handleFilter}
							/>
						</div>
					</div>
					<div className="dataTable">
						<DataTable
							title=""
							columns={columns}
							data={filteredData}
							pagination
							highlightOnHover
							fixedHeader
							paginationPerPage={10}
							customStyles={{
								tableWrapper: {
									style: {
										height: '60vh'
									},
								},
								header: {
									style: {
										// Style for header cells
										display: "flex",
										justifyContent: "center",
										// You can add more specific styling for individual header cells if needed
									},
								},
								rows: {
									style: {
										display: "flex",
										justifyContent: "center",
									},
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Accounts;
