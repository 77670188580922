import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { faCircle, faCog, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { useAdminPublisherGetAllUsersQuery } from "../../../../../slices/apiSlices/adminApi";

const PublisherUsers = () => {
	const { publisherId } = useParams();
	const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);

	const { data, isLoading, error, isSuccess } =
		useAdminPublisherGetAllUsersQuery({
			publisherId,
		}, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});

	if (isLoading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>Error: {error?.data?.msg}</div>;
	}

	if (!isSuccess) {
		return <div>Unable to fetch data.</div>;
	}
	const userData = data?.data;

	const formattedData = Array.isArray(userData)
		? userData.map((user, index) => {
			return {
				_id: user._id,
				id: index + 1,
				email: user?.email,
				name: (
					<Link
						to={`/admin/publisher-accounts/publishers/userview/${user._id}`}
						className="profile-view"
					>
						{user.name}
					</Link>
				),
				status: user?.status,
				contactno: user?.cell_phone,
				createdAt: new Date(user.createdAt).toLocaleDateString(),
			};
		})
		: [];

	const columns = [
		{
			name: "#ID",
			selector: (row) => row.id,
			sortable: true,
			width: "10%",
		},
		{
			name: "E-Mail",
			selector: (row) => row.email,
			sortable: true,
			width: "20%",
		},

		{
			name: "Name",
			selector: (row) => row.name,
			sortable: true,
			width: "15%",
		},
		{
			name: "Status",
			selector: (row) => row.status,
			cell: (row) => {
				let statusIcon;
				let statusText;

				switch (row.status) {
					case "active":
						statusIcon = <FontAwesomeIcon className="border-0 text-success" icon={faCircle} />;
						statusText = "Active";
						break;
					case "suspended":
						statusIcon = <FontAwesomeIcon className="border-0 text-muted" icon={faCircle} />;
						statusText = "Suspended";
						break;
					case "pending":
						statusIcon = <FontAwesomeIcon className="border-0 text-warning" icon={faCircle} />;
						statusText = "Pending";
						break;
					case "inactive":
						statusIcon = <FontAwesomeIcon className="border-0 text-danger" icon={faCircle} />;
						statusText = "Inactive";
						break;
					default:
						statusIcon = <FontAwesomeIcon className="border-0 text-secondary" icon={faCircle} />;
						statusText = row.status;
				}

				return (
					<div>
						{statusIcon} {statusText}
					</div>
				);
			},
			width: "10%",
		},
		{
			name: "Contact No",
			selector: (row) => row.contactno,
			sortable: true,
			width: "15%",
		},
		{
			name: "Created At",
			selector: (row) => row.createdAt,
			sortable: true,
			width: "10%",
		},
		{
			name: "",
			cell: (row) => (
				<div className="row-edit text-center">
					<div className="dropdown show">
						<FontAwesomeIcon
							data-toggle="dropdown"
							className="faCog"
							icon={faCog}
							onClick={() => toggleDropdownVisibility(row.id - 1)}
						/>

						<div
							className={`dropdown-menu rounded-0 ${activeDropdownIndex === row.id - 1 ? "show" : ""
								}`}
							style={
								activeDropdownIndex === row.id - 1
									? {
										position: "absolute",
										transform: "translate3d(-79px, 18px, 0px)",
										top: "0px",
										left: "0px",
										willChange: "transform",
									}
									: {}
							}
						>
							<Link
								to={`/admin/publisher-accounts/publishers/edit/${row._id}`}
								className="dropdown-item"
							>
								Edit
							</Link>
						</div>
					</div>
				</div>
			),
			width: "10%",
			style: { display: "flex", justifyContent: "center" },
		},
	];
	const toggleDropdownVisibility = (index) => {
		// If the clicked row's dropdown is already open, close it
		if (activeDropdownIndex === index) {
			setActiveDropdownIndex(null);
		} else {
			// Otherwise, open the clicked row's dropdown and close any other open dropdown
			setActiveDropdownIndex(index);
		}
	};

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">
					Publisher Users ( {userData[0]?.publisher_account?.name} )
					<div className="side-link">
						<Link
							to={`/admin/publisher-accounts/publishers/add/${publisherId}`}
							className="btn btn-side"
						>
							<FontAwesomeIcon icon={faPlus} />
							Add New Publisher User
						</Link>
					</div>
				</h2>
				<div className="table-supplies">
					<div className="top-filters">
						<div className="search-bar w-25 ml-auto">
							{/* <input
                type="text"
                placeholder="Search... ID, Name, Status , etc"
                className="form-control"
                value={filterText}
                onChange={handleFilter}
              /> */}
						</div>
					</div>
					<div className="dataTable">
						<DataTable
							title=""
							columns={columns}
							data={formattedData}
							pagination
							highlightOnHover
							fixedHeader
							paginationPerPage={10}
							customStyles={{
								tableWrapper: {
									style: {
										height: '60vh'
									},
								},
								headRow: {
									style: {
										// Style for the entire header row
										display: "flex",
										justifyContent: "center",
										background: "your_custom_background_color", // Add your custom background color
										color: "your_custom_text_color", // Add your custom text color
									},
								},
								headCells: {
									style: {
										// Style for individual header cells
										// Add any additional styling for header cells here
									},
								},
								rows: {
									style: {
										display: "flex",
										justifyContent: "center",
									},
								},
								cells: {
									style: {
										// Style for individual cells in the rows
										textAlign: "center", // Center the content horizontally
										// Add any additional styling for cells here
									},
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PublisherUsers;
