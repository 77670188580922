import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const apiUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl + "/advertiser/",
  prepareHeaders: (headers, { getState }) => {
    // Access the token from the Redux state
    const data = getState().advertiser;
    const token = data?.user?.token

    if (token) {
      // Set the Authorization header for every request
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithInterceptor = async (args, api, extraOptions) => {
  let result;

  try {
    // Pre-request logic (like logging, modifying request)
    // console.log('Request started:', args);

    result = await baseQuery(args, api, extraOptions);

    // Post-request logic (like logging, handling responses)
    // console.log('Request completed:', result);

    // Handle custom logic for certain responses (e.g., automatic logout on 401)
    if (result.error && result.error.status === 401) {
      // Perform custom action like logging out
      // api.dispatch(logout());
    }

  } catch (error) {
    console.error('Request failed:', error);
  }

  return result;
};

export const advertiserApi = createApi({
  reducerPath: "advertiserApi",
  baseQuery: baseQueryWithInterceptor, // Use the baseQuery with prepareHeaders

  endpoints: (builder) => ({
    loginAdvertiser: builder.mutation({
      query: (advertiser) => {
        return {
          url: "login",
          method: "POST",
          body: advertiser,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    getLoggedAdvertiser: builder.query({
      query: () => {
        return {
          url: `logged-advertiser`,
          method: "GET",
        };
      },
    }),
    changeAdvertiserPassword: builder.mutation({
      query: ({ changedPassword, userId }) => {
        return {
          url: `password_change/${userId}`,
          method: "PATCH",
          body: changedPassword,
        };
      },
    }),
    getNotifications: builder.query({
      query: ({ limit, offset, status }) => {
        return {
          url: `notifications?limit=${limit}&offset=${offset}&status=${status}`,
          method: "GET",
        };
      },
    }),

    readNotification: builder.mutation({
      query: (notificationId) => {
        return {
          url: `notifications/read/${notificationId}`,
          method: "PATCH",
        };
      },
    }),
    readAllNotification: builder.mutation({
      query: () => {
        return {
          url: `notifications/read/all`,
          method: "PATCH",
        };
      },
    }),

    advertiserGetAllOffers: builder.query({
      query: ({
        media_type_id,
        verticle_id,
        price_format_id,
        device_id,
        country,
        status,
      }) => {
        const queryParams = new URLSearchParams({
          media_type_id: media_type_id || "",
          verticle_id: verticle_id || "",
          price_format_id: price_format_id || "",
          device_id: device_id || "",
          country: country || "",
          status: status || "",
        });
        return {
          url: `offers?${queryParams.toString()}`,
          method: "GET",
        };
      },
    }),

    advertiserGetOffer: builder.query({
      query: (offerId) => {
        return {
          url: `offers/${offerId}`,
          method: "GET",
        };
      },
    }),
    GetAdvertiser: builder.query({
      query: (userId) => {
        return {
          url: `account/${userId}`,
          method: "GET",
        };
      },
    }),
    getDashboardData: builder.query({
      query: ({ startDate, endDate, timeUnit }) => {
        return {
          url: `dashboard/${timeUnit}`,
          method: "GET",
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        };
      },
    }),
    getClicks: builder.query({
      query: () => {
        return {
          url: `clicks`,
          method: "GET",
        };
      },
    }),
    advertiserAddOffer: builder.mutation({
      query: ({ updatedFormData }) => {
        return {
          url: "offers/add",
          method: "POST",
          body: updatedFormData,
        };
      },
    }),
    advertiserupdateOffer: builder.mutation({
      query: ({ updatedFormData, offerId }) => {
        return {
          url: `offers/edit/${offerId}`,
          method: "PATCH",
          body: updatedFormData,
        };
      },
    }),
  }),
});

export const {
  useLoginAdvertiserMutation,
  useGetLoggedAdvertiserQuery,
  useChangeAdvertiserPasswordMutation,
  useGetNotificationsQuery,
  useReadAllNotificationMutation,
  useReadNotificationMutation,
  useAdvertiserGetAllOffersQuery,
  useAdvertiserGetOfferQuery,
  useGetAdvertiserQuery,
  useGetDashboardDataQuery,
  useGetClicksQuery,
  useAdvertiserAddOfferMutation,
  useAdvertiserupdateOfferMutation,
} = advertiserApi;