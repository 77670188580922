import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";
import { timeAgo } from "../../../../utils/Utility";
import {
	useGetNotificationsQuery as useAdminNotificationsQuery,
	useReadAllNotificationMutation as useAdminReadAllNotificationMutation,
	useReadNotificationMutation as useAdminReadNotificationMutation
} from "../../../../slices/apiSlices/adminApi";

import {
	useGetNotificationsQuery as useAdvertiserNotificationsQuery,
	useReadAllNotificationMutation as useAdvertiserReadAllNotificationMutation,
	useReadNotificationMutation as useAdvertiserReadNotificationMutation
} from "../../../../slices/apiSlices/advertiserApi";

import {
	useGetNotificationsQuery as usePublisherNotificationsQuery,
	useReadAllNotificationMutation as usePublisherReadAllNotificationMutation,
	useReadNotificationMutation as usePublisherReadNotificationMutation
} from "../../../../slices/apiSlices/publisherApi";


const Notifications = () => {

	const location = useLocation();
	const currentPath = location.pathname;
	const user = currentPath.split('/')[1];


	const [params, setParams] = useState({
		limit: 10,
		offset: 0,
		status: undefined,
	});
	const [notifications, setNotifications] = useState([]);



	const {
		data
	} = (user === "admin" ? useAdminNotificationsQuery :
		user === "advertiser" ? useAdvertiserNotificationsQuery :
			usePublisherNotificationsQuery)(params, {
				refetchOnMountOrArgChange: true,
				refetchOnReconnect: true,
			});

	const [readNotification] = (user === "admin" ? useAdminReadNotificationMutation :
		user === "advertiser" ? useAdvertiserReadNotificationMutation :
			usePublisherReadNotificationMutation)();

	const [readAllNotification] = (user === "admin" ? useAdminReadAllNotificationMutation :
		user === "advertiser" ? useAdvertiserReadAllNotificationMutation :
			usePublisherReadAllNotificationMutation)();

	const [totalNotifications, setTotalNotifications] = useState(0);
	const [onAll, setOnAll] = useState(true);

	useEffect(() => {
		setNotifications(data?.data.notifications || []);
	}, [data]);

	useEffect(() => {
		if (data) {
			onAll
				? setTotalNotifications(data?.data.total || 0)
				: setTotalNotifications(data?.data.unreadCount);
		}
	}, [data]);

	const handleFilter = (filter) => {
		if (filter == "unread") {
			setOnAll(false);
		} else {
			setOnAll(true);
		}
		setParams((prevParams) => ({
			...prevParams,
			status: filter,
		}));
		fetchMore();
	};

	const fetchMore = () => {
		setTimeout(() => {
			setParams((prevParams) => ({
				...prevParams,
				limit: prevParams.limit + 10,
			}));
		}, 1500);
	};

	const markAsRead = async (notificationId) => {
		const updatedNotifications = notifications.map(async (notification) => {
			if (
				notification._id === notificationId &&
				notification.read_at === null
			) {
				try {
					await readNotification(notificationId);
				} catch (error) {
					console.error("Error updating read status:", error);
				}
			}
			return notification;
		});

		await Promise.all(updatedNotifications);
	};
	const handleReadAll = async () => {
		if (data?.data?.unreadCount > 0) {
			const confirmation = window.confirm(
				"Are you sure you want to mark all notifications as read?"
			);
			if (confirmation) {
				await readAllNotification();
			}
		} else {
			alert("All notifications are already read.");
		}
	};

	return (
		<div id="route-view" style={{ overflowY: "unset" }}>
			<div className="notifications-main">
				<div className="notification-head">
					<div className="notification-filters">
						<div
							className={`all-notifications ${onAll ? "selected-color" : ""}`}
							onClick={() => handleFilter("")}
						>
							All
						</div>
						<div
							className={`Unread-notifications ${onAll ? "" : "selected-color"
								} `}
							onClick={() => handleFilter("unread")}
						>
							Unread
						</div>
					</div>
					{totalNotifications > 0 && (
						<div className="read-all" onClick={handleReadAll}>
							Read All
						</div>
					)}
				</div>
				<div className="position-relative">
					<div className="scrollable-notifications">
						<InfiniteScroll
							dataLength={notifications.length} //This is important field to render the next data
							next={fetchMore}
							hasMore={notifications.length < totalNotifications}
							loader={
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										padding: "30px",
									}}
								>
									<CircularProgress
										style={{
											color: "#609bbd",
										}}
									/>
								</div>
							}
						>
							{notifications.length === 0 ? (
								<div className="no-notifications">No Notifications</div>
							) : (
								notifications.map((notification, index) => (
									<Link
										to={notification.link_to}
										onClick={(e) => {
											markAsRead(notification._id, e);
										}}
										style={{ textDecoration: "none" }}
										key={index}
									>
										<div
											className={`single-notification card ${notification.read_at !== null
												? ""
												: "notification-unread"
												}`}
											style={{ border: "none" }}
										>
											<div
												className={`card-body `}
												style={{ width: "88%", margin: "auto" }}
											>
												<p
													className="card-text"
													style={{
														fontWeight: "700",
														fontFamily: "sans-serif",
														color: "#181818",
													}}
												>
													{notification.message}
												</p>
												<p className="notification-time">
													{timeAgo(notification.createdAt)}
												</p>
											</div>
											<div
												className="notification-border"
												style={{ right: "40px" }}
											/>
										</div>
									</Link>
								))
							)}
						</InfiniteScroll>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Notifications;
