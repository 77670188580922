import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { faCircle, faCog, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useAdminGetAllAdvertiserQuery } from "../../../../slices/apiSlices/adminApi";
import useUserData from "../../../../hooks/useUserData";

const Advertisers = () => {

	const { adminData } = useUserData();
	const isSuperAdmin = adminData.user.is_super_admin;

	const [filterText, setFilterText] = useState("");
	const { data, refetch } = useAdminGetAllAdvertiserQuery(null, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});
	const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);

	const userData = data?.data;

	const formattedData = Array.isArray(userData)
		? userData?.map((user, index) => {
			return {
				_id: user._id,
				id: index + 1,
				name: (
					<Link
						to={`/admin/advertisers/${user._id}`}
						className="profile-view"
					>
						{user.name}
					</Link>
				),
				status: user.status,
				UsersCount: user.users_count,
				AccountManager: user.account_manager_details?.name,
				country: user.country,
				created_at: new Date(user.joining_date).toLocaleDateString(),
			};
		})
		: [];

	// const columns = [
	// 	{
	// 		name: "#ID",
	// 		selector: (row) => row.id,
	// 		sortable: true,
	// 		width: "10%",
	// 	},
	// 	{
	// 		name: "Name",
	// 		selector: (row) => row.name,
	// 		sortable: true,
	// 		width: "15%",
	// 	},
	// 	{
	// 		name: "Users Count",
	// 		selector: (row) => row.UsersCount,
	// 		sortable: true,
	// 		width: "10%",
	// 	},

	// 	...(isSuperAdmin === 1
	// 		? [
	// 			{
	// 				name: "Account Manager",
	// 				selector: (row) => row.AccountManager,
	// 				sortable: true,
	// 				width: "20%",
	// 			},
	// 		]
	// 		: []), // Conditionally adding the column
	// 	{
	// 		name: "Status",
	// 		selector: (row) => row.status,
	// 		cell: (row) => (
	// 			<div>
	// 				{row.status === "active" ? (
	// 					<div>
	// 						<FontAwesomeIcon
	// 							data-toggle="dropdown"
	// 							className="border-0 text-success"
	// 							icon={faCircle}
	// 						/>{" "}
	// 						Active
	// 					</div>
	// 				) : (
	// 					<div>
	// 						<FontAwesomeIcon
	// 							data-toggle="dropdown"
	// 							className="border-0 text-warning"
	// 							icon={faCircle}
	// 						/>{" "}
	// 						Pending
	// 					</div>
	// 				)}
	// 			</div>
	// 		),
	// 		width: "10%",
	// 	},
	// 	{
	// 		name: "Country",
	// 		selector: (row) => row.country,
	// 		width: "10%",
	// 	},
	// 	{
	// 		name: "Created At",
	// 		selector: (row) => row.created_at,
	// 		width: "15%",
	// 	},
	// 	{
	// 		name: "",
	// 		cell: (row) => (
	// 			<div className="row-edit text-center">
	// 				<div className="dropdown show">
	// 					<FontAwesomeIcon
	// 						data-toggle="dropdown"
	// 						className="faCog"
	// 						icon={faCog}
	// 						onClick={() => toggleDropdownVisibility(row.id - 1)}
	// 					/>

	// 					<div
	// 						className={`dropdown-menu rounded-0 ${activeDropdownIndex === row.id - 1 ? "show" : ""
	// 							}`}
	// 						style={
	// 							activeDropdownIndex === row.id - 1
	// 								? {
	// 									position: "absolute",
	// 									transform: "translate3d(-79px, 18px, 0px)",
	// 									top: "0px",
	// 									left: "0px",
	// 									willChange: "transform",
	// 								}
	// 								: {}
	// 						}
	// 					>
	// 						<Link
	// 							to={`/admin/advertisers/edit/${row._id}`}
	// 							className="dropdown-item"
	// 						>
	// 							Edit
	// 						</Link>
	// 						<Link
	// 							to={`/admin/advertisers/users/${row._id}`}
	// 							className="dropdown-item"
	// 						>
	// 							Users
	// 						</Link>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		),
	// 		width: "10%",
	// 	},
	// ];

	const remainingWidth = isSuperAdmin ? 92 : 92; // Total width available for other columns when last column is fixed at 8%
	const numColumns = isSuperAdmin ? 6 : 5; // Number of columns excluding the last column
	const dynamicWidth = `${remainingWidth / numColumns}%`; // Width for each of the remaining columns

	const columns = [
		{
			name: "#ID",
			selector: (row) => row.id,
			sortable: true,
			width: dynamicWidth,
		},
		{
			name: "Name",
			selector: (row) => row.name,
			sortable: true,
			width: dynamicWidth,
		},
		{
			name: "Users Count",
			selector: (row) => row.UsersCount,
			sortable: true,
			width: dynamicWidth,
		},
		// Conditionally add the Account Manager column if the user is a super admin
		...(isSuperAdmin
			? [{
				name: "Account Manager",
				selector: (row) => row.AccountManager,
				sortable: true,
				width: dynamicWidth,
			}]
			: []),
		{
			name: "Status",
			selector: (row) => row.status,
			cell: (row) => (
				<div>
					{row.status === "active" ? (
						<div>
							<FontAwesomeIcon
								data-toggle="dropdown"
								className="border-0 text-success"
								icon={faCircle}
							/>{" "}
							Active
						</div>
					) : (
						<div>
							<FontAwesomeIcon
								data-toggle="dropdown"
								className="border-0 text-warning"
								icon={faCircle}
							/>{" "}
							Pending
						</div>
					)}
				</div>
			),
			width: dynamicWidth,
		},
		{
			name: "Country",
			selector: (row) => row.country,
			width: dynamicWidth,
		},
		{
			name: "",
			cell: (row) => (
				<div className="row-edit text-center">
					<div className="dropdown show">
						<FontAwesomeIcon
							data-toggle="dropdown"
							className="faCog"
							icon={faCog}
							onClick={() => toggleDropdownVisibility(row.id - 1)}
						/>

						<div
							className={`dropdown-menu rounded-0 ${activeDropdownIndex === row.id - 1 ? "show" : ""}`}
							style={
								activeDropdownIndex === row.id - 1
									? {
										position: "absolute",
										transform: "translate3d(-79px, 18px, 0px)",
										top: "0px",
										left: "0px",
										willChange: "transform",
									}
									: {}
							}
						>
							<Link
								to={`/admin/advertisers/edit/${row._id}`}
								className="dropdown-item"
							>
								Edit
							</Link>
							<Link
								to={`/admin/advertisers/users/${row._id}`}
								className="dropdown-item"
							>
								Users
							</Link>
						</div>
					</div>
				</div>
			),
			width: "8%", // Fixed width for the last column
		},
	];



	const toggleDropdownVisibility = (index) => {
		if (activeDropdownIndex === index) {
			setActiveDropdownIndex(null);
		} else {
			// Otherwise, open the clicked row's dropdown and close any other open dropdown
			setActiveDropdownIndex(index);
		}
	};
	// Function to handle search input changes
	const handleFilter = (event) => {
		const value = event.target.value || "";
		setFilterText(value);
	};

	// Apply search filter to the data
	const filteredData = formattedData.filter((item) => {
		const name = item.name.props.children || "";
		const accountId = String(item.id) || "";
		const manager = item.AccountManager || "";
		const country = item.country || "";
		const status = item.status; // Assuming status is a boolean property

		return (
			name.toLowerCase().includes(filterText.toLowerCase()) ||
			accountId.toLowerCase().includes(filterText.toLowerCase()) ||
			manager.toLowerCase().includes(filterText.toLowerCase()) ||
			country.toLowerCase().includes(filterText.toLowerCase()) ||
			(status ? "Active" : "Pending")
				.toLowerCase()
				.includes(filterText.toLowerCase())
		);
	});

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">
					Advertiser Accounts
					<div className="side-link">
						<Link to={`/admin/advertisers/add`} className="btn btn-side">
							<FontAwesomeIcon icon={faPlus} /> Add New Advertiser Account
						</Link>
					</div>
				</h2>
				<div className="table-supplies">
					<div className="top-filters">
						<div className="search-bar w-25 ml-auto">
							<input
								type="text"
								placeholder="Search... ID, Name, Status , etc"
								className="form-control"
								value={filterText}
								onChange={handleFilter}
							/>
						</div>
					</div>
					<div className="dataTable">
						<DataTable
							title=""
							columns={columns}
							data={filteredData}
							pagination
							highlightOnHover
							fixedHeader
							paginationPerPage={10}
							customStyles={{
								tableWrapper: {
									style: {
										height: '60vh'
									},
								},

							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Advertisers;
