import React, { useState } from "react";
import {
	Button,
	Modal,
	TextField,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router";
import { useAdminAdvertiserGetUserQuery } from "../../../../../slices/apiSlices/adminApi";
import { Link } from "react-router-dom";

const AdvertiserUserView = () => {
	const { userId, advertiserId } = useParams();
	const [open, setOpen] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const { data, isSuccess, error, isLoading } =
		useAdminAdvertiserGetUserQuery({
			userId,
		}, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});

	if (isLoading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>Error: {error?.data?.msg}</div>;
	}

	if (!isSuccess) {
		return <div>Unable to fetch data.</div>;
	}

	const allData = data?.data[0] || "n/a";
	const advAccData = data?.data[0]?.AdvertiserAccountData || "n/a";
	const formatCreationDate = (dateString) => {
		const formattedDate = new Date(dateString).toLocaleString(); // Adjust this as per your desired date format
		return formattedDate;
	};
	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// Handle password change logic here
		handleClose();
	};

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">
					Advertiser User Snapshot ( {advAccData?.name} )
				</h2>
				<div className="my-account-main">
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Account Info</span>
								</div>
								<div className="right">
									<Link
										to={`/admin/advertisers/users/edit/${advertiserId}/${userId}`}
									>
										<i className="fas fa-edit"></i> Edit
									</Link>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">ID</div>

									<div className="right">{allData._id}</div>
								</div>
								<div className="body-row">
									<div className="left">Name</div>

									<div className="right">
										{allData.title}. {allData.name}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Email</div>

									<div className="right">{allData.email}</div>
								</div>
								<div className="body-row">
									<div className="left">Status</div>

									<div className="right">
										<i className="fas fa-circle text-success border-0 small"></i>{" "}
										{allData.status}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Creation Date</div>

									<div className="right">
										{formatCreationDate(allData.createdAt)}
									</div>
								</div>
							</div>
						</div>
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Location</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Country</div>

									<div className="right">
										{advAccData?.country} <small>-{advAccData?.flag}</small>
									</div>
								</div>
								<div className="body-row">
									<div className="left">Address 1</div>

									<div className="right">{advAccData.address_1}</div>
								</div>
								<div className="body-row">
									<div className="left">Address 2</div>

									<div className="right">{advAccData.address_2}</div>
								</div>
								<div className="body-row">
									<div className="left">State</div>

									<div className="right">{advAccData.state}</div>
								</div>
								<div className="body-row">
									<div className="left">City</div>

									<div className="right">{advAccData.city}</div>
								</div>
								<div className="body-row">
									<div className="left">Postal Code</div>

									<div className="right">{advAccData.zipcode}</div>
								</div>
							</div>
						</div>
					</div>
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Quick Links</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Password</div>

									<div>
										<Button onClick={handleOpen} variant="text" color="primary">
											Change Password
										</Button>
									</div>
								</div>
							</div>
						</div>
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Contact Info</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Permanent Number</div>
									<div className="right">{allData.cell_phone}</div>
								</div>
								<div className="body-row">
									<div className="left">Work Number</div>
									<div className="right">{allData.work_phone}</div>
								</div>
								<div className="body-row">
									<div className="left">IM</div>
									<div className="right">
										<i className="text-muted small">N/A</i>
									</div>
								</div>
								<div className="body-row">
									<div className="left">IM Identifier/Username</div>
									<div className="right">
										<i className="text-muted small">N/A</i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="exampleModalCenterTitle"
					aria-describedby="passwordChangeModal"
				>
					<div role="document" className="modal-dialog modal-dialog-centered">
						<div className="modal-content rounded-0 border-0 shadow">
							<div className="modal-header">
								<h5 id="exampleModalCenterTitle">Change Password</h5>
								<Button onClick={handleClose} className="close">
									<FontAwesomeIcon icon={faTimes} />
								</Button>
							</div>
							<form onSubmit={handleSubmit}>
								<div className="modal-body px-5 py-4">
									<TextField
										type={showPassword ? "text" : "password"}
										label="New Password"
										fullWidth
										required
										helperText="Minimum 8 characters are required."
									/>
									<FormControlLabel
										control={<Checkbox id="cb-pass" />}
										label="Show Password"
										onClick={() => setShowPassword(!showPassword)}
									/>
								</div>
								<div className="modal-footer">
									<Button
										onClick={handleClose}
										style={{ backgroundColor: "#6c757d", color: "white" }}
									>
										Cancel
									</Button>{" "}
									<Button
										type="submit"
										variant="contained"
										style={{
											backgroundColor: "rgba(96, 155, 189, 0.9)",
											color: "white",
										}}
									>
										Change
									</Button>
								</div>
							</form>
						</div>
					</div>
				</Modal>
				<div
					id="passwordChangeModal"
					data-backdrop="static"
					data-keyboard="false"
					className="modal fade"
				>
					<div role="document" className="modal-dialog modal-dialog-centered">
						<div className="modal-content rounded-0 border-0 shadow">
							<div className="modal-header">
								<h5 id="exampleModalCenterTitle">Change Password</h5>
								<button
									type="button"
									data-dismiss="modal"
									aria-label="Close"
									className="close"
								>
									<span aria-hidden="true">×</span>
								</button>
							</div>
							<form>
								<div className="modal-body px-5 py-4">
									<div className="form-group form-inline m-0 align-items-baseline">
										<div>
											<label className="mr-3">New Password</label>
										</div>
										<div style={{ flex: "1 1 0%" }}>
											<input
												type="password"
												className="form-control rounded-0 w-100"
											/>{" "}
											<small className="form-text text-danger">
												1. Password field is required
											</small>{" "}
											<small className="form-text text-danger">
												2. Minimum 8 characters are required
											</small>
											<div className="custom-control custom-checkbox small mt-3">
												<input
													type="checkbox"
													id="cb-pass"
													className="custom-control-input"
												/>{" "}
												<label
													htmlFor="cb-pass"
													className="custom-control-label m-0 d-inline"
												>
													Show Password
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										data-dismiss="modal"
										className="btn btn-secondary border-0 rounded-0"
									>
										Cancel
									</button>{" "}
									<button
										type="submit"
										disabled="disabled"
										className="btn bg-theme border-0 rounded-0"
									>
										Change
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdvertiserUserView;
