import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { useAdminGetAllRoleQuery, useAdminAccountUpdateMutation, useGetAdminQuery, } from "../../../../../slices/apiSlices/adminApi";

const UpdateAccount = () => {
	const { userId } = useParams();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		email: "",
		status: "",
		role_id: "",
		title: "",
		first_name: "",
		last_name: "",
		work_phone: "",
		cell_phone: "",
		im: "",
		im_identifier: "",
	});

	const [error, setError] = useState({});
	const { data: roleData } =
		useAdminGetAllRoleQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});
	const {
		data,
		isSuccess,
		isLoading,
		error: resErr,
	} = useGetAdminQuery(
		userId
		, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});

	const [adminAccountUpdate, { isLoading: updateLoading }] =
		useAdminAccountUpdateMutation();
	const userData = data?.data[0];

	useEffect(() => {
		const fetchData = () => {


			if (userData) {
				setFormData({
					...formData,
					email: userData.email,
					status: userData.status,
					role_id: userData.role_data._id,
					title: userData.title,
					first_name: userData.first_name,
					last_name: userData.last_name,
					work_phone: userData.work_phone,
					cell_phone: userData.cell_phone,
					im: userData.im,
					im_identifier: userData.im_identifier,
				});
			}
		};
		fetchData();
	}, [data, userData]);

	if (isLoading) {
		return (
			<div id="route-view" className="col-md-12">
				Loading...
			</div>
		);
	}
	if (resErr) {
		return (
			<div id="route-view" className="col-md-12">
				{" "}
				{resErr?.data?.msg}
			</div>
		);
	}

	const roles = roleData?.data;

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (error?.data) {
			setError((prevError) => {
				const updatedError = {
					...prevError,
					data: prevError?.data?.filter((item) => item.field !== name),
				};
				return updatedError;
			});
		}
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setError("");
		const res = await adminAccountUpdate({ formData, userId });
		if (res.error && res.error.data.errors) {
			const errors = res.error.data.errors;
			setError({ data: errors });
			if (errors.length > 4) {
				toast.error("Please fill in all the required fields.");
			} else {
				errors.forEach((err) => toast.error(err.message));
			}
		}
		if (res.error) {
			alert(res?.error?.data?.msg);
		} else {
			if (res.data.success === true) {
				toast.success(res.data.message);
				navigate("/admin/accounts");
				setFormData({
					email: "",
					status: "",
					role_id: "",
					title: "",
					first_name: "",
					last_name: "",
					work_phone: "",
					cell_phone: "",
					im: "",
					im_identifier: "",
				});
			} else {
				toast.info(res.data.msg);
				console.log(res.data.msg, res.data.status);
				setError({
					msg: res.data.msg,
				});
			}
		}
	};
	const renderError = (fieldName) => {
		const errorItem = error?.data?.find((item) => item.field === fieldName);
		return errorItem ? (
			<div key={fieldName} className="text-danger">
				{errorItem.message}
			</div>
		) : null;
	};

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">Update Account</h2>
				<form className="form-panel" onSubmit={handleSubmit}>
					<div className="form-group row">
						<div className="col-sm-2">
							<label htmlFor="" className="required">
								Title
							</label>
							<select
								id=""
								className="form-control"
								name="title"
								value={formData.title}
								onChange={handleChange}
							>
								<option value="" defaultValue disabled="disabled">
									Select Title
								</option>{" "}
								<option value="Mr.">Mr.</option>{" "}
								<option value="Mrs.">Mrs.</option>{" "}
								<option value="Miss">Miss</option>
							</select>
							<small className="form-text text-danger">
								{renderError("title")}
							</small>
						</div>
						<div className="col-sm-5">
							<label className="required">First Name</label>{" "}
							<input
								type="text"
								className={`form-control border  ${formData?.first_name.trim() === "" ? "border-danger" : ""
									}`}
								name="first_name"
								value={formData?.first_name}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("first_name")}
							</small>
						</div>
						<div className="col-sm-5">
							<label className="required">Last Name</label>{" "}
							<input
								type="text"
								className={`form-control border  ${formData?.last_name.trim() === "" ? "border-danger" : ""
									}`}
								name="last_name"
								value={formData?.last_name}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("last_name")}
							</small>
						</div>
					</div>
					<div className="form-group row">
						<div className="col-sm-6">
							<label className="required">Status</label>
							<select
								className="form-control"
								name="status"
								value={formData?.status}
								onChange={handleChange}
							>
								<option value="" defaultValue disabled="disabled">
									Select Status
								</option>
								<option value="active">Active</option>
								<option value="inactive">Inactive</option>
								<option value="pending">Pending</option>
								<option value="suspended">Suspended</option>
							</select>
							<small className="form-text text-danger">
								{renderError("status")}
							</small>
						</div>
						<div className="col-sm-6">
							<label className="required">Role</label>
							<select
								className="form-control"
								name="role_id"
								value={formData?.role_id}
								onChange={handleChange}
							>
								<option value="">Select role</option>
								{roles?.map((user) => (
									<option key={user._id} value={user._id}>
										{user.role} - {user.permissions.length + "(Permissions)"}
									</option>
								))}
							</select>
							<small className="form-text text-danger">
								{renderError("role_id")}
							</small>
						</div>
					</div>
					<div className="form-group">
						<label className="required">Email</label>{" "}
						<input
							type="text"
							className={`form-control border  ${formData?.email.trim() === "" ? "border-danger" : ""
								}`}
							name="email"
							value={formData?.email}
							onChange={handleChange}
						/>
						<small className="form-text text-danger">
							{renderError("email")}
						</small>
					</div>
					<div className="form-group row">
						<div className="col-sm-6">
							<label className="required">Permanant Contact No</label>{" "}
							<input
								type="text"
								className={`form-control border ${formData?.cell_phone.toString().trim() === ""
									? "border-danger"
									: ""
									}`}
								name="cell_phone"
								value={formData?.cell_phone}
								onChange={handleChange}
								onKeyPress={(e) => {
									const isNumeric = /^[0-9]*$/.test(e.key);
									if (!isNumeric) {
										e.preventDefault();
									}
								}}
							/>
							<small className="form-text text-danger">
								{renderError("cell_phone")}
							</small>
						</div>
						<div className="col-sm-6">
							<label>Work Contact No</label>
							<input
								type="text"
								className="form-control"
								name="work_phone"
								value={formData?.work_phone}
								onChange={handleChange}
								onKeyPress={(e) => {
									const isNumeric = /^[0-9]*$/.test(e.key);
									if (!isNumeric) {
										e.preventDefault();
									}
								}}
							/>
						</div>
					</div>
					<div className="form-group row">
						<div className="col-sm-6">
							<label>Instant Messaging / Chat</label>
							<select
								value={formData?.im}
								onChange={handleChange}
								className="form-control"
							>
								<option value="">select</option>
								<option value="skype">Skype</option>
							</select>
							<small className="form-text text-danger">
								{renderError("im")}
							</small>
						</div>
						<div className="col-sm-6">
							<label>IM Username / Identifier</label>{" "}
							<input
								type="text"
								className={`form-control border  ${formData?.first_name.trim() === "" ? "border-danger" : ""
									}`}
								name="im_identifier"
								value={formData?.im_identifier}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("im_identifier")}
							</small>
						</div>
					</div>
					<div className="form-submit-bar">
						<div>
							<Link
								to={"/admin/accounts"}
								className="btn btn-sm btn-light text-muted mr-2 px-3"
							>
								Cancel
							</Link>{" "}
							<button type="submit" className="mr-2 px-3 btn btn-sm btn-submit">
								{updateLoading ? "Updating..." : "Update"}
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default UpdateAccount;
