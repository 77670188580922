import React from "react";
import { useGetConversionQuery } from "../../../../slices/apiSlices/adminApi";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const ConversionsSnapshot = () => {
	const { Id } = useParams();
	const { data, isLoading, isSuccess, error } = useGetConversionQuery(Id, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});

	if (isLoading) {
		return (
			<div id="route-view" className="col-md-12">
				Loading...
			</div>
		);
	}
	if (error) {
		return (
			<div id="route-view" className="col-md-12">
				{error?.data?.msg}
			</div>
		);
	}

	if (!isSuccess) {
		return (
			<div id="route-view" className="col-md-12">
				Unable to fetch data.
			</div>
		);
	}
	const userData = data?.data;

	const formatCreationDate = (dateString) => {
		const formattedDate = new Date(dateString).toLocaleString();
		return formattedDate;
	};

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">Conversion SnapShot</h2>
				<div className="my-account-main">
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Conversion Info</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">ID</div>
									<div className="right">{userData?._id}</div>
								</div>
								<div className="body-row">
									<div className="left">Pannel</div>
									<div className="right">{userData?.panel}</div>
								</div>
								<div className="body-row">
									<div className="left">Conversion Type</div>
									<div className="right">{userData?.conversion_type}</div>
								</div>
								<div className="body-row">
									<div className="left">Sub Id</div>
									<div
										className="right"
										style={{
											wordWrap: "break-word",
											wordBreak: "break-all",
											maxWidth: "600px",
										}}
									>
										{userData?.sub_id}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Order Id</div>
									<div className="right">{userData._id}</div>
								</div>
								<div className="body-row">
									<div className="left">Amount</div>
									<div className="right">{userData?.amount}</div>
								</div>
								<div className="body-row">
									<div className="left">Is Verified</div>
									<div className="right">
										<div>
											{userData?.is_verified === 1 ? (
												<div>
													<FontAwesomeIcon
														data-toggle="dropdown"
														className="border-0 text-success"
														icon={faCircle}
													/>
												</div>
											) : (
												<div>
													<FontAwesomeIcon
														data-toggle="dropdown"
														className="border-0 text-muted"
														icon={faCircle}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="body-row">
									<div className="left">Is Approved</div>
									<div className="right">
										<div>
											{userData?.is_approved === 1 ? (
												<div>
													<FontAwesomeIcon
														data-toggle="dropdown"
														className="border-0 text-success"
														icon={faCircle}
													/>
												</div>
											) : (
												<div>
													<FontAwesomeIcon
														data-toggle="dropdown"
														className="border-0 text-muted"
														icon={faCircle}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="body-row">
									<div className="left">Creation Date</div>
									<div className="right">
										{formatCreationDate(userData?.createdAt)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Updated Date</div>
									<div className="right">
										{formatCreationDate(userData?.updatedAt)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Publisher Info</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">ID</div>
									<div
										className="right"
										style={{
											wordWrap: "break-word",
											wordBreak: "break-all",
											maxWidth: "600px",
										}}
									>
										{userData?.click_id?.publisher_id?._id}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Name</div>
									<div className="right">
										{userData?.click_id?.publisher_id?.name}
									</div>
								</div>
							</div>
						</div>
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Offer Info</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Id</div>
									<div
										className="right"
										style={{
											wordWrap: "break-word",
											wordBreak: "break-all",
											maxWidth: "600px",
										}}
									>
										{userData?.click_id?.offer_id?._id}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Offer Name</div>
									<div className="right">
										{userData?.click_id?.offer_id?.name}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConversionsSnapshot;
