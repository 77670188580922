import React, {
	useEffect,
	useRef,
	useState,
	useCallback,
	useMemo,
} from "react";
import DataTable from "react-data-table-component";
import { Link, useSearchParams } from "react-router-dom";
import { Typography, Paper, Grid } from "@mui/material";
import $ from "jquery";
import "daterangepicker";
import { Chart } from "react-google-charts";
import moment from "moment";
//project
import "./Dashboard.css";
import { useGetDashboardDataQuery } from "../../../slices/apiSlices/advertiserApi";

const Dashboard = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		for (let index = 0; index < document.getElementsByClassName("loginStyles").length; index++) {
			let element = document.getElementsByClassName("loginStyles")[index];
			element.remove()
		}
	}, []);

	const initialStartDate =
		searchParams.get("start_date") ||
		new Date(new Date().getFullYear(), new Date().getMonth(), 2)
			.toISOString()
			.split("T")[0];
	const initialEndDate =
		searchParams.get("end_date") || new Date().toISOString().split("T")[0];
	const initialTimeUnit = searchParams.get("time_unit") || "daily";

	const [startDate, setStartDate] = useState(initialStartDate);
	const [endDate, setEndDate] = useState(initialEndDate);
	const [filterText, setFilterText] = useState("");
	const dateRangePickerRef = useRef(null);
	const [timeUnit, setTimeUnit] = useState(initialTimeUnit);

	const { data, error, isLoading } = useGetDashboardDataQuery({
		startDate,
		endDate,
		timeUnit,
	}, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});

	const graphData = data?.data?.graph_array;
	const userData = data?.data?.table;

	const options = useMemo(
		() => ({
			vAxis: { viewWindow: { min: 0 } },
			curveType: "function",
			legend: { position: "bottom" },
			pointShape: { type: "circle", rotation: 180 },
			pointSize: 7,
			bar: { groupWidth: "10%" },
			lineWidth: 4,
			series: {
				0: { type: "area" },
				1: { lineDashStyle: [4, 4], type: "line" },
				2: { type: "bars" },
			},
			colors: ["#4c8bcf", "#4943cb", "#53d571"],
		}),
		[]
	);

	const calculateDiffInDays = useCallback((start, end) => {
		const startDate = new Date(start);
		const endDate = new Date(end);
		return (endDate - startDate) / (1000 * 60 * 60 * 24);
	}, []);

	const determineTimeUnit = useCallback((diffInDays) => {
		if (diffInDays < 8) return "hourly";
		if (diffInDays >= 1 && diffInDays <= 30) return "daily";
		if (diffInDays >= 7 && diffInDays <= 365) return "weekly";
		if (diffInDays >= 30 && diffInDays <= 1094) return "monthly";
		if (diffInDays >= 1094) return "yearly";
		return "daily";
	}, []);

	useEffect(() => {
		const diffInDays = calculateDiffInDays(startDate, endDate);
		setTimeUnit(determineTimeUnit(diffInDays));
	}, [startDate, endDate, calculateDiffInDays, determineTimeUnit]);

	useEffect(() => {
		$(dateRangePickerRef.current).daterangepicker(
			{
				opens: "left",
				autoUpdateInput: true,
				startDate: startDate,
				endDate: endDate,
				locale: { format: "YYYY-MM-DD", cancelLabel: "Clear" },
				ranges: {
					Today: [moment(), moment()],
					Yesterday: [
						moment().subtract(1, "days"),
						moment().subtract(1, "days"),
					],
					"Last 7 Days": [moment().subtract(6, "days"), moment()],
					"Last 30 Days": [moment().subtract(29, "days"), moment()],
					"This Month": [moment().startOf("month"), moment()],
					"Last Month": [
						moment().subtract(1, "month").startOf("month"),
						moment().subtract(1, "month").endOf("month"),
					],
					"This Year": [moment().startOf("year"), moment()],
					"Last Year": [
						moment().subtract(1, "year").startOf("year"),
						moment().subtract(1, "year").endOf("year"),
					],
					"All Time": [moment("2000-01-01"), moment()],
				},
			},
			(start, end, label) => {
				setStartDate(start.format("YYYY-MM-DD"));
				setEndDate(end.format("YYYY-MM-DD"));
				const diffInDays = moment(end).diff(moment(start), "days");
				setTimeUnit(determineTimeUnit(diffInDays));
			}
		);

		$(dateRangePickerRef.current).on("apply.daterangepicker", (ev, picker) => {
			$(this).val(
				picker.startDate.format("YYYY-MM-DD") +
				" - " +
				picker.endDate.format("YYYY-MM-DD")
			);
		});

		$(dateRangePickerRef.current).on("cancel.daterangepicker", () => {
			$(this).val("");
			setStartDate("");
			setEndDate("");
		});
	}, [startDate, endDate, determineTimeUnit]);

	useEffect(() => {
		setSearchParams(
			new URLSearchParams({
				start_date: startDate,
				end_date: endDate,
				time_unit: timeUnit,
			})
		);
	}, [startDate, endDate, timeUnit, setSearchParams]);


	const formattedData = useMemo(
		() =>
			Array.isArray(userData)
				? userData.map((user, index) => ({
					id: index + 1,
					_id: user?._id,
					name: (
						<Link
							className="profile-view"
							onClick={() =>
								window.open(`/advertiser/offer/snapshot/${user._id}`, "_blank")
							}
						>
							{user?.name}
						</Link>
					),
					clicks: user?.clicks !== undefined ? user.clicks : 0,
					conversions: user?.conversions !== undefined ? user.conversions : 0,
					conversionRate: user?.conversions
						? ((user?.conversions / user?.clicks) * 100).toFixed(2) + "%"
						: "N/A",
					commission: user?.commission !== undefined ? "$" + user.commission : "$0",
				}))
				: [],
		[userData]
	);

	const columns = useMemo(
		() => [
			{ name: "ID", selector: (row) => row.id, sortable: true },
			{ name: "Offer", selector: (row) => row.name, sortable: true },
			{ name: "Clicks", selector: (row) => row.clicks, sortable: true },
			{ name: "Conversions", selector: (row) => row.conversions },
			{ name: "Commissions", selector: (row) => row.commission },
			{ name: "Conversion rate", selector: (row) => row.conversionRate },
		],
		[]
	);

	const filteredData = useMemo(
		() =>
			formattedData.filter((item) => {
				const name = item.name.props.children || "";
				return name.toLowerCase().includes(filterText.toLowerCase());
			}),
		[formattedData, filterText]
	);

	const handleFilter = useCallback((event) => {
		setFilterText(event.target.value || "");
	}, []);

	// const renderCard = (label, value, route) => (
	// 	<Grid item xs={12} sm={6} md={4} key={label}>
	// 		<Link to={route} style={{ textDecoration: "none", color: "inherit" }}>
	// 			<Paper
	// 				className="account shadow advertiser-card"
	// 				style={{
	// 					border: "5px outset rgb(96, 155, 189)",
	// 					padding: "15px",
	// 					borderBottom: "0.5px solid rgba(34, 34, 34, 0.1333333333)",
	// 					borderRight: "0.5px solid rgba(34, 34, 34, 0.1333333333)",
	// 					borderRadius: "10px",
	// 				}}
	// 			>
	// 				<Typography variant="h6">{label}</Typography>
	// 				<Typography variant="h4">{value}</Typography>
	// 			</Paper>
	// 		</Link>
	// 	</Grid>
	// );

	const renderCard = useCallback(
		(label, value, route) => (
			<Grid item xs={12} sm={3} md={3} key={label}>
				<Link to={route} style={{ textDecoration: "none", color: "inherit" }}>
					<Paper
						className="account shadow advertiser-card"
						style={{
							border: "5px outset rgb(96, 155, 189)",
							padding: "15px",
							borderBottom: "0.5px solid rgba(34, 34, 34, 0.1333333333)",
							borderRight: "0.5px solid rgba(34, 34, 34, 0.1333333333)",
							borderRadius: "10px",
						}}
					>
						<Typography variant="h6">{label}</Typography>
						<Typography variant="h4">{label === "Commissions" ? (value ? "$" + value : 0) : value || 0}</Typography>
					</Paper>
				</Link>
			</Grid>
		),
		[]
	);

	return (
		// <div style={{ padding: "4%" }}>
		// 	{isLoading && <p>Loading...</p>}
		// 	{error && <p>{error.error}</p>}
		// 	<div className="advertiser-date-filters">
		// 		<input
		// 			name="start_date"
		// 			type="date"
		// 			value={startDate}
		// 			onChange={(event) => handleStartDateChange(event.target.value)}
		// 		/>
		// 		<span>-</span>
		// 		<input
		// 			name="end_date"
		// 			type="date"
		// 			value={endDate}
		// 			onChange={(event) => handleEndDateChange(event.target.value)}
		// 		/>
		// 	</div>

		// 	<Grid container spacing={3}>
		// 		{data && (
		// 			<>
		// 				{renderCard(
		// 					"Clicks",
		// 					data?.data?.clicks || 0,
		// 					"/advertiser/reporting/clicks"
		// 				)}
		// 				{renderCard("Conversions", data?.data?.conversions || 0, "#")}
		// 				{renderCard("Offers", data.data?.offers || 0, "/advertiser/offer")}
		// 				{renderCard("Commissions", data.data?.commission || 0, "#")}
		// 			</>
		// 		)}
		// 	</Grid>
		// </div>
		// <div id="route-view" className="col-md-12">
		<div style={{ padding: "4%" }}>
			{isLoading && <p>Loading...</p>}
			{error && <p>{error.error}</p>}
			<div
				className="advertiser-date-filters"
				style={{ display: "flex", justifyContent: "right" }}
			>
				<input ref={dateRangePickerRef} className="date-range-picker" />
				<select
					className="form-control"
					style={{ width: "15%" }}
					onChange={(e) => setTimeUnit(e.target.value)}
					value={timeUnit}
				>
					{calculateDiffInDays(startDate, endDate) < 8 && (
						<option value="hourly">Hourly</option>
					)}
					{calculateDiffInDays(startDate, endDate) >= 1 &&
						calculateDiffInDays(startDate, endDate) <= 30 && (
							<option value="daily">Daily</option>
						)}
					{calculateDiffInDays(startDate, endDate) >= 7 &&
						calculateDiffInDays(startDate, endDate) <= 365 && (
							<option value="weekly">Weekly</option>
						)}
					{calculateDiffInDays(startDate, endDate) >= 30 &&
						calculateDiffInDays(startDate, endDate) <= 1094 && (
							<option value="monthly">Monthly</option>
						)}
					{calculateDiffInDays(startDate, endDate) >= 1094 && (
						<option value="yearly">Yearly</option>
					)}
				</select>
			</div>

			<Grid container spacing={3}>
				{data && (
					<>
						{renderCard(
							"Clicks",
							data?.data?.clicks || 0,
							"/advertiser/reporting/clicks"
						)}
						{renderCard("Conversions", data?.data?.conversions || 0, "#")}
						{renderCard("Offers", data.data?.offers || 0, "/advertiser/offer")}
						{renderCard("Commissions", data.data?.commission || 0, "#")}
					</>
				)}
			</Grid>
			<div style={{ marginTop: "20px" }}>
				<div className="chart-container">
					{graphData ? (
						<Chart
							chartType="ComboChart"
							loader={<div>Loading Chart</div>}
							width="100%"
							height="400px"
							data={graphData}
							options={options}
						/>
					) : (
						<div className="chart-container">
							No graph data available for this date interval
						</div>
					)}
				</div>
				<div className="row mt-4">
					<div className="col-md-12">
						<div className="dataTables_wrapper dt-bootstrap">
							<div className="row form-inputs">
								<div className="col-sm-4">
									<div className="alignCenter">
										<input type="text" className="date-range-picker" />
										<button className="btn btn-default btn-sm clear-filter-button btn-outline-primary input-button-sm">
											Generate Report
										</button>
									</div>
								</div>
								<div className="offset-sm-4 col-sm-4">
									<input
										type="text"
										placeholder="Search"
										className="form-control"
										value={filterText}
										onChange={handleFilter}
									/>
								</div>
							</div>
							<div className="dataTable">
								<DataTable
									title=""
									columns={columns}
									data={filteredData}
									pagination
									highlightOnHover
									fixedHeader
									paginationPerPage={10}
									customStyles={{
										tableWrapper: {
											style: {
												height: '60vh'
											},
										},
										header: {
											style: { display: "flex", justifyContent: "center" },
										},
										rows: {
											style: { display: "flex", justifyContent: "center" },
										},
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		// </div>
	);
};
export default Dashboard;
