import React from "react";
import { usePublisherGetOfferQuery } from "../../../../slices/apiSlices/publisherApi";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import "./Offers.css";
import { useSelector } from "react-redux";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <div className="tab-content">{children}</div>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

const ResourceTabs = () => {
	const imgBaseUrl = process.env.REACT_APP_API_URL;
	const { offerId } = useParams();
	const { data, isLoading, isSuccess, error } =
		usePublisherGetOfferQuery(
			offerId,
			{
				refetchOnMountOrArgChange: true,
				refetchOnReconnect: true,
			});
	const [value, setValue] = React.useState(0);

	const handleChange = (index) => {
		setValue(index);
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>Error: {error?.data?.msg}</div>;
	}

	if (!isSuccess) {
		return <div>Unable to fetch data.</div>;
	}

	const resources = data?.data?.snapshot.resources || {};
	console.log(data);
	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">Resources</h2>
				<div>
					<div className="tab-container">
						<button
							className={`tab-button ${value === 0 ? "active" : ""}`}
							onClick={() => handleChange(0)}
							{...a11yProps(0)}
						>
							Images
						</button>
						<button
							className={`tab-button ${value === 1 ? "active" : ""}`}
							onClick={() => handleChange(1)}
							{...a11yProps(1)}
						>
							Video
						</button>
						<button
							className={`tab-button ${value === 2 ? "active" : ""}`}
							onClick={() => handleChange(2)}
							{...a11yProps(2)}
						>
							Documents
						</button>
						<button
							className={`tab-button ${value === 3 ? "active" : ""}`}
							onClick={() => handleChange(3)}
							{...a11yProps(3)}
						>
							Landing Page
						</button>
					</div>

					<TabPanel value={value} index={0}>
						<div className="mt-3 container">
							<div className="row">
								{resources?.images?.map((image, index) => (
									<div key={index} className="col-md-4 mb-3">
										<div className="card">
											<div className="card-body text-center">
												<h5 className="card-title">Image {index + 1}</h5>
												<img
													src={imgBaseUrl + image}
													alt={`Image ${index + 1}`}
													className="img-fluid"
													style={{ maxWidth: "150px" }}
												/>
												<div className="mt-2">
													<a
														href={imgBaseUrl + image}
														download
														target="_blank"
														rel="noopener noreferrer"
														className="btn btn-primary"
													>
														Download
													</a>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</TabPanel>

					<TabPanel value={value} index={1}>
						<div className="mt-3 container">
							<div className="row">
								{resources?.video?.map((video, index) => (
									<div key={index} className="col-md-4 mb-3">
										<div className="card">
											<div className="card-body text-center">
												<h5 className="card-title">Video {index + 1}</h5>
												<div className="mt-2">
													<a
														href={video}
														download
														target="_blank"
														rel="noopener noreferrer"
														className="btn btn-primary"
													>
														Watch/Download
													</a>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</TabPanel>

					<TabPanel value={value} index={2}>
						<div className="mt-3 container">
							<div className="row">
								{resources?.docs?.map((doc, index) => (
									<div key={index} className="col-md-4 mb-3">
										<div className="card">
											<div className="card-body text-center">
												<h5 className="card-title">Document {index + 1}</h5>
												<div className="mt-2">
													<a
														href={doc}
														download
														target="_blank"
														rel="noopener noreferrer"
														className="btn btn-primary"
													>
														Download
													</a>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</TabPanel>

					<TabPanel value={value} index={3}>
						<div className="mt-3 container">
							<div className="row">
								{resources?.landing_page?.map((landingPage, index) => (
									<div key={index} className="col-md-4 mb-3">
										<div className="card">
											<div className="card-body text-center">
												<h5 className="card-title">Landing Page {index + 1}</h5>
												<a
													href={imgBaseUrl + landingPage}
													download
													target="_blank"
													rel="noopener noreferrer"
													className="btn btn-primary"
												>
													Download
												</a>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</TabPanel>
				</div>
			</div>
		</div>
	);
};
export default ResourceTabs;
