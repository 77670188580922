import React from 'react';
import Errorpage from '../../components/common/Errorpage';
import useUserData from '../../hooks/useUserData';

const ProtectedRoute = ({ component: Component, requiredPermissions }) => {
  const { adminData } = useUserData();
  const permissions = adminData.user.permissions;
  const isSuperAdmin = adminData.user.is_super_admin;
  const hasPermission = isSuperAdmin ? true : requiredPermissions.every((perm) => permissions.includes(perm));
  return hasPermission ? <Component /> : <Errorpage />;
};

export default ProtectedRoute;
