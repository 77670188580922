import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import {
	useApplicationUpdateMutation,
	useGetOfferApplicationsQuery,
} from "../../../../slices/apiSlices/adminApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OfferApplications = () => {
	const { offerId } = useParams();
	const [filterText, setFilterText] = useState("");
	const [status, setStatus] = useState("");
	const [selectAll, setSelectAll] = useState(false);
	const [formData, setFormData] = useState({
		update_ids: [],
		status: "",
	});

	const { data, error, isLoading, refetch, isSuccess } =
		useGetOfferApplicationsQuery({ offerId, status }, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});

	const [applicationUpdate, { isLoading: updateLoading }] =
		useApplicationUpdateMutation();

	useEffect(() => {
		const updateApplicationStatus = async () => {
			if (formData.update_ids.length > 0 && formData.status) {
				try {
					const res = await applicationUpdate({ formData });
					toast.success(res.data.msg);
					setStatus("");
					setFormData({
						update_ids: [],
						status: "",
					});
					setSelectAll(false);
					refetch();
				} catch (error) {
					console.error("Error updating application status:", error);
				}
			}
		};

		updateApplicationStatus();
	}, [formData.status, formData.update_ids]);

	const handleStatus = (e) => {
		const changedStatus = e.target.value;
		setStatus(changedStatus);
		toast.info(changedStatus === "" ? "All" : changedStatus);
		refetch();
	};

	if (isLoading) {
		return (
			<div id="route-view" className="col-md-12">
				Loading...
			</div>
		);
	}
	if (error) {
		return (
			<div id="route-view" className="col-md-12">
				{" "}
				{error?.data?.msg}
			</div>
		);
	}

	if (!isSuccess) {
		return (
			<div id="route-view" className="col-md-12">
				Unable to fetch data.
			</div>
		);
	}
	const userData = data?.data;
	const formattedData = Array.isArray(userData)
		? userData?.map((user, index) => {
			return {
				id: index + 1,
				_id: user?._id,
				name: (
					<Link to={``} className="profile-view">
						{user?.publisherData?.name}
					</Link>
				),
				OfferStatus: user?.offerData?.status,
				offer: user?.offerData?.name,
				advertiser: user?.advertiserData?.name,
				publisherManager: user?.publisherManagerData?.name,
				applicationStatus: user?.status,
				applicationDate: user?.createdAt,
			};
		})
		: [];

	const handleSelectAll = (e) => {
		const checked = e.target.checked;
		setSelectAll(checked);
		const updateIds = checked ? formattedData.map((row) => row._id) : [];
		setFormData({ ...formData, update_ids: updateIds });
	};
	const handleCheckboxChange = (e, id) => {
		const checked = e.target.checked;
		let updatedIds;

		if (id === "selectAll") {
			setSelectAll(checked);
			updatedIds = checked ? formattedData.map((row) => row._id) : [];
		} else {
			const existingIds = formData.update_ids;
			updatedIds = checked
				? [...existingIds, id]
				: existingIds.filter((existingId) => existingId !== id);
		}

		setFormData({ ...formData, update_ids: updatedIds });
	};

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;

		if (type === "checkbox") {
			const id = e.target.value;
			let updatedIds;

			if (name === "selectAll") {
				setSelectAll(checked);
				updatedIds = checked ? formattedData.map((row) => row._id) : [];
			} else {
				const existingIds = formData.update_ids;
				updatedIds = checked
					? [...existingIds, id]
					: existingIds.filter((existingId) => existingId !== id);
			}

			setFormData({ ...formData, update_ids: updatedIds });
		} else if (type === "select-one") {
			setFormData({ ...formData, status: value });
		}
	};

	const columns = [
		{
			name: (
				<div>
					<input
						type="checkbox"
						name="selectAll"
						checked={selectAll}
						onChange={handleSelectAll}
					/>
				</div>
			),
			cell: (row) => (
				<div>
					<input
						type="checkbox"
						name="selectsingle"
						checked={formData.update_ids.includes(row._id)}
						onChange={(e) => handleCheckboxChange(e, row._id)}
					/>
				</div>
			),
		},

		{
			name: "ID",
			selector: (row) => row.id,
			sortable: true,
		},
		{
			name: "Publisher",
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: "Offer",
			selector: (row) => row.offer,
			sortable: true,
		},
		{
			name: "Offer Status",
			selector: (row) => row.OfferStatus,
			cell: (row) => (
				<div>
					{row.OfferStatus === "active" ? (
						<div>
							<FontAwesomeIcon
								data-toggle="dropdown"
								className="border-0 text-success"
								icon={faCircle}
							/>{" "}
							Active
						</div>
					) : (
						<div style={{ textTransform: "capitalize" }}>
							<FontAwesomeIcon
								data-toggle="dropdown"
								className="border-0 text-warning"
								icon={faCircle}
							/>{" "}
							{row.OfferStatus}
						</div>
					)}
				</div>
			),
		},
		{
			name: "Advertiser",
			selector: (row) => row.advertiser,
		},
		{
			name: "Publisher Manager",
			selector: (row) => row.publisherManager,
		},
		{
			name: "Application Status",
			selector: (row) => row.applicationStatus,
			cell: (row) => (
				<div style={{ textTransform: "capitalize" }}>
					{row.applicationStatus}
				</div>
			),
		},
		{
			name: "Application Date",
			selector: (row) => row.applicationDate,
		},
	];

	const handleFilter = (event) => {
		const value = event.target.value || "";
		setFilterText(value);
	};

	const filteredData = formattedData.filter((item) => {
		const name = item.name.props.children || "";
		return name.toLowerCase().includes(filterText.toLowerCase());
	});

	return (
		<div id="route-view" className="col-md-12">
			<div offer_id="17">
				<h2 className="main-heading">Offer Applications</h2>
				<div>
					<div className="table-supplies">
						<div className="top-filters">
							<div className="search-bar w-25 ml-auto">
								<input
									type="text"
									placeholder="Search"
									className="form-control"
									value={filterText}
									onChange={handleFilter}
								/>
							</div>
						</div>
					</div>
					<div className="table-filter-panel row">
						<div className="col inner">
							<div className="row">
								<div className="form-group col-sm-6">
									<select
										className="form-control"
										value={formData.status}
										onChange={handleChange}
									>
										<option value="">Select to Approve</option>
										<option value="approved">Approve</option>
										<option value="rejected">Reject</option>
									</select>
								</div>
								<div className="form-group col-sm-6">
									<select
										className="form-control"
										value={status}
										onChange={handleStatus}
									>
										<option value="">Show All</option>
										<option value="approved">Approved</option>
										<option value="pending">Pending</option>
										<option value="rejected">Rejected</option>
									</select>
								</div>
							</div>
						</div>
						<div className="col inner">
							<div className="row">
								<div className="col-sm-6 offset-sm-6"></div>
							</div>
						</div>
					</div>
					<div className="dataTable">
						<DataTable
							title=""
							columns={columns}
							data={filteredData}
							pagination
							highlightOnHover
							fixedHeader
							paginationPerPage={10}
							customStyles={{
								tableWrapper: {
									style: {
										height: '60vh'
									},
								},
								header: {
									style: {
										display: "flex",
										justifyContent: "center",
									},
								},
								rows: {
									style: {
										display: "flex",
										justifyContent: "center",
									},
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OfferApplications;
