import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useGetySalesByIdDataQuery } from "../../../../slices/apiSlices/adminApi";
import { useParams, useSearchParams } from "react-router-dom";

const SalesById = () => {
	const { offerId, publisherId } = useParams();
	const end_Date = new Date().toISOString().split("T")[0];
	const firstDayOfMonth = new Date(
		new Date().getFullYear(),
		new Date().getMonth(),
		2
	);
	const start_Date = firstDayOfMonth.toISOString().split("T")[0];
	const [searchParams, setSearchParams] = useSearchParams();
	const startDateParam = searchParams.get("start_date");
	const endDateParam = searchParams.get("end_date");
	const [startDate, setStartDate] = useState(start_Date);
	const [endDate, setEndDate] = useState(end_Date);
	const { data } = useGetySalesByIdDataQuery({
		startDate,
		endDate,
		offerId,
		publisherId,
	}, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});

	const [filterText, setFilterText] = useState("");
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		setRowsPerPage(10);
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			setStartDate(startDateParam || startDate);
			setEndDate(endDateParam || endDate);
		};
		fetchData();
	}, [startDate, endDate, startDateParam, endDateParam]);

	const userData = data?.data;

	const [rowsPerPage, setRowsPerPage] = useState(
		userData ? userData.length : 10
	);

	const formattedData = Array.isArray(userData)
		? userData?.map((user, index) => {
			return {
				id: index + 1,
				_id: user?._id,
				subId: user?.subId || "No SubId",
				clicks: user?.clicks || 0,
				conversions: user?.conversions || 0,
				conversionRate:
					((user?.conversions || 0 / user?.clicks || 0) * 100).toFixed(2) +
					"%",
				commissions: user?.commission || 0,
			};
		})
		: [];

	const columns = [
		{
			name: "SubID",
			selector: (row) => row.subId,
		},
		{
			name: "Clicks",
			selector: (row) => row.clicks,
			sortable: true,
		},
		{
			name: "Conversions",
			selector: (row) => row.conversions,
		},
		{
			name: "Conversion Rate",
			selector: (row) => row.conversionRate,
		},
		{
			name: "Commissions",
			selector: (row) => (row.commissions ? `$${row.commissions}` : ""),
		},
	];

	const handleFilter = (event) => {
		const value = event.target.value || "";
		setFilterText(value);
	};

	const filteredData = formattedData.filter((item) => {
		const name = item.subId || "";
		return name.toLowerCase().includes(filterText.toLowerCase());
	});

	const paginatedData = filteredData.slice(
		(currentPage - 1) * rowsPerPage,
		currentPage * rowsPerPage
	);
	const onChangePage = (page) => {
		setCurrentPage(page);
	};
	const handleRowsPerPageChange = (rows) => {
		setRowsPerPage(rows);
		setCurrentPage(1);
	};

	const totalRows = filteredData.length;
	const totalPages = Math.ceil(totalRows / rowsPerPage);

	const CustomFooter = () => {
		const totalClicks = formattedData.reduce((acc, cur) => acc + cur.clicks, 0);

		const totalConversions = formattedData.reduce(
			(acc, cur) => acc + cur.conversions,
			0
		);
		const totalConversionRate =
			totalClicks > 0
				? ((totalConversions / totalClicks) * 100).toFixed(2) + "%"
				: "0.00%";

		const totalCommissions =
			"$" + formattedData.reduce((acc, cur) => acc + cur.commissions, 0);
		return (
			<div>
				<div
					id="row-4"
					role="row"
					className="sc-jrQzAO iAwKFK rdt_TableRow footer"
				>
					<div
						id="cell-1-4"
						data-column-id="1"
						role="cell"
						className="sc-hKwDye sc-eCImPb sc-jRQBWg kUKiTL fvAeSn bsgpsK rdt_TableCell"
						data-tag="allowRowEvents"
					>
						<div data-tag="allowRowEvents">Total</div>
					</div>
					<div
						id="cell-2-4"
						data-column-id="2"
						role="cell"
						className="sc-hKwDye sc-eCImPb sc-jRQBWg kUKiTL fvAeSn bsgpsK rdt_TableCell"
						data-tag="allowRowEvents"
					>
						<div data-tag="allowRowEvents">{totalClicks}</div>
					</div>
					<div
						id="cell-3-4"
						data-column-id="3"
						role="cell"
						className="sc-hKwDye sc-eCImPb sc-jRQBWg kUKiTL fvAeSn bsgpsK rdt_TableCell"
						data-tag="allowRowEvents"
					>
						<div data-tag="allowRowEvents">{totalConversions}</div>
					</div>
					<div
						id="cell-4-4"
						data-column-id="4"
						role="cell"
						className="sc-hKwDye sc-eCImPb sc-jRQBWg kUKiTL fvAeSn bsgpsK rdt_TableCell"
						data-tag="allowRowEvents"
					>
						<div data-tag="allowRowEvents">{totalConversionRate}</div>
					</div>
					<div
						id="cell-5-4"
						data-column-id="5"
						role="cell"
						className="sc-hKwDye sc-eCImPb sc-jRQBWg kUKiTL fvAeSn bsgpsK rdt_TableCell"
						data-tag="allowRowEvents"
					>
						<div data-tag="allowRowEvents">{totalCommissions}</div>
					</div>
				</div>
				<div>
					<nav className="sc-iJKOTD bhoXfZ rdt_Pagination">
						<span className="sc-bYoBSM sc-ikJyIC fXzwgx bWTWQu">
							Rows per page:
						</span>
						<div className="sc-llYSUQ fQmYxz">
							<select
								aria-label="Rows per page:"
								className="sc-cxpSdN fWWeYy"
								value={rowsPerPage}
								onChange={(e) =>
									handleRowsPerPageChange(parseInt(e.target.value))
								}
							>
								{[10, 15, 20, 25, 30].map((option) => (
									<option key={option} value={option}>
										{option}
									</option>
								))}
							</select>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
							>
								<path d="M7 10l5 5 5-5z"></path>
								<path d="M0 0h24v24H0z" fill="none"></path>
							</svg>
						</div>
						<span className="sc-bYoBSM sc-kLwhqv fXzwgx emVwZU">{`${(currentPage - 1) * rowsPerPage + 1
							}-${Math.min(
								currentPage * rowsPerPage,
								totalRows
							)} of ${totalRows}`}</span>
						<div className="sc-ezbkAF kNbUsB">
							<button
								id="pagination-first-page"
								type="button"
								aria-label="First Page"
								aria-disabled="true"
								className="sc-giYglK gPLhoV"
								onClick={() => onChangePage(1)}
								disabled={currentPage === 1}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									aria-hidden="true"
									role="presentation"
								>
									<path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
									<path fill="none" d="M24 24H0V0h24v24z"></path>
								</svg>
							</button>
							<button
								id="pagination-previous-page"
								type="button"
								aria-label="Previous Page"
								aria-disabled="true"
								onClick={() => onChangePage(currentPage - 1)}
								disabled={currentPage === 1}
								className="sc-giYglK gPLhoV"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									aria-hidden="true"
									role="presentation"
								>
									<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
									<path d="M0 0h24v24H0z" fill="none"></path>
								</svg>
							</button>
							<button
								id="pagination-next-page"
								type="button"
								aria-label="Next Page"
								aria-disabled="true"
								onClick={() => onChangePage(currentPage + 1)}
								disabled={currentPage === totalPages}
								className="sc-giYglK gPLhoV"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									aria-hidden="true"
									role="presentation"
								>
									<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
									<path d="M0 0h24v24H0z" fill="none"></path>
								</svg>
							</button>
							<button
								id="pagination-last-page"
								type="button"
								aria-label="Last Page"
								aria-disabled="true"
								onClick={() => onChangePage(totalPages)}
								disabled={currentPage === totalPages}
								className="sc-giYglK gPLhoV"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									aria-hidden="true"
									role="presentation"
								>
									<path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
									<path fill="none" d="M0 0h24v24H0V0z"></path>
								</svg>
							</button>
						</div>
					</nav>
				</div>
			</div>
		);
	};

	const handleStartDateChange = (date) => {
		setStartDate(date);
		updateURL(date, endDate);
	};

	const handleEndDateChange = (date) => {
		setEndDate(date);
		updateURL(startDate, date);
	};

	const updateURL = (startDate, endDate) => {
		setSearchParams(
			`?${new URLSearchParams({ start_date: startDate, end_date: endDate })}`
		);
	};
	return (
		<div id="route-view" className="col-md-12">
			{/* <style scoped>{`.sc-dlVxhl.RvYtY { overflow: unset;}`}</style> */}
			<div>
				<h2 className="main-heading">Sales By SubId</h2>
				<div className="admin-date-filters">
					<input
						name="start_date"
						type="date"
						value={startDate}
						onChange={(event) => handleStartDateChange(event.target.value)}
					/>
					<span>-</span>
					<input
						name="end_date"
						type="date"
						value={endDate}
						onChange={(event) => handleEndDateChange(event.target.value)}
					/>
				</div>
				<div className="table-supplies">
					<div className="top-filters">
						<div className="search-bar w-25 ml-auto">
							<input
								type="text"
								placeholder="Search... SubId"
								className="form-control"
								value={filterText}
								onChange={handleFilter}
							/>
						</div>
					</div>
					<div className="dataTable">
						<DataTable
							title=""
							columns={columns}
							data={paginatedData}
							pagination
							highlightOnHover
							fixedHeader
							paginationComponent={CustomFooter}
							// paginationTotalRows={totalRows}
							paginationPerPage={rowsPerPage}
							paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
							customStyles={{
								tableWrapper: {
									style: {
										height: '400px',
									},
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SalesById;
