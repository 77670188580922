// PermissionBadge.js
import React from "react";

const PermissionBadge = ({ permissions, keyword }) => {
	return (
		<div>
			<div className="d-flex my-2">
				{permissions && permissions.some((perm) => perm.includes(keyword)) ? (
					<div className="font-weight-bold">{`${keyword
						.replace(/_/g, " ")
						.toUpperCase()}: `}</div>
				) : (
					""
				)}
				{permissions.map((perm, key) => {
					return perm.includes(keyword) ? (
						<div
							key={key}
							className="font-italic badge d-flex align-items-center border rounded-0 ml-1"
						>
							{perm.replace(new RegExp(`${keyword}_`, "g"), "").toUpperCase()}
						</div>
					) : (
						""
					);
				})}
			</div>
		</div>
	);
};

export default PermissionBadge;
