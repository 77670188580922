import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import {
	Button,
	Modal,
	TextField,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";

import { useGetAdminQuery } from "../../../../../slices/apiSlices/adminApi";

function MyAccount() {
	const { userId } = useParams();
	const [open, setOpen] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [changedPassword, setChangedPassword] = useState({
		password: "",
	});
	const { data, isSuccess, isLoading, error } = useGetAdminQuery(
		userId,
		{
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});

	//   const [changePublisherPassword, { isLoading: UpdateLoading }] =
	//     useChangePublisherPasswordMutation();

	if (isLoading) {
		return (
			<div id="route-view" className="col-md-12">
				Loading...
			</div>
		);
	}
	if (error) {
		return (
			<div id="route-view" className="col-md-12">
				{" "}
				{error?.data?.msg}
			</div>
		);
	}

	if (!isSuccess) {
		return (
			<div id="route-view" className="col-md-12">
				Unable to fetch data.
			</div>
		);
	}

	const userData = data?.data[0];

	const formatCreationDate = (dateString) => {
		const options = {
			weekday: "long",
			day: "numeric",
			month: "long",
			year: "numeric",
			// timeZone: 'Asia/Kolkata', // Set the timezone to India
		};

		const formattedDate = new Date(dateString).toLocaleDateString(
			"en-IN",
			options
		);
		return formattedDate;
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleChangePassword = (e) => {
		const value = e.target.value;
		setChangedPassword({ ...changedPassword, password: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		//  const res = await change({ changedPassword , userId });
		//  toast.success(res.data.msg);
		//  setChangedPassword({ ...changedPassword, password: "" });
		//  handleClose();
	};

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">Account Snapshot</h2>
				<div className="my-account-main">
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Account Info</span>
								</div>
								<div className="right">
									<Link to={`/admin/account/edit/${userId}`}>
										<i className="fas fa-edit"></i> Edit
									</Link>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Account ID</div>

									<div className="right">{userData?._id}</div>
								</div>
								<div className="body-row">
									<div className="left">Assigned Role</div>

									<div className="right">{userData?.role_data?.role}</div>
								</div>
								<div className="body-row">
									<div className="left">Email</div>

									<div className="right">{userData?.email}</div>
								</div>
								<div className="body-row">
									<div className="left">Account Status</div>

									<div className="right">
										<i className="fas fa-circle text-success border-0 small"></i>{" "}
										{userData?.status}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Title</div>

									<div className="right">{userData?.title}</div>
								</div>
								<div className="body-row">
									<div className="left">Name</div>

									<div className="right">{userData?.name}</div>
								</div>
								<div className="body-row">
									<div className="left">Permanent Contact</div>

									<div className="right">{userData?.cell_phone}</div>
								</div>
								<div className="body-row">
									<div className="left">Work Contact</div>

									<div className="right">{userData?.work_phone}</div>
								</div>
								<div className="body-row">
									<div className="left">Creation Date</div>
									<div className="right">
										{formatCreationDate(userData?.createdAt)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Quick Links</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Password</div>

									<div>
										<Button onClick={handleOpen} variant="text" color="primary">
											Change Password
										</Button>
									</div>
								</div>
							</div>
						</div>
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Role</span>
								</div>
								{/* <div className="right"><a ><i className="fas fa-edit"></i> Edit</a></div> */}
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Assigned Role</div>

									<div className="right">{userData?.role_data?.role}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					id="passwordChangeModal"
					data-backdrop="static"
					data-keyboard="false"
					className="modal fade"
				>
					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby="exampleModalCenterTitle"
						aria-describedby="passwordChangeModal"
					>
						<div role="document" className="modal-dialog modal-dialog-centered">
							<div className="modal-content rounded-0 border-0 shadow">
								<div className="modal-header">
									<h5 id="exampleModalCenterTitle">Change Password</h5>
									<Button onClick={handleClose} className="close">
										<FontAwesomeIcon icon={faTimes} />
									</Button>
								</div>
								<form onSubmit={handleSubmit}>
									<div className="modal-body px-5 py-4">
										<TextField
											value={changedPassword.password}
											onChange={handleChangePassword}
											type={showPassword ? "text" : "password"}
											label="New Password"
											fullWidth
											required
											helperText="Minimum 8 characters are required."
										/>
										<FormControlLabel
											control={
												<Checkbox
													checked={showPassword}
													onChange={() => setShowPassword(!showPassword)}
												/>
											}
											label="Show Password"
										/>
									</div>
									<div className="modal-footer">
										<Button
											onClick={handleClose}
											style={{ backgroundColor: "#6c757d", color: "white" }}
										>
											Cancel
										</Button>{" "}
										<Button
											type="submit"
											variant="contained"
											style={{
												backgroundColor: "rgba(96, 155, 189, 0.9)",
												color: "white",
											}}
										>
											Change
										</Button>
									</div>
								</form>
							</div>
						</div>
					</Modal>
				</div>
			</div>
		</div>
	);
}

export default MyAccount;
