import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useGetAllAdminQuery, useAdminAddPublisherMutation } from "../../../../slices/apiSlices/adminApi";

import {
	useGetAllCityMutation,
	useGetAllCountryQuery,
	useGetAllStateMutation,
} from "../../../../slices/apiSlices/otherApi";
import useUserData from "../../../../hooks/useUserData";

function AddPublisherAccounts() {

	const { adminData } = useUserData();
	const isSuperAdmin = adminData.user.is_super_admin;
	const adminId = adminData.user._id;

	const [selectedCountry, setSelectedCountry] = useState("");
	const [selectedState, setSelectedState] = useState("");
	const [selectedCity, setSelectedCity] = useState("");
	const [states, setStates] = useState({});
	const [cities, setCities] = useState({});

	const [formData, setFormData] = useState({
		name: "",
		address_1: "",
		address_2: "",
		country: "",
		state: "",
		city: "",
		zipcode: "",
		internal_notes: "",
		first_name: "",
		last_name: "",
		title: "",
		email: "",
		cell_phone: "",
		work_phone: "",
		password: "",
		password_confirmation: "",
		account_manager_id: isSuperAdmin === 1 ? "" : adminId,
		status: undefined,
	});
	const [error, setError] = useState({});

	const navigate = useNavigate();

	const { data } = useGetAllAdminQuery(null, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});
	const { data: countrydata } =
		useGetAllCountryQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});
	const [getAllCity] = useGetAllCityMutation();
	const [getAllState] = useGetAllStateMutation();
	const [adminAddPublisher, { isLoading: AddLoading }] =
		useAdminAddPublisherMutation();

	const admin_Data = data?.data;

	///handle functions
	const handleCountryChange = async (e) => {
		const isoCode = e.target.value;
		setSelectedCountry(isoCode);
		setSelectedState("");
		setSelectedCity("");
		if (error?.data) {
			setError((prevError) => ({
				...prevError,
				data: prevError?.data?.filter((item) => item.field !== "country"),
			}));
		}
		const res = await getAllState({ country_code: isoCode });
		setStates(res);
		setFormData({
			...formData,
			country: isoCode,
			state: "",
			city: "",
		});
	};
	const handleStateChange = async (e) => {
		const isoCode =
			e.target.options[e.target.selectedIndex].getAttribute("data-code");
		const contcode =
			e.target.options[e.target.selectedIndex].getAttribute("data-contcode");
		const name = e.target.value;
		setSelectedState(name);
		setSelectedCity("");
		if (error?.data) {
			setError((prevError) => ({
				...prevError,
				data: prevError?.data?.filter((item) => item.field !== "state"),
			}));
		}
		const res = await getAllCity({
			country_code: contcode,
			state_code: isoCode,
		});
		setCities(res);
		setFormData({
			...formData,
			state: name,
			city: "",
		});
	};
	const handleCityChange = (e) => {
		const selectedCityId = e.target.value;
		setSelectedCity(selectedCityId);
		if (error?.data) {
			setError((prevError) => ({
				...prevError,
				data: prevError?.data?.filter((item) => item.field !== "city"),
			}));
		}
		setFormData({
			...formData,
			city: selectedCityId,
		});
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		if (error?.data) {
			setError((prevError) => {
				const updatedError = {
					...prevError,
					data: prevError?.data?.filter((item) => item.field !== name),
				};
				return updatedError;
			});
		}
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const res = await adminAddPublisher({ formData });
		if (res.error && res.error.data.errors) {
			const errors = res.error.data.errors;
			setError({ data: errors });
			if (errors.length > 4) {
				toast.error("Please fill in all the required fields.");
			} else {
				errors.forEach((err) => toast.error(err.message));
			}
		} else {
			if (res.data.success === true) {
				toast.success(res.data.msg);
				navigate("/admin/publisher-accounts");
			} else {
				toast.info(res.data.msg);
				console.log(res.data.msg, res.data.status);
				setError({
					msg: res.data.msg,
				});
			}
		}
	};

	const renderError = (fieldName) => {
		const errorItem = error?.data?.find((item) => item.field === fieldName);
		return errorItem ? (
			<div key={fieldName} className="text-danger">
				{errorItem.message}
			</div>
		) : null;
	};
	console.log(formData);

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">Add Publisher</h2>
				<form onSubmit={handleSubmit}>
					<div className="form-panel">
						<h3 className="heading">Account Details</h3>
						<div className="form-group">
							<label className="required">Account Name</label>
							<input
								className={`form-control border  ${formData.name.trim() === "" ? "border-danger" : ""
									}`}
								name="name"
								value={formData.name}
								onChange={handleChange}
							/>
							<small className="form-text info-text">
								{renderError("name")}
								(Your company, firm or any desirable name for account)
							</small>
						</div>
						<div className="form-group">
							<label className="required">Address 1</label>
							<textarea
								className={`form-control border  ${formData.address_1.trim() === "" ? "border-danger" : ""
									}`}
								name="address_1"
								value={formData.address_1}
								onChange={handleChange}
							></textarea>
							<small className="form-text info-text">
								{renderError("address_1")}
							</small>
						</div>
						<div className="form-group">
							<label>Address 2</label>
							<textarea
								className="form-control"
								name="address_2"
								value={formData.address_2}
								onChange={handleChange}
							></textarea>
						</div>
						<div>
							<div className="form-group">
								<label htmlFor="" className="required">
									Country
								</label>
								<select
									id=""
									className="form-control"
									name="country"
									value={selectedCountry}
									onChange={handleCountryChange}
								>
									<option value=""></option>
									{countrydata?.data?.map((country) => (
										<option key={country.isoCode} value={country.isoCode}>
											{country.name}
										</option>
									))}
								</select>
								<small className="form-text info-text">
									{renderError("country")}
								</small>
							</div>
							<div className="form-group">
								<label htmlFor="" className="required">
									State
								</label>
								<select
									id=""
									className="form-control"
									name="state"
									value={selectedState}
									onChange={handleStateChange}
									disabled={selectedCountry === ""}
								>
									<option value=""></option>
									{states?.data?.data?.map((state) => (
										<option
											key={state.isoCode}
											value={state.name}
											data-contcode={state.countryCode}
											data-code={state.isoCode}
										>
											{state.name}
										</option>
									))}
								</select>
								<small className="form-text info-text">
									{renderError("state")}
								</small>
								{selectedCountry === "" && (
									<small className="form-text text-danger">
										Select a country first
									</small>
								)}
							</div>
						</div>
						<div className="form-group row">
							<div className="col-md-6">
								<label className="required">City</label>
								<select
									id=""
									className="form-control"
									name="city"
									value={selectedCity}
									onChange={handleCityChange}
									disabled={selectedState === ""}
								>
									<option value=""></option>
									{cities?.data?.data?.map((city) => (
										<option key={city.name} value={city.name}>
											{city.name}
										</option>
									))}
								</select>
								<small className="form-text text-danger">
									{renderError("city")}
								</small>
								{selectedState === "" && (
									<small className="form-text text-danger">
										Select a Sate first
									</small>
								)}
							</div>
							<div className="col-md-6">
								<label className="required">Postal Code</label>
								<input
									className={`form-control border  ${formData.zipcode.trim() === "" ? "border-danger" : ""
										}`}
									name="zipcode"
									value={formData.zipcode}
									onChange={handleChange}
								/>
								<small className="form-text text-danger">
									{renderError("zipcode")}
								</small>
							</div>
						</div>
						<div className="form-group">
							<label>Internal Notes</label>
							<textarea
								className="form-control"
								name="internal_notes"
								value={formData.internal_notes}
								onChange={handleChange}
							></textarea>
							<small className="form-text info-text">
								Only for Internal Records, Not Visible to Publishers
							</small>
						</div>
					</div>
					<div className="form-panel">
						<h3 className="heading">Add User</h3>
						<div className="form-group">
							<label htmlFor="" className="required">
								First Name
							</label>
							<input
								type="text"
								className={`form-control border  ${formData.first_name.trim() === "" ? "border-danger" : ""
									}`}
								name="first_name"
								value={formData.first_name}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("first_name")}
							</small>
						</div>
						<div className="form-group">
							<label htmlFor="" className="required">
								Last Name
							</label>
							<input
								type="text"
								className={`form-control border  ${formData.last_name.trim() === "" ? "border-danger" : ""
									}`}
								name="last_name"
								value={formData.last_name}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("last_name")}
							</small>
						</div>
						<div className="form-group">
							<label className="required" htmlFor="">
								Title
							</label>
							<select
								id=""
								className="form-control"
								name="title"
								value={formData.title}
								onChange={handleChange}
							>
								<option value="" defaultValue disabled="disabled">
									Select Title
								</option>
								<option value="Mr.">Mr.</option>
								<option value="Mrs.">Mrs.</option>
								<option value="Miss">Miss</option>
							</select>
							<small className="form-text text-danger">
								{renderError("title")}
							</small>
						</div>
						<div className="form-group">
							<label htmlFor="" className="required">
								Email
							</label>
							<input
								type="email"
								className={`form-control border  ${formData.email.trim() === "" ? "border-danger" : ""
									}`}
								name="email"
								value={formData.email}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("email")}
							</small>
						</div>
						<div className="form-group row">
							<div className="col-md-6">
								<label htmlFor="" className="required">
									Cell Phone
								</label>
								<input
									maxLength="10"
									type="tel"
									className={`form-control border ${formData.cell_phone.toString().trim() === ""
										? "border-danger"
										: ""
										}`}
									name="cell_phone"
									value={formData.cell_phone}
									onChange={handleChange}
									onKeyPress={(e) => {
										const isNumeric = /^[0-9]*$/.test(e.key);
										if (!isNumeric) {
											e.preventDefault();
										}
									}}
								/>
								<small className="form-text text-danger">
									{renderError("cell_phone")}
								</small>
							</div>
							<div className="col-md-6">
								<label htmlFor="">Work Phone</label>
								<input
									type="tel"
									maxLength="10"
									className="form-control"
									name="work_phone"
									value={formData.work_phone}
									onChange={handleChange}
									onKeyPress={(e) => {
										const isNumeric = /^[0-9]*$/.test(e.key);
										if (!isNumeric) {
											e.preventDefault();
										}
									}}
								/>
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="" className="required">
								Password
							</label>
							<input
								type="text"
								className={`form-control border  ${formData.password.trim() === "" ? "border-danger" : ""
									}`}
								name="password"
								value={formData.password}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("password")}
							</small>
						</div>
						<div className="form-group">
							<label htmlFor="" className="required">
								Confirm Password
							</label>
							<input
								type="text"
								className={`form-control border  ${formData.password_confirmation.trim() === ""
									? "border-danger"
									: ""
									}`}
								name="password_confirmation"
								value={formData.password_confirmation}
								onChange={handleChange}
							/>
							<small className="form-text text-danger">
								{renderError("password_confirmation")}
							</small>
							<small className="form-text info-text">
								Password must contain at least 8 characters
							</small>
						</div>
					</div>
					<div className="form-panel">
						<h3 className="heading">Settings</h3>
						{isSuperAdmin === 1 ? <div className="form-group">
							<label htmlFor="" className="required">
								Account Manager
							</label>
							<select
								className="form-control"
								name="account_manager_id"
								value={formData.account_manager_id}
								onChange={handleChange}
							>
								<option value="">Select Account Manager</option>
								{admin_Data?.map((user) => (
									<option key={user?._id} value={user?._id}>
										{user?.name} - {user?.role_data?.role}
									</option>
								))}
							</select>
							<small className="form-text text-danger">
								{renderError("account_manager_id")}
							</small>
						</div> : null}
						<div className="form-group">
							<label htmlFor="" className="required">
								Status
							</label>
							<select
								className="form-control"
								name="status"
								value={formData.status}
								onChange={handleChange}
							>
								<option value="">Select Status</option>
								<option value="active">Active</option>
								<option value="pending">Pending</option>
							</select>
							<small className="form-text text-danger">
								{renderError("status")}
							</small>
						</div>
						<div className="form-submit-bar">
							<div>
								<Link
									to={"/admin/publisher-accounts"}
									className="btn btn-sm btn-light text-muted mr-2 px-3"
								>
									Cancel
								</Link>
								<button
									type="submit"
									className="mr-2 px-3 btn btn-sm btn-submit"
								>
									{AddLoading ? "Adding..." : "Add"}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default AddPublisherAccounts;
