import React from 'react';
import { Typography, Container, Paper } from '@mui/material';


const index = () => {
  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '15%' }}>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h4" >
          Error 404 Page not found !!
        </Typography>
        <Typography variant="body1">
          Please try again later or contact support.
        </Typography>
      </Paper>
    </Container>
  )
}

export default index