import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetAllCountryQuery } from "../../../../slices/apiSlices/otherApi";
import {
	useAddPaymentMethodMutation,
	useGetPaymentMethodsQuery,
	useGetPaymentMethodQuery,
} from "../../../../slices/apiSlices/publisherApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentForm = () => {
	const { data: countrydata } =
		useGetAllCountryQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});

	const { data: paymentMethodsfields } =
		useGetPaymentMethodsQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});
	const { data, refetch } = useGetPaymentMethodQuery(null, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});
	const prevData = data?.data || "";

	const [addPaymentMethod, { isLoading: AddLoading }] =
		useAddPaymentMethodMutation();
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [currentPaymentMethod, setCurrentPaymentMethod] = useState(
		prevData.mode_of_payment || "bank_transfer"
	);

	const [formData, setFormData] = useState({
		mode_of_payment: currentPaymentMethod,
		details: {},
		payment_reason: "",
	});

	// useEffect(() => {
	// 	setFormData({
	// 		mode_of_payment: currentPaymentMethod,
	// 		details: {},
	// 		payment_reason: "",
	// 	});
	// }, [paymentMethods]);

	useEffect(() => {
		if (countrydata && paymentMethodsfields) {
			let modifiedPaymentMethods = [];
			paymentMethodsfields?.data?.map((paymentMethod, index2) => {
				if (paymentMethod.slug == "bank_transfer") {
					const fields = paymentMethod?.fields.map((field, index) => {
						if (field.slug == "bank_country") {
							return {
								...field,
								values: countrydata.data.map((country) => country.name),
							};
						}
						return field;
					});
					modifiedPaymentMethods.push({ ...paymentMethod, fields });
				} else {
					modifiedPaymentMethods.push({ ...paymentMethod });
				}
			});
			setPaymentMethods(modifiedPaymentMethods);
		}
	}, [countrydata, paymentMethodsfields]);

	useEffect(() => {
		const initializeFormData = () => {
			if (!prevData || !paymentMethods) return;

			const selectedPaymentMethod = paymentMethods.find(
				(method) =>
					method.slug === (prevData.mode_of_payment || currentPaymentMethod)
			);

			if (!selectedPaymentMethod) return;

			const updatedDetails = {};

			selectedPaymentMethod.fields.forEach((field) => {
				// If the field exists in prevData, use its value, otherwise set default
				updatedDetails[field.slug] = prevData.details
					? prevData.details[field.slug] || ""
					: "";
			});

			setFormData((prevFormData) => ({
				...prevFormData,
				details: updatedDetails,
				payment_reason: prevData.payment_reason || "",
			}));
		};

		initializeFormData();
	}, [data, paymentMethods, currentPaymentMethod]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log(formData);

		const res = await addPaymentMethod(
			formData
		);

		if (res.data.status) {
			toast.success("Payment method added successfully.");
			refetch();
		} else {
			toast.error("Failed to add payment method. Please try again later.");
		}
		// Handle response as needed
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			// [name]: value,
			...(name === "payment_reason" ? { payment_reason: value } : {}),
			details: {
				...prevFormData.details,
				...(name === "payment_reason" ? {} : { [name]: value }),
			},
		}));
	};

	const renderPaymentMethodFields = (mode_of_payment) => {
		if (!paymentMethods) return null;

		const selectedPaymentMethod = paymentMethods.find(
			(method) => method.slug === mode_of_payment
		);

		if (!selectedPaymentMethod) return null; // Ensure selected payment method exists
		return (
			<>
				{selectedPaymentMethod?.fields.map((field) => (
					<div key={field.slug}>
						<label className="required">{field.name}</label>
						{field.field_type === "input" ? (
							<input
								required
								name={field.slug}
								className={`form-control border ${(formData.details[field.slug] || "").trim() === ""
									? "border-danger"
									: ""
									}`}
								value={formData.details[field.slug]}
								onChange={handleChange}
							/>
						) : (
							<select
								required
								name={field.slug}
								className={`form-control border ${(formData.details[field.slug] || "").trim() === ""
									? "border-danger"
									: ""
									}`}
								value={formData.details[field.slug]}
								onChange={handleChange}
							>
								<option value="">Select {field.name}</option>
								{field.values?.map((value, index) => (
									<option key={index} value={value}>
										{value}
									</option>
								))}
							</select>
						)}
					</div>
				))}
			</>
		);
	};

	return (
		<div id="route-view" className="">
			<div>
				<h2 className="main-heading"></h2>
				<form onSubmit={handleSubmit}>
					<div
						className="form-panel"
						style={{
							maxWidth: "850px",
							marginLeft: "auto",
							marginRight: "auto",
						}}
					>
						<h3 className="heading">Add Payment Details</h3>
						<div
							className=""
							style={{
								padding: "8%",
								paddingTop: "5px",
							}}
						>
							<div className="">
								<label className="required">Payment Type</label>
								<select
									required
									className={`form-control border  ${formData.mode_of_payment.trim() === ""
										? "border-danger"
										: ""
										}`}
									name="mode_of_payment"
									value={formData.mode_of_payment}
									onChange={(e) => {
										const newPaymentMethod = e.target.value;
										setCurrentPaymentMethod(newPaymentMethod);
										setFormData((prevFormData) => ({
											...prevFormData,
											mode_of_payment: newPaymentMethod,
											details: {},
										}));
									}}
								>
									{paymentMethods?.map((v, valueIndex) => (
										<option key={valueIndex} value={v.slug}>
											{v.name}
										</option>
									))}
								</select>
							</div>
							{renderPaymentMethodFields(currentPaymentMethod)}
							<div className="">
								<label className="required">Payment Reason</label>
								<input
									required
									className={`form-control border  ${formData.payment_reason.trim() === "" ? "border-danger" : ""
										}`}
									name="payment_reason"
									value={formData.payment_reason}
									onChange={handleChange}
								></input>
							</div>
							<div
								style={{
									paddingTop: "20px",
									display: "flex",
									gap: "8px",
									alignItems: "center",
								}}
							>
								<h5 style={{ margin: "0" }}>Billing Cycle : </h5>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: "18px",
									}}
								>
									{prevData.billing_cycle}
								</span>
							</div>
						</div>
						<div style={{ left: 0, width: "100%" }} className="form-submit-bar">
							<div>
								<Link
									to={"/admin"}
									className="btn btn-sm btn-light text-muted mr-2 px-3"
								>
									Cancel
								</Link>
								<button
									type="submit"
									className="mr-2 px-3 btn btn-sm btn-submit"
								>
									{AddLoading ? "Adding..." : "Add"}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PaymentForm;
