import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetAllAdminQuery } from "../../../../slices/apiSlices/adminApi";
import {
	useGetAllCityMutation,
	useGetAllCountryQuery,
	useGetAllStateMutation,
} from "../../../../slices/apiSlices/otherApi";

import {
	useAdminGetPublisherQuery,
	useAdminupdatePublisherMutation,
} from "../../../../slices/apiSlices/adminApi";
import useUserData from "../../../../hooks/useUserData";

const PublisherUpdate = () => {

	const { adminData } = useUserData();
	const isSuperAdmin = adminData.user.is_super_admin;
	const { publisherId } = useParams();
	const [selectedCountry, setSelectedCountry] = useState("");
	const [selectedState, setSelectedState] = useState("");
	const [selectedCity, setSelectedCity] = useState("");
	const [states, setStates] = useState({});
	const [cities, setCities] = useState({});
	const [error, setError] = useState({});
	const navigate = useNavigate();
	const { data: admin_data } =
		useGetAllAdminQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});
	const [getAllCity] = useGetAllCityMutation();
	const [getAllState] = useGetAllStateMutation();
	const { data: countrydata, } =
		useGetAllCountryQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});
	const {
		data,
		isSuccess,
		isLoading: pubLoading,
		error: resErr,
	} = useAdminGetPublisherQuery({
		publisherId,
	}, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});

	const [adminupdatePublisher, { isLoading: UpdateLoading }] =
		useAdminupdatePublisherMutation();
	const [formData, setFormData] = useState({
		name: "",
		address_1: "",
		address_2: "",
		country: "",
		state: "",
		city: "",
		zipcode: "",
		internal_notes: "",
		account_manager_id: "",
		status: "",
		updateID: "",
	});
	useEffect(() => {
		const fetchData = async () => {
			const publisherData = data?.data[0];
			// Ensure that the state and city data match the structure returned by the API
			const publisherState = publisherData?.state || "";
			const publisherCity = publisherData?.city || "";
			setSelectedState(publisherState);
			setSelectedCity(publisherCity);
			const countryIsoCode = countrydata?.data?.find(
				(country) => country.name === publisherData?.country
			)?.isoCode;
			setSelectedCountry(countryIsoCode || "");
			if (countryIsoCode) {
				const stateRes = await getAllState({ country_code: countryIsoCode });

				setStates(stateRes);

				if (publisherState) {
					const stateIsoCode = stateRes?.data?.data?.find(
						(state) => state.name === publisherState
					)?.isoCode;

					if (stateIsoCode) {
						const cityRes = await getAllCity({
							country_code: countryIsoCode,
							state_code: stateIsoCode,
						});
						setCities(cityRes);
					}
				}
			}

			setFormData({
				...formData,
				name: publisherData?.name || "",
				address_1: publisherData?.address_1 || "",
				address_2: publisherData?.address_2 || "",
				country: countryIsoCode || "",
				state: publisherState,
				city: publisherCity,
				zipcode: publisherData?.zipcode || null,
				internal_notes: publisherData?.internal_notes || "",
				account_manager_id: publisherData?.account_manager_id || "",
				status: publisherData?.status || "",
				updateID: publisherData?._id || "",
			});
		};

		fetchData();
	}, [
		publisherId,
		data,
		countrydata,
		getAllState,
		getAllCity,
	]);
	if (pubLoading) {
		return <div>Loading...</div>;
	}
	if (resErr) {
		return <div>Error: {resErr?.data?.msg}</div>;
	}

	if (!isSuccess) {
		return <div>Unable to fetch data.</div>;
	}

	const handleCountryChange = async (e) => {
		const isoCode = e.target.value;
		setSelectedCountry(isoCode);
		setSelectedState("");
		setSelectedCity("");
		if (error?.data) {
			setError((prevError) => ({
				...prevError,
				data: prevError?.data?.filter((item) => item.field !== "country"),
			}));
		}
		const res = await getAllState({ country_code: isoCode });
		setStates(res);
		setFormData({
			...formData,
			country: isoCode,
			state: "",
			city: "",
		});
	};

	const handleStateChange = async (e) => {
		const isoCode =
			e.target.options[e.target.selectedIndex].getAttribute("data-code");
		const contcode =
			e.target.options[e.target.selectedIndex].getAttribute("data-contcode");
		const name = e.target.value;
		setSelectedState(name);
		setSelectedCity("");
		if (error?.data) {
			setError((prevError) => ({
				...prevError,
				data: prevError?.data?.filter((item) => item.field !== "state"),
			}));
		}
		const res = await getAllCity({
			country_code: contcode,
			state_code: isoCode,
		});
		setCities(res);
		setFormData({
			...formData,
			state: name,
			city: "",
		});
	};

	const handleCityChange = (e) => {
		const selectedCityId = e.target.value;
		setSelectedCity(selectedCityId);
		if (error?.data) {
			setError((prevError) => ({
				...prevError,
				data: prevError?.data?.filter((item) => item.field !== "city"),
			}));
		}
		setFormData({
			...formData,
			city: selectedCityId,
		});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (error?.data) {
			setError((prevError) => {
				const updatedError = {
					...prevError,
					data: prevError?.data?.filter((item) => item.field !== name),
				};
				return updatedError;
			});
		}
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const res = await adminupdatePublisher({ formData });
		if (res.error && res.error.data.errors) {
			const errors = res.error.data.errors;
			setError({ data: errors });
			if (errors.length > 4) {
				toast.error("Please fill in all the required fields.");
			} else {
				errors.forEach((err) => toast.error(err.message));
			}
		} else {
			if (res.data.success === true) {
				toast.success(res.data.msg);
				navigate("/admin/publisher-accounts");
				setFormData({
					name: "",
					address_1: "",
					address_2: "",
					country: "",
					state: "",
					city: "",
					zipcode: null,
					internal_notes: "",
					account_manager_id: "",
					status: undefined,
					updateID: "",
				});
			} else {
				toast.info(res.data.msg);
				console.log(res.data.msg, res.data.status);
				setError({
					msg: res.data.msg,
				});
			}
		}
	};
	const renderError = (fieldName) => {
		const errorItem = error?.data?.find((item) => item.field === fieldName);
		return errorItem ? (
			<div key={fieldName} className="text-danger">
				{errorItem.message}
			</div>
		) : null;
	};
	const admindata = admin_data?.data;

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">Update Publisher</h2>
				<form onSubmit={handleSubmit}>
					<div className="form-panel">
						<h3 className="heading">Account Details</h3>
						<div className="form-group">
							<label className="required">Account Name</label>
							<input
								className={`form-control border  ${formData.name.trim() === "" ? "border-danger" : ""
									}`}
								name="name"
								value={formData.name}
								onChange={handleChange}
							/>
							<small className="form-text info-text">
								{renderError("name")}
								(Your company, firm or any desirable name for account)
							</small>
						</div>
						<div className="form-group">
							<label className="required">Address 1</label>
							<input
								type="text"
								className={`form-control border  ${formData.address_1.trim() === "" ? "border-danger" : ""
									}`}
								name="address_1"
								value={formData.address_1}
								onChange={handleChange}
							></input>
							<small className="form-text info-text">
								{renderError("address_1")}
							</small>
						</div>
						<div className="form-group">
							<label>Address 2</label>
							<input
								type="text"
								className="form-control"
								name="address_2"
								value={formData.address_2}
								onChange={handleChange}
							/>
							<small className="form-text info-text"></small>
						</div>
						<div className="form-group">
							<label className="required">Country</label>
							<select
								id=""
								className="form-control"
								name="country"
								value={selectedCountry}
								onChange={handleCountryChange}
							>
								<option value=""></option>
								{countrydata?.data?.map((country) => (
									<option key={country.isoCode} value={country.isoCode}>
										{country.name}
									</option>
								))}
							</select>
							<small className="form-text info-text">
								{renderError("country")}
							</small>
						</div>
						<div className="form-group">
							<label className="required">State</label>
							<select
								id=""
								className="form-control"
								name="state"
								value={selectedState}
								onChange={handleStateChange}
								disabled={selectedCountry === ""}
							>
								<option value=""></option>
								{states?.data?.data?.map((state) => (
									<option
										key={state.isoCode}
										value={state.name}
										data-contcode={state.countryCode}
										data-code={state.isoCode}
									>
										{state.name}
									</option>
								))}
							</select>
							<small className="form-text info-text">
								{renderError("state")}
							</small>
							{selectedCountry === "" && (
								<small className="form-text text-danger">
									Select a country first to update state
								</small>
							)}
						</div>
						<div className="form-group row">
							<div className="col-md-6">
								<label className="required">City</label>
								<select
									id=""
									className="form-control"
									name="city"
									value={selectedCity}
									onChange={handleCityChange}
									disabled={selectedState === ""}
								>
									<option value=""></option>
									{cities?.data?.data?.map((city) => (
										<option key={city.name} value={city.name}>
											{city.name}
										</option>
									))}
								</select>
								<small className="form-text text-danger">
									{renderError("city")}
								</small>
								{selectedState === "" && (
									<small className="form-text text-danger">
										Select a Sate first to update city
									</small>
								)}
							</div>
							<div className="form-group">
								<label className="required">Postal Code</label>
								<input
									className={`form-control border  ${formData.zipcode === "" ? "border-danger" : ""
										}`}
									name="zipcode"
									value={formData.zipcode}
									onChange={handleChange}
								/>
								<small className="form-text info-text">
									{renderError("zipcode")}
								</small>
							</div>
							<div className="form-group">
								<label>Internal Notes</label>
								<textarea
									className="form-control"
									name="internal_notes"
									value={formData.internal_notes}
									onChange={handleChange}
								></textarea>
								<small className="form-text info-text">
									Only for Internal Records, Not Visible to Publishers
								</small>
							</div>
						</div>
					</div>
					<div className="form-panel">
						<h3 className="heading">Settings</h3>
						{isSuperAdmin === 1 ? <div className="form-group">
							<label className="required">Account Manager</label>
							<select
								className="form-control"
								name="account_manager_id"
								value={formData.account_manager_id}
								onChange={handleChange}
							>
								<option value="">Select Account Manager</option>
								{admindata?.map((user) => (
									<option key={user?._id} value={user?._id}>
										{user?.name} - {user?.role_data?.role}
									</option>
								))}
							</select>
							<small className="form-text text-danger">
								{renderError("account_manager_id")}
							</small>
						</div> : null}
						<div className="form-group">
							<label className="required">Status</label>
							<select
								className="form-control"
								name="status"
								value={formData.status}
								onChange={handleChange}
							>
								<option value=""></option>
								<option value="active">Active</option>
								<option value="pending">Pending</option>
							</select>
							<small className="form-text text-danger">
								{renderError("status")}
							</small>
						</div>
						<div className="form-submit-bar">
							<div>
								<Link
									to={`/admin/publisher-accounts`}
									className="btn btn-sm btn-light text-muted mr-2 px-3"
								>
									Cancel
								</Link>
								<button
									type="submit"
									className="mr-2 px-3 btn btn-sm btn-submit"
								>
									{UpdateLoading ? "Updating..." : "Update"}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PublisherUpdate;
