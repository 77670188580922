import React, { useEffect, useState, useMemo } from "react";
import { Button, Modal } from "@mui/material";
import {
	useAdminPublisherDuopPaymentListQuery,
	useAdminPublisherAddPaymentMutation,
} from "../../../../slices/apiSlices/adminApi";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import ReactSelect from "react-select";

const DuoPaymentList = () => {
	// const navigate = useNavigate();
	const { userId } = useParams();

	const [selectedOption, setSelectedOption] = useState();
	const [open, setOpen] = useState(false);
	const [formData, setFormData] = useState({
		publisher_id: null,
		publisher_name: null,
		// payable_amount: null,
		payable_id: "",
		// paid_amount: "",
		transaction_id: "",
	});

	const { data, isSuccess, refetch, isLoading, error } =
		useAdminPublisherDuopPaymentListQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});

	const [adminPublisherAddPayment, { isLoading: AddLoading }] =
		useAdminPublisherAddPaymentMutation();

	useEffect(() => {
		setSelectedOption(userId);
	}, [userId]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		// if (
		// 	name === "paid_amount" &&
		// 	(!/^[0-9]*$/.test(value) || parseInt(value, 10) > formData.payable_amount)
		// ) {
		// 	toast.error("Invalid amount!");
		// 	return;
		// }
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const res = await adminPublisherAddPayment({ formData });
			if (res.error) {
				toast.error("Payment failed!");
			} else {
				toast.success("Payment successful!");
				handleClose();
				setFormData({
					publisher_id: null,
					publisher_name: null,
					paid_amount: "",
					payable_id: "",
					transaction_id: "",
				});
				refetch();
			}
		} catch {
			toast.error("An error occurred!");
		}
	};

	const handleOpenWithPublisher = (
		publisher_id,
		publisher_name,
		// payable_amount,
		payable_id
	) => {
		setFormData({
			publisher_id,
			publisher_name,
			// payable_amount,
			payable_id,
			paid_amount: "",
			transaction_id: "",
		});
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
		toast.success("ID copied to clipboard!");
	};

	const getShortenedId = (id) =>
		`${id?.substring(0, 4)}...${id?.substring(id?.length - 4)}`;

	// const setMaxAmount = () => {
	// 	setFormData((prevData) => ({
	// 		...prevData,
	// 		paid_amount: formData.payable_amount,
	// 	}));
	// };

	const formattedData = useMemo(() => {
		return (
			data?.data?.map((payment, index) => ({
				id: index + 1,
				_id: payment?._id,
				name: (
					<Link
						to={`/admin/publisher-accounts/publishers/userview/${payment.publisher_detail._id}`}
						className="profile-view"
					>
						{payment.publisher_detail.name}
					</Link>
				),
				payable_amount: payment.payable_amount, ///
				mode_of_payment: payment?.mode_of_payment
					? payment?.mode_of_payment
						.replace(/_/g, " ")
						.replace(/\b\w/g, (char) => char.toUpperCase())
					: "",
				payment_reason: payment?.payment_reason,
				currency: payment?.currency?.toUpperCase(), //
				email: payment.publisher_detail.email,
				cell_phone: payment.publisher_detail.cell_phone,
				status: payment.publisher_detail.status,
				button: (
					<Button
						onClick={() =>
							handleOpenWithPublisher(
								payment.publisher_detail._id,
								payment.publisher_detail.name,
								payment._id
								// payment.payable_amount
							)
						}
						variant="text"
						color="primary"
					>
						Pay Now
					</Button>
				),
			})) || []
		);
	}, [data]);

	const columns = useMemo(
		() => [
			{ name: "ID", selector: (row) => row.id, sortable: true },
			{ name: "Publisher Name", selector: (row) => row.name },
			{ name: "Email", selector: (row) => row.email },
			{ name: "Cell Phone", selector: (row) => row.cell_phone },
			{ name: "Currency", selector: (row) => row.currency, sortable: true },
			{
				name: "Mode of Payment",
				selector: (row) => row.mode_of_payment,
				sortable: true,
			},
			{
				name: "Payable Amount",
				selector: (row) => row.payable_amount,
				sortable: true,
			},
			{
				name: "Status",
				selector: (row) => row.status,
				cell: (row) => (
					<div>
						<FontAwesomeIcon
							className={`border-0 text-${row.status === "active" ? "success" : "muted"
								}`}
							icon={faCircle}
						/>{" "}
						{row.status.charAt(0).toUpperCase() + row.status.slice(1)}
					</div>
				),
			},
			{ name: "  ", selector: (row) => row.button },
		],
		[]
	);

	const filteredData =
		selectedOption === "all"
			? formattedData
			: formattedData.filter((item) => item._id === selectedOption);

	const handleSelectChange = (selectedOption) => {
		setSelectedOption(selectedOption ? selectedOption.value : "all");
	};

	const options = [
		{ value: "all", label: "All" },
		...formattedData.map((item) => ({
			value: item._id,
			label: item.name.props.children,
		})),
	];

	if (isLoading) return <div>Loading...</div>;
	if (error) return <div>Error: {error?.data?.msg}</div>;
	if (!isSuccess) return <div>Unable to fetch data.</div>;

	return (
		<div id="route-view" className="col-md-12">
			<div className="paymentHistoryTables_wrapper dt-bootstrap">
				<h2 className="main-heading">Due Payments</h2>
				<div className="table-supplies">
					<div className="top-filters">
						<div className="search-bar w-25 ml-auto">
							<ReactSelect
								value={options.find(
									(option) => option.value === selectedOption
								)}
								onChange={handleSelectChange}
								options={options}
								placeholder="Search by Name..."
								isClearable
							/>
						</div>
					</div>
					<div className="dataTable offers-datatable">
						<DataTable
							columns={columns}
							data={filteredData}
							pagination
							highlightOnHover
							fixedHeader
							responsive
							paginationPerPage={10}
							customStyles={{
								tableWrapper: {
									style: {
										height: '400px',
									},
								},
							}}
						/>
					</div>
				</div>
			</div>
			<Modal open={open} onClose={handleClose}>
				<div role="document" className="modal-dialog modal-dialog-centered">
					<div className="modal-content rounded-0 border-0 shadow">
						<div className="modal-header">
							<h5>Submit Payment</h5>
							<Button onClick={handleClose} className="close">
								<FontAwesomeIcon icon={faTimes} />
							</Button>
						</div>
						<form onSubmit={handleSubmit}>
							<div className="modal-body px-5 py-4">
								<div>
									<label className="required">Publisher Name</label>
									<div>
										{formData.publisher_name} (
										<span
											style={{ cursor: "pointer", color: "blue" }}
											onClick={() => copyToClipboard(formData.publisher_id)}
										>
											{getShortenedId(formData.publisher_id)}
										</span>
										)
									</div>
									<input
										name="publisher_id"
										value={formData.payable_id}
										hidden
									/>
								</div>
								{/* <div>
									<label className="required">Amount</label>
									<div className="input-group">
										<input
											name="paid_amount"
											className="form-control border"
											value={formData.paid_amount}
											onChange={handleChange}
											pattern="[0-9]*"
											title="Amount must be a positive integer"
											required
										/>
										<div className="input-group-append">
											<Button
												type="button"
												className="btn btn-outline-secondary"
												onClick={setMaxAmount}
											>
												Max
											</Button>
										</div>
									</div>
								</div> */}
								<div>
									<label className="required">Transaction Id</label>
									<input
										name="transaction_id"
										className="form-control border"
										value={formData.transaction_id}
										onChange={handleChange}
										required
									/>
								</div>
							</div>
							<div className="modal-footer">
								<Button
									onClick={handleClose}
									style={{ backgroundColor: "#6c757d", color: "white" }}
								>
									Cancel
								</Button>
								<Button
									type="submit"
									variant="contained"
									style={{
										backgroundColor: "rgba(96, 155, 189, 0.9)",
										color: "white",
									}}
								>
									Pay
								</Button>
								{AddLoading && (
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									></span>
								)}
							</div>
						</form>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default DuoPaymentList;
