import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { faCircle, faCog, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useAdminGetAllPublisherQuery } from "../../../../slices/apiSlices/adminApi";

const PublisherAccounts = () => {
	const [filterText, setFilterText] = useState("");

	const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);

	const { data, error } = useAdminGetAllPublisherQuery(null, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});


	if (error) {
		return <div>Error: {error.data.msg}</div>;
	}

	const userData = data?.data;

	const formattedData = Array.isArray(userData)
		? userData.map((pub, index) => {
			return {
				id: index + 1,
				_id: pub._id,
				name: (
					<Link
						to={`/admin/publisher-accounts/${pub._id}`}
						className="profile-view"
					>
						{pub.name}
					</Link>
				),
				status: pub.status,
				created_at: new Date(pub.joining_date).toLocaleDateString(),
				UsersCount: pub.users_count, // You might need to update this based on actual user counts
				AccountManager: pub?.account_manager_details?.name || "",
				country: pub.flag,
			};
		})
		: [];
	const columns = [
		{
			name: "#ID",
			selector: (row) => row.id,
			sortable: true,
			width: "10%",
		},
		{
			name: "Name",
			selector: (row) => row.name,
			sortable: true,
			width: "15%",
		},
		{
			name: "Users Count",
			selector: (row) => row.UsersCount,
			sortable: true,
			width: "15%",
		},

		{
			name: "Account Manager",
			selector: (row) => row.AccountManager,
			sortable: true,
			width: "20%",
		},
		{
			name: "Status",
			selector: (row) => row.status,
			cell: (row) => (
				<div>
					{row.status == "active" ? (
						<div>
							<FontAwesomeIcon
								data-toggle="dropdown"
								className="border-0 text-success"
								icon={faCircle}
							/>{" "}
							Active
						</div>
					) : (
						<div>
							<FontAwesomeIcon
								data-toggle="dropdown"
								className="border-0 text-warning"
								icon={faCircle}
							/>{" "}
							Pending
						</div>
					)}
				</div>
			),
			width: "10%",
		},
		{
			name: "Country",
			selector: (row) => row.country,
			width: "10%",
		},
		{
			name: "Created At",
			selector: (row) => row.created_at,
			width: "15%",
		},
		{
			name: "",
			cell: (row) => (
				<div className="row-edit text-center">
					<div className="dropdown show">
						<FontAwesomeIcon
							data-toggle="dropdown"
							className="faCog"
							icon={faCog}
							onClick={() => toggleDropdownVisibility(row.id - 1)}
						/>

						<div
							className={`dropdown-menu rounded-0 ${activeDropdownIndex === row.id - 1 ? "show" : ""
								}`}
							style={
								activeDropdownIndex === row.id - 1
									? {
										position: "absolute",
										transform: "translate3d(-79px, 18px, 0px)",
										top: "0px",
										left: "0px",
										willChange: "transform",
									}
									: {}
							}
						>
							<Link
								to={`/admin/publisher-accounts/edit/${row._id}`}
								className="dropdown-item"
							>
								Edit
							</Link>
							<Link
								to={`/admin/publisher-accounts/publishers/${row._id}`}
								className="dropdown-item"
							>
								Users
							</Link>
						</div>
					</div>
				</div>
			),
			width: "5%",
		},
	];
	const toggleDropdownVisibility = (index) => {
		// If the clicked row's dropdown is already open, close it
		if (activeDropdownIndex === index) {
			setActiveDropdownIndex(null);
		} else {
			// Otherwise, open the clicked row's dropdown and close any other open dropdown
			setActiveDropdownIndex(index);
		}
	};

	// Function to handle search input changes
	const handleFilter = (event) => {
		const value = event.target.value || "";
		setFilterText(value);
	};

	// Apply search filter to the data
	const filteredData = formattedData.filter((item) => {
		const name = (item.name && item.name.props.children) || "";
		const accountId = String(item.id) || "";
		const manager = item.AccountManager || "";
		const country = item.country || "";
		const status = item.status ? "Active" : "Pending";

		return (
			name.toLowerCase().includes(filterText.toLowerCase()) ||
			accountId.toLowerCase().includes(filterText.toLowerCase()) ||
			manager.toLowerCase().includes(filterText.toLowerCase()) ||
			country.toLowerCase().includes(filterText.toLowerCase()) ||
			status.toLowerCase().includes(filterText.toLowerCase())
		);
	});

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">
					Publisher Accounts
					<div className="side-link">
						<Link to={`/admin/publisher-accounts/add`} className="btn btn-side">
							<FontAwesomeIcon icon={faPlus} /> Add New Publisher Account
						</Link>
					</div>
				</h2>
				<div className="table-supplies">
					<div className="top-filters">
						<div className="search-bar w-25 ml-auto">
							<input
								type="text"
								placeholder="Search... ID, Name, Status , etc"
								className="form-control"
								value={filterText}
								onChange={handleFilter}
							/>
						</div>
					</div>
					<div className="dataTable">
						<DataTable
							title=""
							columns={columns}
							data={filteredData}
							pagination
							highlightOnHover
							fixedHeader
							paginationPerPage={10}
							customStyles={{
								tableWrapper: {
									style: {
										height: '60vh'
									},
								},
								headRow: {
									style: {
										// Style for the entire header row
										display: "flex",
										justifyContent: "center",
										background: "your_custom_background_color", // Add your custom background color
										color: "your_custom_text_color", // Add your custom text color
									},
								},
								headCells: {
									style: {
										// Style for individual header cells
										// Add any additional styling for header cells here
									},
								},
								rows: {
									style: {
										display: "flex",
										justifyContent: "center",
									},
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PublisherAccounts;
