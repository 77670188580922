import React, { useEffect, useState } from "react";
import "../Admin.css";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronDown,
	faChevronUp,
	faCog,
} from "@fortawesome/free-solid-svg-icons";
import { useAdminGetOffersQuery } from "../../../../slices/apiSlices/adminApi";
import {
	useGetAllDevicesQuery,
	useGetAllPriceFormatsQuery,
	useGetAllVerticlesQuery,
	useGetAllMediaTypesQuery,
	useGetAllCountryQuery
} from "../../../../slices/apiSlices/otherApi";
import { Link } from "react-router-dom";

const Offers = () => {
	const imgBaseUrl = process.env.REACT_APP_API_URL;
	const [filterText, setFilterText] = useState("");
	const [showFilter, setShowFilter] = useState(false);
	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState({ columns: [], rows: [] });

	const [selectedFilters, setSelectedFilters] = useState({
		media_type_id: "",
		verticle_id: "",
		price_format_id: "",
		device_id: "",
		country: "",
		status: "",
	});

	const { data: devices } = useGetAllDevicesQuery(null, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});
	const { data: price_formats } =
		useGetAllPriceFormatsQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});
	const { data: verticles } =
		useGetAllVerticlesQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});
	const { data: media_types } =
		useGetAllMediaTypesQuery(null, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});
	const { data: countries } = useGetAllCountryQuery(null, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});

	const { data } = useAdminGetOffersQuery({
		...selectedFilters,
	}, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});

	useEffect(() => {
		setLoading(true);
		fetchData();
	}, [data]);

	const columns = [
		{
			name: "#ID",
			selector: (row) => row.Id,
			sortable: true,
			width: "7%",
			cell: (row) => <div className="table-cell">{row.Id}</div>,
		},
		{
			name: "Image",
			cell: (row) => (
				<div className="table-cell">
					<img
						src={imgBaseUrl + row.Image}
						alt="Offer Image"
						className="offer-image"
						height={30}
						width={50}
					/>
				</div>
			),
			sortable: true,
		},
		{
			name: "Offer",
			selector: (row) => row.Offer,
			sortable: true,
			cell: (row) => <div className="table-cell">{row.Offer}</div>,
		},
		{
			name: "Visibility",
			selector: (row) => row.Visibility,
			sortable: true,
			cell: (row) => <div className="table-cell">{row.Visibility}</div>,
		},
		{
			name: "Status",
			selector: (row) => row.status,
			cell: (row) => <div className="table-cell">{row.status}</div>,
		},
		{
			name: "Verticles",
			selector: (row) => row.Verticles,
			cell: (row) => <div className="table-cell">{row.Verticles}</div>,
		},
		{
			name: "Devices",
			selector: (row) => (
				<div className="table-cell">
					{row.Devices.map((device, index) => (
						<span
							key={index}
							className="font-italic badge d-flex align-items-center border mb-1"
						>
							{device}
						</span>
					))}
				</div>
			),
		},
		{
			name: "Media Types",
			selector: (row) => (
				<div className="table-cell">
					{row.MediaTypes.length > 0
						? row.MediaTypes.map((mediaType, index) => (
							<span
								key={index}
								className="font-italic badge d-flex align-items-center border mb-1"
							>
								{mediaType}
							</span>
						))
						: "All"}
				</div>
			),
			sortable: true,
		},
		{
			name: "Advertiser",
			selector: (row) => row.Advertiser,
			sortable: true,
			cell: (row) => <div className="table-cell">{row.Advertiser}</div>,
		},
		{
			name: "Countries",
			selector: (row) => (
				<div className="table-cell">
					{row.Countries.length > 0
						? row.Countries.map((country, index) => country).join(", ")
						: "All"}
				</div>
			),
		},
		{
			name: "Payout",
			selector: (row) => row.Payout,
			sortable: true,
			cell: (row) =>
				<div className="table-cell">
					<div>{row.Payout.type}</div>
					<div>{row.Payout.value}</div>
				</div>,
		},
		{
			name: "Revenue",
			selector: (row) => row.Revenue,
			sortable: true,
			cell: (row) => (
				<div className="table-cell">
					<div>{row.Revenue.type}</div>
					<div>{row.Revenue.value}</div>
				</div>
			),
		},
		{
			name: "",
			cell: (row) => (
				<div className="table-cell row-edit text-center">
					<div className="dropdown dropleft show">
						<FontAwesomeIcon
							data-toggle="dropdown"
							className="faCog"
							icon={faCog}
							onClick={(event) => toggleDropdownVisibility(row.Id - 1, event)}
						/>

						<div
							id={`row-dropdown${row.Id - 1}`}
							className={`dropdown-menu dt-dropdown rounded-0 `}
							style={{
								position: "absolute",
								transform: "translate3d(-162px, 0px, 0px)",
								top: "0px",
								left: "0px",
								willChange: "transform",
							}}
						>
							<Link
								to={`/admin/offers/edit/${row._id}`}
								className="dropdown-item"
							>
								Edit
							</Link>
							<Link
								to={`/admin/offers/snapshot/${row._id}`}
								className="dropdown-item"
							>
								SnapShot
							</Link>
							<Link
								to={`/admin/offers/applications/${row._id}`}
								className="dropdown-item"
							>
								Offer Applications
							</Link>
						</div>
					</div>
				</div>
			),
		},
	];


	const fetchData = async () => {
		try {
			const result = data;
			const formattedData = Array.isArray(result?.data)
				? result?.data.map((offer, index) => {
					return {
						Id: index + 1,
						_id: offer._id,
						Image: offer?.image,
						name: offer?.name,
						Offer: (
							<Link
								to={`/admin/offers/snapshot/${offer._id}`}
								className="profile-view"
							>
								{offer?.name}
							</Link>
						),
						Visibility: offer?.visibility,
						status: offer?.status,
						Verticles: offer?.verticleData?.name,
						Devices: offer?.DeviceData?.map((dev) => dev.name),
						MediaTypes: offer?.mediaTypeData?.map((media) => media.name),
						Advertiser: offer?.advertiserData?.name,
						Countries: offer?.flag?.map((country) => country),
						Payout: {
							type: offer?.payout_type || "N/A",
							value: offer?.payout_value ? `$${offer.payout_value}` : "N/A"
						},
						Revenue: {
							type: offer?.revenue_type || "N/A",
							value: offer?.revenue_value ? `$${offer.revenue_value}` : "N/A"
						},
					};
				})
				: [];
			setTableData((prevTableData) => ({
				...prevTableData,
				rows: formattedData,
				columns: columns,
			}));
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error("Error fetching data:", error);
		}
	};

	const toggleDropdownVisibility = (index, event) => {
		document.getElementById(`row-dropdown${index}`).classList.toggle("show");

		document.querySelectorAll(`.dt-dropdown`).forEach((dropdown) => {
			if (
				dropdown.getAttribute("id") !=
				event?.currentTarget.nextElementSibling.getAttribute("id")
			) {
				dropdown.classList.remove("show");
			}
		});
	};

	const selectedFiltersHandler = (value, filterType) => {
		setSelectedFilters((prevFilters) => ({
			...prevFilters,
			[filterType]: value,
		}));

		// Call fetchData to make the API call with updated filters
		fetchData();
	};

	const clearFilters = () => {
		setSelectedFilters({
			media_type_id: "",
			verticle_id: "",
			price_format_id: "",
			device_id: "",
			country: "",
			status: "",
		});
		fetchData();
	};

	const getStatusOffers = (value) => {
		setSelectedFilters((prevFilters) => ({
			...prevFilters,
			status: value,
		}));
		fetchData(); // Assuming fetchData is the function that makes the API call
	};

	const handleShowFilter = () => {
		setShowFilter(!showFilter);
	};
	// Function to handle search input changes
	const handleSearchFilter = (event) => {
		const value = event.target.value || "";
		setFilterText(value);
	};

	const filteredData = tableData.rows.filter((item) => {
		const name = item.name || "";
		return name.toLowerCase().includes(filterText.toLowerCase());
	});
	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">Offers</h2>
				<div className="form-inputs mt-4 mb-4">
					<div className="row">
						<div className="col-sm-2">
							<div className="form-group">
								<select
									id=""
									onChange={(event) => getStatusOffers(event.target.value)}
									className="form-control"
								>
									<option value="">Show All</option>
									<option value="active">Active</option>
									<option value="inactive">Inactive</option>
									<option value="paused">Paused</option>
								</select>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-2">
							<p
								className="alignCenter clickable text-primary"
								onClick={handleShowFilter}
							>
								<small>Show filters</small>
								<small>
									&nbsp;
									<FontAwesomeIcon
										data-toggle="dropdown"
										icon={showFilter ? faChevronUp : faChevronDown}
										style={{ cursor: "pointer" }}
									/>
								</small>
							</p>
						</div>
					</div>
					{showFilter ? (
						<div className="row">
							<div className="col">
								<select
									value={selectedFilters.device_id}
									onChange={(e) =>
										selectedFiltersHandler(e.target.value, "device_id")
									}
									id=""
									className="form-control select2-devices"
								>
									<option value="">Select Devices</option>
									{devices?.data.map((pF) => (
										<option key={pF._id} value={pF._id}>
											{pF.name}{" "}
										</option>
									))}
								</select>
							</div>
							<div className="col">
								<select
									id=""
									onChange={(e) =>
										selectedFiltersHandler(e.target.value, "verticle_id")
									}
									className="form-control select2-verticles"
									value={selectedFilters.verticle_id}
								>
									<option value="">Select Verticles</option>
									{verticles?.data.map((pF) => (
										<option key={pF._id} value={pF._id}>
											{pF.name}{" "}
										</option>
									))}
								</select>
							</div>
							<div className="col">
								<select
									id=""
									onChange={(e) =>
										selectedFiltersHandler(e.target.value, "price_format_id")
									}
									className="form-control select2-price-format"
									value={selectedFilters.price_format_id}
								>
									<option value="">Select Price Formats</option>
									{price_formats?.data.map((pF) => (
										<option key={pF._id} value={pF._id}>
											{pF.name}{" "}
										</option>
									))}
								</select>
							</div>
							<div className="col">
								<select
									id=""
									onChange={(e) =>
										selectedFiltersHandler(e.target.value, "media_type_id")
									}
									className="form-control select2-media-type"
									value={selectedFilters.media_type_id}
								>
									<option value="">Select Media Type</option>
									{media_types?.data.map((pF) => (
										<option key={pF._id} value={pF._id}>
											{pF.name}{" "}
										</option>
									))}
								</select>
							</div>
							<div className="col">
								<select
									id=""
									onChange={(e) =>
										selectedFiltersHandler(e.target.value, "country")
									}
									className="form-control select2-country"
									value={selectedFilters.country}
								>
									<option value="">Select Devices Country</option>
									{countries?.data.map((pF) => (
										<option key={pF._id} value={pF._id}>
											{pF.name}{" "}
										</option>
									))}
								</select>
							</div>
							<div className="col-12 pt-3">
								<div className="form-group">
									<button
										onClick={clearFilters}
										className="btn btn-default btn-sm clear-filter-button btn-outline-primary"
									>
										Clear Filters
									</button>
								</div>
							</div>
						</div>
					) : null}
				</div>
				<div className="dataTables_wrapper dt-bootstrap">
					<div className="row form-inputs">
						<div className="col-sm-4"></div>
						<div className="offset-sm-4 col-sm-4">
							<label className="pull-right alignCenter">
								{" "}
								Search &nbsp;{" "}
								<input
									type="text"
									placeholder="Type to Search"
									value={filterText}
									onChange={handleSearchFilter}
								/>
							</label>
						</div>
					</div>
					<div className="dataTable offers-datatable">
						<DataTable
							columns={tableData.columns}
							data={filteredData}
							pagination
							highlightOnHover
							fixedHeader
							responsive
							paginationPerPage={10}
							customStyles={{
								tableWrapper: {
									style: {
										height: '400px',
									},
								},
							}}
						// loading={loading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Offers;
