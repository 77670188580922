import React from "react";
import { useAdvertiserGetOfferQuery } from "../../../../slices/apiSlices/advertiserApi";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faCircle,
	faEdit,
	faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const OfferSnapshot = () => {
	const imgBaseUrl = process.env.REACT_APP_API_URL;
	const { offerId } = useParams();
	const { data, isLoading, isSuccess, error } =
		useAdvertiserGetOfferQuery(
			offerId,
			{
				refetchOnMountOrArgChange: true,
				refetchOnReconnect: true,
			});



	if (isLoading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>Error: {error?.data?.msg}</div>;
	}

	if (!isSuccess) {
		return <div>Unable to fetch data.</div>;
	}

	const offerData = data?.data[0];
	const resources = offerData?.resources || {};
	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">Snapshot</h2>
				<div className="my-account-main">
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Offer Info</span>
								</div>
								<div className="right">
									<Link to={`/advertiser/offer/edit/${offerId}`}>
										<FontAwesomeIcon icon={faEdit} /> Edit{" "}
									</Link>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left"></div>
									<div className="right">
										<img
											src={imgBaseUrl + offerData?.image}
											alt="image"
											width="140"
											height="70"
										/>
									</div>
								</div>
								<div className="body-row">
									<div className="left">ID</div>

									<div className="right">
										{offerData?._id ? (
											offerData?._id
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Name</div>

									<div className="right">
										{offerData?.name ? (
											offerData?.name
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Status</div>

									<div>
										{offerData?.status === "active" ? (
											<span>
												<FontAwesomeIcon
													icon={faCircle}
													className="text-success border-0 small"
												/>
												&nbsp; Active
											</span>
										) : offerData?.status === "paused" ? (
											<span>
												<FontAwesomeIcon
													icon={faCircle}
													className="text-muted border-0 small"
												/>
												&nbsp; Paused
											</span>
										) : offerData?.status === "deleted" ? (
											<span>
												<FontAwesomeIcon
													icon={faCircle}
													className="text-secondary border-0 small"
												/>
												&nbsp; Deleted
											</span>
										) : offerData?.status === "pending" ? (
											<span>
												<FontAwesomeIcon
													icon={faCircle}
													className="text-warning border-0 small"
												/>
												&nbsp; Pending
											</span>
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Advertiser</div>

									<div className="right">
										{offerData?.advertiserAccountData?.name ? (
											offerData?.advertiserAccountData?.name
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Verticle</div>

									<div className="right">
										{offerData?.varticleData?.name ? (
											offerData?.varticleData?.name
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Expire on</div>

									<div className="right">
										{offerData?.expiry_date ? (
											offerData?.expiry_date
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Created Date</div>

									<div className="right">
										{offerData?.createdAt ? (
											offerData?.createdAt
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Last Updated Date</div>

									<div className="right">
										{offerData?.updatedAt ? (
											offerData?.updatedAt
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Base Destination URL</div>

									<div
										className="right"
										style={{
											wordWrap: "break-word",
											wordBreak: "break-all",
											maxWidth: "600px",
										}}
									>
										{offerData?.destination_url ? (
											offerData?.destination_url
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Preview URL</div>

									<div
										className="right"
										style={{
											wordWrap: "break-word",
											wordBreak: "break-all",
											maxWidth: "600px",
										}}
									>
										{offerData?.preview_url ? (
											offerData?.preview_url
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Currency</div>

									<div className="right">
										{offerData?.currencyData?.name ? (
											offerData?.currencyData?.name
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Notes</div>

									<div className="right">
										{offerData?.internal_notes ? (
											offerData?.internal_notes
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Description</div>
									<div
										className="right"
										style={{
											wordWrap: "break-word",
											wordBreak: "break-all",
											maxWidth: "600px",
										}}
									>
										{offerData?.description ? (
											offerData?.description
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Revenue &amp; Payout</span>
								</div>
							</div>
							<div className="body">
								<div className="table-responsive">
									<table className="table table-stripped">
										<tbody>
											<tr>
												<th>ID</th>
												<th>Name</th>
												<th>Private</th>
												<th>Revenue</th>
												<th>Payout</th>
												<th>Postback</th>
												<th>Approval</th>
												<th>Duplicate</th>
											</tr>
											<tr>
												<td>{offerData?._id}</td>
												<td></td>
												<td>no</td>
												<td>
													{offerData?.revenue_type +
														":" +
														offerData?.revenue_value}
												</td>
												<td>
													{offerData?.payout_type +
														":" +
														offerData?.payout_value}
												</td>
												<td>
													<FontAwesomeIcon
														icon={faCheckCircle}
														className="text-green"
													/>
												</td>
												<td>
													<FontAwesomeIcon
														icon={faTimesCircle}
														className="text-red"
													/>
												</td>
												<td>
													<FontAwesomeIcon
														icon={faTimesCircle}
														className="text-red"
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Stats</span>
								</div>
							</div>

							<div className="body">
								<div className="table-responsive">
									<table className="table table-stripped">
										<tbody>
											<tr>
												<th>Revenue</th>
												<th>Payout</th>
												<th>Margin</th>
												<th>Clicks</th>
												<th>CV</th>
												<th>CVR</th>
											</tr>
											<tr>
												<td>{offerData?.revenue_value}</td>
												<td>{offerData?.payout_value}</td>
												<td>
													{offerData?.revenue_value / offerData?.payout_value}
												</td>
												<td>0</td>
												<td>0</td>
												<td>0%</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Targeting</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Countries</div>
									<div className="right">
										{offerData?.countries && offerData?.countries.length > 0
											? offerData.countries.map((country, index) => (
												<span key={index}>{country}</span>
											))
											: "All"}
									</div>
								</div>
							</div>
						</div>
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Tracking Rules</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Visibilty</div>

									<div className="right">
										{offerData?.visibility ? (
											offerData?.visibility
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Conversion Method</div>

									<div className="right">
										{offerData?.tracking?.conversion_method ? (
											offerData?.tracking?.conversion_method
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Duplicate Click Filter</div>

									<div className="right">
										{offerData?.tracking?.enable_duplicate_filter === true ? (
											<FontAwesomeIcon
												icon={faCheckCircle}
												className="text-red"
											/>
										) : (
											<FontAwesomeIcon
												icon={faTimesCircle}
												className="text-red"
											/>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">SSL</div>

									<div className="right">
										{offerData?.tracking?.force_ssl === true ? (
											<FontAwesomeIcon
												icon={faCheckCircle}
												className="text-red"
											/>
										) : (
											<FontAwesomeIcon
												icon={faTimesCircle}
												className="text-red"
											/>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Deep Link</div>

									<div className="right">
										{offerData?.tracking?.enable_deep_link === true ? (
											<FontAwesomeIcon
												icon={faCheckCircle}
												className="text-red"
											/>
										) : (
											<FontAwesomeIcon
												icon={faTimesCircle}
												className="text-red"
											/>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Unique Session identifier</div>

									<div className="right">
										{offerData?.tracking?.unique_session_duration ? (
											offerData?.tracking?.unique_session_identifier +
											":" +
											offerData?.tracking?.unique_session_duration +
											":" +
											offerData?.tracking?.unique_session_interval
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Terms &amp; Conditions</div>

									<div className="right">
										{offerData?.require_terms_conditions ? (
											"Terms And Conditions Text "
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Resources</span>
								</div>
							</div>
							<div className="body">
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignContent: "center",
									}}
								>
									<Link to={`/advertiser/offer/snapshot/${offerId}/resources`}>
										<button className="btn btn-primary mx-2">
											View / Download
										</button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				{offerData?.caps === "enable" ? (
					<div className="my-account-main">
						<div className="my-account">
							<div className="account shadow">
								<div className="head">
									<div className="left">
										<span>Caps</span>
									</div>{" "}
								</div>{" "}
								<div className="body">
									<div className="table-responsive">
										<table className="table table-stripped">
											<thead>
												<tr>
													<th>Type</th>
													<th>Daily</th>
													<th>Weekly</th>
													<th>Monthly</th>
													<th>Global</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Clicks</td>
													<td>{offerData?.offer_caps?.click_caps?.daily}</td>
													<td>{offerData?.offer_caps?.click_caps?.weekly}</td>
													<td>{offerData?.offer_caps?.click_caps?.monthly}</td>
													<td>{offerData?.offer_caps?.click_caps?.global}</td>
												</tr>
												<tr>
													<td>Conversions</td>
													<td>
														{offerData?.offer_caps?.conversion_caps?.daily}
													</td>
													<td>
														{offerData?.offer_caps?.conversion_caps?.weekly}
													</td>
													<td>
														{offerData?.offer_caps?.conversion_caps?.monthly}
													</td>
													<td>
														{offerData?.offer_caps?.conversion_caps?.global}
													</td>
												</tr>
												<tr>
													<td>Payout</td>
													<td>{offerData?.offer_caps?.payout_caps?.daily}</td>
													<td>{offerData?.offer_caps?.payout_caps?.weekly}</td>
													<td>{offerData?.offer_caps?.payout_caps?.monthly}</td>
													<td>{offerData?.offer_caps?.payout_caps?.global}</td>
												</tr>
												<tr>
													<td>Revenue</td>
													<td>{offerData?.offer_caps?.revenue_caps?.daily}</td>
													<td>{offerData?.offer_caps?.revenue_caps?.weekly}</td>
													<td>
														{offerData?.offer_caps?.revenue_caps?.monthly}
													</td>
													<td>{offerData?.offer_caps?.revenue_caps?.global}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
};
export default OfferSnapshot;