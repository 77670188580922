import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Collapse from "@mui/material/Collapse";
import React, { useEffect, useState } from "react";
import { useAdminAddRoleMutation, useGetAllPermissionsQuery } from "../../../../../slices/apiSlices/adminApi";
import { Link, useNavigate } from "react-router-dom";


const AddRoleForm = () => {
	const navigate = useNavigate();
	const [error, setError] = useState({ msg: "" });
	const [permissions, setPermissions] = useState({});
	const [collapses, setCollapses] = useState({});

	const [adminAddRole, { isLoading, error: resErr }] =
		useAdminAddRoleMutation();

	const { data: permissionsData } = useGetAllPermissionsQuery(null, {
		refetchOnMountOrArgChange: true,
		refetchOnReconnect: true,
	});

	const data = permissionsData?.data;

	useEffect(() => {
		if (data) {
			const initialCollapses = {};
			Object.keys(data).forEach((group, index) => {
				initialCollapses[`collapse${index + 1}`] = true;
			});
			setCollapses(initialCollapses);
			const initialPermissions = {};
			Object.keys(data).forEach((group) => {
				initialPermissions[group] = {
					all: false,
					...data[group].reduce((acc, permission) => {
						acc[permission.name] = false;
						return acc;
					}, {}),
				};
			});
			setPermissions(initialPermissions);
		}
	}, [data]);

	const toggleCollapse = (collapseKey) => {
		setCollapses((prevCollapses) => ({
			...prevCollapses,
			[collapseKey]: !prevCollapses[collapseKey],
		}));
	};
	const handleCheckboxChange = (group, permission, isAllCheckbox) => {
		setPermissions((prevPermissions) => {
			const updatedPermissions = {
				...prevPermissions,
				[group]: {
					...prevPermissions[group],
					[permission]: isAllCheckbox,
				},
			};
			updatedPermissions[group]["all"] = Object.values(
				updatedPermissions[group]
			).every(Boolean);

			// Check if all child checkboxes are checked and update the parent checkbox
			const allChildChecked = Object.values(updatedPermissions[group])
				.slice(1)
				.every(Boolean);
			updatedPermissions[group]["all"] = allChildChecked;

			return updatedPermissions;
		});
	};

	const handleGroupCheckboxChange = (group) => {
		const groupCheckbox = document.getElementById(`group-${group}`);
		const childCheckboxes = document.getElementsByClassName(
			`${group}-checkbox`
		);

		const allChecked = groupCheckbox.checked;

		for (let x in permissions[group]) {
			handleCheckboxChange(group, x, allChecked);
		}

		// Update the state to reflect the changes even if the collapse is closed
		setPermissions((prevPermissions) => ({
			...prevPermissions,
			[group]: {
				...prevPermissions[group],
				all: allChecked,
			},
		}));
	};

	const renderCheckboxes = (group, permissions) => {
		return (
			<div key={group} className="check-tree">
				<div className="d-flex align-items-center node">
					<a
						onClick={() => toggleCollapse(`collapse${group + 1}`)}
						role="button"
						className={`collapsed ${collapses[`collapse${group + 1}`] ? "show" : ""
							}`}
					></a>
					<div className="custom-control custom-checkbox mr-2 d-flex align-items-center">
						<input
							type="checkbox"
							id={`group-${group}`}
							className="custom-control-input"
							onChange={() => handleGroupCheckboxChange(group)}
							checked={permissions[group]?.all || false}
						/>
						<label
							htmlFor={`group-${group}`}
							className="custom-control-label m-0"
						>
							{group}
						</label>
					</div>
				</div>
				<Collapse
					in={collapses[`collapse${group + 1}`]}
					timeout="auto"
					unmountOnExit
				>
					<div
						id={`collapse-${group}`}
						className={`check-tree-child collapse show`}
					>
						{data[group] &&
							data[group].map((permission) => (
								<div key={permission.name} className="node">
									<div className="custom-control custom-checkbox mr-2 d-flex align-items-center">
										<input
											type="checkbox"
											id={`permission-${permission.name}`}
											className={`custom-control-input ${group}-checkbox`}
											onChange={(e) =>
												handleCheckboxChange(
													group,
													permission.name,
													e.target.checked
												)
											}
											checked={permissions[group][permission.name] || false}
										/>
										<label
											htmlFor={`permission-${permission.name}`}
											className="custom-control-label m-0"
										>
											{permission.label}
										</label>
									</div>
								</div>
							))}
					</div>
				</Collapse>
			</div>
		);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const roleName = event.target.elements.role.value;
		const selectedPermissions = [];
		Object.keys(permissions).forEach((group) => {
			Object.keys(permissions[group]).forEach((permission) => {
				if (permissions[group][permission] && permission !== "all") {
					selectedPermissions.push(permission);
				}
			});
		});
		const rolePayload = {
			role: roleName,
			permissions: selectedPermissions,
		};
		try {
			const res = await adminAddRole({ rolePayload });
			if (res.error && res.error.data.errors) {
				const errors = res.error.data.errors;
				setError({ data: errors });
				if (errors.length > 4) {
					toast.error("Please fill in all the required fields.");
				} else {
					errors.forEach((err) => toast.error(err.message));
				}
				setError({
					fld: res.error.data.errors.map((err) => err.field) || "",
					msg: res.error.data.errors.map((err) => err.message) || "",
				});
			}
			if (res.error) {
				return (
					<div id="route-view" className="col-md-12">
						{res?.error?.data?.msg}
					</div>
				);
			} else {
				if (res.data.success === true) {
					toast.success(res.data.msg);
					navigate("/admin/roles");
				} else {
					setError({
						msg: res.data.msg,
					});
				}
			}
		} catch (error) {
			console.error("Error adding role:", error);
			setError({ msg: "Error adding role. Please try again." });
		}
	};

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">Add Role</h2>
				<form id="roleAddForm" onSubmit={handleSubmit} className="form-panel">
					<div className="form-group">
						<label className="required">Name</label>
						<input
							required="required"
							name="role"
							className="form-control border border-danger"
						/>
						<small className="form-text info-text">
							Eg: Administrator, Manager, Employee, etc
						</small>
					</div>
					<div className="form-group">
						<label className="required">Assign Permissions</label>
						{Object.keys(permissions).map((group) =>
							renderCheckboxes(group, permissions)
						)}
					</div>
					<div className="form-submit-bar">
						<div>
							<Link
								to={"/admin/roles"}
								className="btn btn-sm btn-light text-muted mr-2 px-3"
							>
								Cancel
							</Link>
							<button type="submit" className="mr-2 px-3 btn btn-sm btn-submit">
								{isLoading ? "Adding..." : "Add"}
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AddRoleForm;
