import React from "react";
import { Link, useParams } from "react-router-dom";
import { useAdminGetPublisherQuery } from "../../../../slices/apiSlices/adminApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const PublisherSnapshot = () => {
	const { publisherId } = useParams();
	const { data, isSuccess, isLoading, error } =
		useAdminGetPublisherQuery({
			publisherId,
		}, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});

	if (isLoading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>Error: {error?.data?.msg}</div>;
	}

	if (!isSuccess) {
		return <div>Unable to fetch data.</div>;
	}

	const publisherData = data && data?.data[0];

	const formatCreationDate = (dateString) => {
		const formattedDate = new Date(dateString).toLocaleString(); // Adjust this as per your desired date format
		return formattedDate;
	};

	console.log(publisherData);

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">
					Publisher Account Snapshot ( {publisherData?.name} )
				</h2>
				<div className="my-account-main">
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Account Info</span>
								</div>
								<div className="right">
									<Link to={`/admin/publisher-accounts/edit/${publisherId}`}>
										<i className="fas fa-edit"></i> Edit
									</Link>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">ID</div>
									<div className="right">{publisherData?._id}</div>
								</div>
								<div className="body-row">
									<div className="left">Name</div>
									<div className="right">{publisherData?.name}</div>
								</div>
								<div className="body-row">
									<div className="left">Status</div>
									<div className="right">
										<div>
											{publisherData?.status === "active" ? (
												<div>
													<FontAwesomeIcon
														data-toggle="dropdown"
														className="border-0 text-success"
														icon={faCircle}
													/>
													Active
												</div>
											) : (
												<div>
													<FontAwesomeIcon
														data-toggle="dropdown"
														className="border-0 text-warning"
														icon={faCircle}
													/>
													Pending
												</div>
											)}
										</div>
									</div>
								</div>

								<div className="body-row">
									<div className="left">Total Advertisers</div>
									<div className="right">
										{publisherData?.users ? (
											publisherData?.users
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Creation Date</div>
									<div className="right">
										{formatCreationDate(publisherData?.createdAt)}
									</div>
								</div>
							</div>
						</div>
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Location</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Country</div>
									<div className="right">
										{publisherData?.country}
										<small> - {publisherData?.flag}</small>
									</div>
								</div>
								<div className="body-row">
									<div className="left">Address 1</div>
									<div className="right">{publisherData?.address_1}</div>
								</div>
								<div className="body-row">
									<div className="left">Address 2</div>
									<div className="right">{publisherData?.address_2}</div>
								</div>
								<div className="body-row">
									<div className="left">State</div>
									<div className="right">{publisherData?.state}</div>
								</div>
								<div className="body-row">
									<div className="left">City</div>
									<div className="right">{publisherData?.city}</div>
								</div>
								<div className="body-row">
									<div className="left">Postal Code</div>
									<div className="right">{publisherData?.zipcode}</div>
								</div>
							</div>
						</div>
					</div>
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Account Manager</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">ID</div>
									<div className="right">
										{publisherData?.manager_data?._id}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Name</div>
									<div className="right">
										Mr. {publisherData?.manager_data?.name}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Role</div>
									<div className="right">{publisherData?.role_data?.role}</div>
								</div>
								<div className="body-row">
									<div className="left">Permanent Number</div>
									<div className="right">
										{publisherData?.manager_data?.cell_phone ? (
											publisherData?.manager_data?.cell_phone
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Work Number</div>
									<div className="right">
										{publisherData?.manager_data?.work_phone ? (
											publisherData?.manager_data.work_phone
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">IM</div>
									<div className="right">

										{publisherData?.manager_data?.im ? (
											publisherData?.manager_data.im
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
								<div className="body-row">
									<div className="left">IM Identifier/Username</div>
									<div className="right">

										{publisherData?.manager_data?.im_identifier ? (
											publisherData?.manager_data.im_identifier
										) : (
											<i className="text-muted small">N/A</i>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Postback</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Postback Url</div>
									<div className="right" style={{ wordBreak: 'break-all', wordWrap: 'break-word' }}>
										{publisherData?.postback_url}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="my-account-main-full">
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Publisher Users</span>
								</div>
								<div className="right">
									<Link
										to={`/admin/publisher-accounts/publishers/${publisherId}`}
									>
										<i className="fas fa-edit"></i> Show All
									</Link>
								</div>
							</div>

							<div className="body">
								<div className="body-row-counter">
									<div className="circle-counter">
										<div className="circle active">
											<span className="count">{publisherData?.activeCount}</span>
											<span className="text">Active</span>
										</div>
										<div className="circle pending">
											<span className="count">{publisherData?.pendingCount}</span>
											<span className="text">Pending</span>
										</div>
										<div className="circle inactive">
											<span className="count">{publisherData?.inactiveCount}</span>
											<span className="text">Inactive</span>
										</div>
										<div className="circle suspended">
											<span className="count">{publisherData?.suspendedCount}</span>
											<span className="text">Suspended</span>
										</div>
										<div className="circle primary">
											<span className="count">{publisherData?.total_users}</span>
											<span className="text">Total</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PublisherSnapshot;
