import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const apiUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl + "/publisher/",
  prepareHeaders: (headers, { getState }) => {
    // Access the token from the Redux state
    const data = getState().publisher;
    const token = data?.user?.token
    if (token) {
      // Set the Authorization header for every request
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithInterceptor = async (args, api, extraOptions) => {
  let result;

  try {
    // Pre-request logic (like logging, modifying request)
    // console.log('Request started:', args);

    result = await baseQuery(args, api, extraOptions);

    // Post-request logic (like logging, handling responses)
    // console.log('Request completed:', result);

    // Handle custom logic for certain responses (e.g., automatic logout on 401)
    if (result.error && result.error.status === 401) {
      // Perform custom action like logging out
      // api.dispatch(logout());
    }

  } catch (error) {
    console.error('Request failed:', error);
  }

  return result;
};


export const publisherApi = createApi({
  reducerPath: "publisherApi",
  baseQuery: baseQueryWithInterceptor, // Use the baseQuery with prepareHeaders

  endpoints: (builder) => ({
    loginPublisher: builder.mutation({
      query: (publisher) => {
        return {
          url: "login",
          method: "POST",
          body: publisher,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    getLoggedPublisher: builder.query({
      query: () => {
        return {
          url: `logged-publisher`,
          method: "GET",
        };
      },
    }),

    changePublisherPassword: builder.mutation({
      query: ({ changedPassword, userId }) => {
        return {
          url: `password_change/${userId}`,
          method: "PATCH",
          body: changedPassword,
        };
      },
    }),

    getNotifications: builder.query({
      query: ({ limit, offset, status }) => {
        return {
          url: `notifications?limit=${limit}&offset=${offset}&status=${status}`,
          method: "GET",
        };
      },
    }),
    readNotification: builder.mutation({
      query: (notificationId) => ({
        url: `notifications/read/${notificationId}`,
        method: "PATCH",
      }),
    }),
    readAllNotification: builder.mutation({
      query: () => ({
        url: `notifications/read/all`,
        method: "PATCH",
      }),
    }),

    publisherGetAllOffers: builder.query({
      query: ({
        media_type_id,
        verticle_id,
        price_format_id,
        device_id,
        country,
        status,
      }) => {
        const queryParams = new URLSearchParams({
          media_type_id: media_type_id || "",
          verticle_id: verticle_id || "",
          price_format_id: price_format_id || "",
          device_id: device_id || "",
          country: country || "",
          status: status || "",
        });
        return {
          url: `offers?${queryParams.toString()}`,
          method: "GET",
        };
      },
    }),

    publisherGetOffer: builder.query({
      query: (offerId) => {
        return {
          url: `offers/${offerId}`,
          method: "GET",
        };
      },
    }),
    GetPublisher: builder.query({
      query: (userId) => {
        return {
          url: `accounts/${userId}`,
          method: "GET",
        };
      },
    }),
    getDashboardData: builder.query({
      query: ({ startDate, endDate, timeUnit }) => {
        return {
          url: `dashboard/${timeUnit}`,
          method: "GET",
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        };
      },
    }),
    getClicks: builder.query({
      query: () => {
        return {
          url: `clicks`,
          method: "GET",
        };
      },
    }),
    applyOffer: builder.mutation({
      query: (offer) => {
        return {
          url: "apply_offer",
          method: "POST",
          body: offer,
        };
      },
    }),
    addPaymentMethod: builder.mutation({
      query: (formData) => {
        return {
          url: "payment-methods/update",
          method: "PUT",
          body: formData,
        };
      },
    }),

    getPaymentMethods: builder.query({
      query: () => {
        return {
          url: `payment-methods/all`,
          method: "GET",
        };
      },
    }),
    getPaymentMethod: builder.query({
      query: () => {
        return {
          url: `payment-methods/get`,
          method: "GET",
        };
      },
    }),
    getPaymentHistory: builder.query({
      query: () => {
        return {
          url: `payment-history`,
          method: "GET",
        };
      },
    }),
    getgraphData: builder.query({
      query: ({ startDate, endDate }) => {
        return {
          url: `graph`,
          method: "GET",
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        };
      },
    }),
    addPostBackUrl: builder.mutation({
      query: (formData) => {
        return {
          url: "postback_url",
          method: "PATCH",
          body: formData,
        };
      },
    }),
    getPostBackUrl: builder.query({
      query: () => {
        return {
          url: `postback_url`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useLoginPublisherMutation,
  useGetLoggedPublisherQuery,
  useChangePublisherPasswordMutation,
  useGetNotificationsQuery,
  useReadAllNotificationMutation,
  useReadNotificationMutation,
  usePublisherGetAllOffersQuery,
  usePublisherGetOfferQuery,
  useGetPublisherQuery,
  useGetDashboardDataQuery,
  useGetClicksQuery,
  useApplyOfferMutation,
  useAddPaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useGetPaymentMethodQuery,
  useGetPaymentHistoryQuery,
  useGetgraphDataQuery,
  useAddPostBackUrlMutation,
  useGetPostBackUrlQuery
} = publisherApi;