import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../../components/common/Layout';
import ErrorPage from '../../components/common/Errorpage/index';
import Notifications from '../../components/common/Layout/Notification/Notifictions';
import Dashboard from '../../components/panels/advertiser/Dashboard';
import Offers from '../../components/panels/advertiser/Offers/Offers';
import OfferSnapshot from '../../components/panels/advertiser/Offers/OfferSnapshot';
import ResourceTabs from '../../components/panels/advertiser/Offers/ResourceTabs';
import Clicks from '../../components/panels/advertiser/Reporting/Clicks';
import MyAccount from '../../components/panels/advertiser/ControlPanel/MyAccount';
import AddOffers from '../../components/panels/advertiser/Offers/AddOffers';
import OfferUpdate from '../../components/panels/advertiser/Offers/OfferUpdate';


const AdvertiserRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="offer" element={<Offers />} />
        <Route path="offer/add" element={<AddOffers />} />
        <Route path="offer/edit/:offerId" element={<OfferUpdate />} />
        <Route path="offer/snapshot/:offerId" element={<OfferSnapshot />} />
        <Route path="offer/snapshot/:offerId/resources" element={<ResourceTabs />} />
        <Route path="account/:userId" element={<MyAccount />} />
        <Route path="reporting/clicks" element={<Clicks />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};

export default AdvertiserRoutes;
