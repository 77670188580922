import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import {
	Button,
	Modal,
	TextField,
	FormControlLabel,
	Checkbox,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
	useAdminPublisherGetUserQuery,
	useUpdateBillingCycleMutation,
} from "../../../../../slices/apiSlices/adminApi";
import { Link, useParams } from "react-router-dom";

const paymentOptions = [
	{
		value: "net_30",
		label: "Net 30",
	},
	{
		value: "net_15",
		label: "Net 15",
	},
	{
		value: "net_7",
		label: "Net 7",
	},
	{ value: "monthly", label: "Monthly" },
	{ value: "by_weekly", label: "By-weekly" },
	{ value: "weekly", label: "Weekly" },
	{ value: "daily", label: "Daily" },
];

const PublisherViewUser = () => {
	const { userId } = useParams();
	const [openPasswordModal, setOpenPasswordModal] = useState(false);
	const [openPaymentModal, setOpenPaymentModal] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [paymentCycle, setPaymentCycle] = useState("");
	const { data, isSuccess, refetch, isLoading, error } =
		useAdminPublisherGetUserQuery({
			userId,
		}, {
			refetchOnMountOrArgChange: true,
			refetchOnReconnect: true,
		});
	const [updateBillingCycle, { isLoading: UpdateLoading }] =
		useUpdateBillingCycleMutation();

	if (isLoading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>Error: {error?.data?.msg}</div>;
	}

	if (!isSuccess) {
		return <div>Unable to fetch data.</div>;
	}

	const allData = data.data;
	const methodData = data?.data?.payment_method || "n/a";
	const pubAccData = data?.data?.PublisherAccountData || "n/a";

	const formatCreationDate = (dateString) => {
		const formattedDate = new Date(dateString).toLocaleString(); // Adjust this as per your desired date format
		return formattedDate;
	};
	const handlePasswordModalOpen = () => {
		setOpenPasswordModal(true);
	};

	const handlePasswordModalClose = () => {
		setOpenPasswordModal(false);
	};

	const handlePaymentModalOpen = () => {
		setOpenPaymentModal(true);
	};

	const handlePaymentModalClose = () => {
		setOpenPaymentModal(false);
	};

	const handlePasswordSubmit = (e) => {
		e.preventDefault();
		// Handle password change logic here
		handlePasswordModalClose();
	};
	const handlePaymentSubmit = async (e) => {
		e.preventDefault();
		const formData = {
			billing_cycle: paymentCycle,
			publisher_id: userId,
		};

		// Handle payment cycle change logic here
		const res = await updateBillingCycle({ formData });
		if (res.data.success === true) {
			refetch();
			toast.success(res.data.msg);
		} else {
			toast.error(res?.data?.msg || "error occurer!!");
		}
		handlePaymentModalClose();
	};

	const formatKey = (key) => {
		return key
			.split("_")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	};
	const formatBillingCycle = (cycle) => {
		if (cycle.startsWith("net")) {
			// Keep the number for "net" cycles
			return cycle
				.replace(/_/g, " ")
				.replace(/-/g, " ")
				.replace(/\b\w/g, (c) => c.toUpperCase());
		} else {
			// Remove numeric digits for other cycles
			return cycle
				.replace(/[0-9]/g, "") // Remove digits
				.replace(/_/g, " ")
				.replace(/-/g, " ")
				.replace(/\b\w/g, (c) => c.toUpperCase())
				.trim(); // Remove any leading/trailing whitespace
		}
	};

	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">
					Publisher User Snapshot ( {pubAccData?.name} )
				</h2>
				<div className="my-account-main">
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Account Info</span>
								</div>
								<div className="right">
									<Link
										to={`/admin/publisher-accounts/publishers/edit/${userId}`}
									>
										<i className="fas fa-edit"></i> Edit
									</Link>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">ID</div>

									<div className="right">{allData?._id}</div>
								</div>
								<div className="body-row">
									<div className="left">Name</div>

									<div className="right">
										{allData.title}. {allData.name}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Email</div>
									<div className="right">{allData.email}</div>
								</div>
								<div className="body-row">
									<div className="left">Status</div>
									<div className="right">
										<i className="fas fa-circle text-success border-0 small"></i>{" "}
										{allData.status}
									</div>
								</div>
								<div className="body-row">
									<div className="left">Creation Date</div>

									<div className="right">
										{formatCreationDate(allData.createdAt)}
									</div>
								</div>
							</div>
						</div>
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Location</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Country</div>

									<div className="right">
										{pubAccData?.country} <small>-{pubAccData?.flag}</small>
									</div>
								</div>
								<div className="body-row">
									<div className="left">Address 1</div>

									<div className="right">{pubAccData.address_1}</div>
								</div>
								<div className="body-row">
									<div className="left">Address 2</div>
									<div className="right">{pubAccData.address_2}</div>
								</div>
								<div className="body-row">
									<div className="left">State</div>

									<div className="right">{pubAccData.state}</div>
								</div>
								<div className="body-row">
									<div className="left">City</div>

									<div className="right">{pubAccData.city}</div>
								</div>
								<div className="body-row">
									<div className="left">Postal Code</div>

									<div className="right">{pubAccData.zipcode}</div>
								</div>
							</div>
						</div>
					</div>
					<div className="my-account">
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Quick Links</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Password</div>

									<div>
										<Button
											onClick={handlePasswordModalOpen}
											variant="text"
											color="primary"
										>
											Change Password
										</Button>
									</div>
								</div>
							</div>
						</div>
						<div className="account shadow">
							<div className="head">
								<div className="left">
									<span>Contact Info</span>
								</div>
							</div>
							<div className="body">
								<div className="body-row">
									<div className="left">Permanent Number</div>
									<div className="right">{allData.cell_phone}</div>
								</div>
								<div className="body-row">
									<div className="left">Work Number</div>
									<div className="right">{allData.work_phone}</div>
								</div>
								<div className="body-row">
									<div className="left">IM</div>
									<div className="right">
										<i className="text-muted small">N/A</i>
									</div>
								</div>
								<div className="body-row">
									<div className="left">IM Identifier/Username</div>
									<div className="right">
										<i className="text-muted small">N/A</i>
									</div>
								</div>
							</div>
						</div>
						{methodData && (
							<div className="account shadow">
								<div className="head">
									<div className="left">
										<span>Payment Method</span>
									</div>
									<div className="right">
										<Link
											to={`/admin/publisher-accounts/publishers/payment-history/${userId}`}
										>
											<i className="fas fa-edit"></i>Check Payment History
										</Link>
									</div>
								</div>
								<div className="body">
									{Object.keys(methodData).map((key) =>
										key !== "details" ? (
											<div className="body-row" key={key}>
												<div className="left">{formatKey(key)}</div>
												<div className="right">
													{key === "billing_cycle"
														? formatBillingCycle(methodData[key])
														: methodData[key]}
												</div>
											</div>
										) : (
											Object.keys(methodData.details).map((detailKey) => (
												<div className="body-row" key={detailKey}>
													<div className="left">{formatKey(detailKey)}</div>
													<div className="right">
														{detailKey === "billing_cycle"
															? formatBillingCycle(
																methodData.details[detailKey]
															)
															: methodData.details[detailKey]}
													</div>
												</div>
											))
										)
									)}
									{/* <div className="body-row">
										<div className="left">Payment Cycle</div>
										<div className="right">Net 30 </div>
									</div> */}
									<div className="body-row">
										<div className="left"> </div>
										<div className="right">
											<Button
												onClick={handlePaymentModalOpen}
												variant="text"
												color="primary"
											>
												Change Payment Cycle
											</Button>{" "}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<Modal
					open={openPasswordModal}
					onClose={handlePasswordModalClose}
					aria-labelledby="exampleModalCenterTitle"
					aria-describedby="passwordChangeModal"
				>
					<div role="document" className="modal-dialog modal-dialog-centered">
						<div className="modal-content rounded-0 border-0 shadow">
							<div className="modal-header">
								<h5 id="exampleModalCenterTitle">Change Password</h5>
								<Button onClick={handlePasswordModalClose} className="close">
									<FontAwesomeIcon icon={faTimes} />
								</Button>
							</div>
							<form onSubmit={handlePasswordSubmit}>
								<div className="modal-body px-5 py-4">
									<TextField
										type={showPassword ? "text" : "password"}
										label="New Password"
										fullWidth
										required
										helperText="Minimum 8 characters are required."
									/>
									<FormControlLabel
										control={<Checkbox id="cb-pass" />}
										label="Show Password"
										onClick={() => setShowPassword(!showPassword)}
									/>
								</div>
								<div className="modal-footer">
									<Button
										onClick={handlePasswordModalClose}
										style={{ backgroundColor: "#6c757d", color: "white" }}
									>
										Cancel
									</Button>{" "}
									<Button
										type="submit"
										variant="contained"
										style={{
											backgroundColor: "rgba(96, 155, 189, 0.9)",
											color: "white",
										}}
									>
										Change
									</Button>
								</div>
							</form>
						</div>
					</div>
				</Modal>
				<Modal
					open={openPaymentModal}
					onClose={handlePaymentModalClose}
					aria-labelledby="paymentModalTitle"
					aria-describedby="paymentModalDescription"
				>
					<div role="document" className="modal-dialog modal-dialog-centered">
						<div className="modal-content rounded-0 border-0 shadow">
							<div className="modal-header">
								<h5 id="paymentModalTitle">Change Payment Cycle</h5>
								<Button onClick={handlePaymentModalClose} className="close">
									<FontAwesomeIcon icon={faTimes} />
								</Button>
							</div>
							<form onSubmit={handlePaymentSubmit}>
								<div className="modal-body px-5 py-4">
									<FormControl fullWidth>
										<InputLabel id="payment-cycle-label">
											Payment Cycle
										</InputLabel>
										<Select
											labelId="payment-cycle-label"
											id="payment-cycle"
											value={paymentCycle}
											label="Payment Cycle"
											onChange={(e) => setPaymentCycle(e.target.value)}
										>
											{paymentOptions.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
								<div className="modal-footer">
									<Button
										onClick={handlePaymentModalClose}
										style={{ backgroundColor: "#6c757d", color: "white" }}
									>
										Cancel
									</Button>{" "}
									<Button
										type="submit"
										variant="contained"
										style={{
											backgroundColor: "rgba(96, 155, 189, 0.9)",
											color: "white",
										}}
									>
										Change
									</Button>
								</div>
							</form>
						</div>
					</div>
				</Modal>
			</div>
		</div>
	);
};

export default PublisherViewUser;
