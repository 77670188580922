import { createSlice } from '@reduxjs/toolkit';

const publisherSlice = createSlice({
  name: 'publisher',
  initialState: {
    isAuthenticated: false,
    user: null,
    basePath: '/publisher',
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    }
  },
});

export const { login, logout } = publisherSlice.actions;
export default publisherSlice.reducer;
