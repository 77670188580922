import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Layout from '../../components/common/Layout';
import Dashboard from '../../components/panels/admin/Dashboard';
import Offers from '../../components/panels/admin/Offers/Offers';
import AddOffers from '../../components/panels/admin/Offers/AddOffers';
import OfferUpdate from '../../components/panels/admin/Offers/OfferUpdate';
import OfferSnapshot from '../../components/panels/admin/Offers/OfferSnapshot';
import ResourceTabs from '../../components/panels/admin/Offers/ResourceTabs';
import OfferApplications from '../../components/panels/admin/Offers/OfferApplications';
import AllOfferApplications from '../../components/panels/admin/Offers/AllOfferApplications';

import PublisherAccounts from '../../components/panels/admin/PublisherAccounts/PublisherAccounts';
import PublisherSnapshot from '../../components/panels/admin/PublisherAccounts/PublisherSnapshot';
import PublisherUpdate from '../../components/panels/admin/PublisherAccounts/PublisherUpdate';
import AddPublisherAccounts from '../../components/panels/admin/PublisherAccounts/AddPublisherAccounts';

import PublisherUsers from '../../components/panels/admin/PublisherAccounts/PublisherUser/PublisherUsers';
import PublisherAddUser from '../../components/panels/admin/PublisherAccounts/PublisherUser/PublisherAddUser';
import PublisherViewUser from '../../components/panels/admin/PublisherAccounts/PublisherUser/PublisherViewUser';
import PublisherUpdateUser from '../../components/panels/admin/PublisherAccounts/PublisherUser/PublisherUpdateUser';


import PaymentHistory from '../../components/panels/admin/Payment/PaymentHistory';
import DuoPaymentList from '../../components/panels/admin/Payment/DuoPaymentList';

import Advertisers from '../../components/panels/admin/Advertisers/Advertisers';
import AdvertiserUpdate from '../../components/panels/admin/Advertisers/AdvertiserUpdate';
import AdvertiserView from '../../components/panels/admin/Advertisers/AdvertiserView';
import AddAdvertisers from '../../components/panels/admin/Advertisers/AddAdvertisers';

import AdvertiserUsers from '../../components/panels/admin/Advertisers/AdvertiserUsers/AdvertiserUsers';
import AdvertiserAddUser from '../../components/panels/admin/Advertisers/AdvertiserUsers/AdvertiserAddUser';
import AdvertiserUpdateUser from '../../components/panels/admin/Advertisers/AdvertiserUsers/AdvertiserUpdateUser';
import AdvertiserUserView from '../../components/panels/admin/Advertisers/AdvertiserUsers/AdvertiserUserView';


import Clicks from '../../components/panels/admin/Reporting/Clicks';
import ClickDetails from '../../components/panels/admin/Reporting/ClickDetails';
import PublisherByOffer from '../../components/panels/admin/Reporting/PublisherByOffer';
import SalesById from '../../components/panels/admin/Reporting/SalesById';

import Roles from '../../components/panels/admin/ControlPanel/Roles/Roles';
import AddRole from '../../components/panels/admin/ControlPanel/Roles/AddRole';
import UpdateRole from '../../components/panels/admin/ControlPanel/Roles/UpdateRole';


import Accounts from '../../components/panels/admin/ControlPanel/Accounts/Accounts';
import AddAdminAccount from '../../components/panels/admin/ControlPanel/Accounts/AddAdminAccount';
import UpdateAccount from '../../components/panels/admin/ControlPanel/Accounts/UpdateAccount';
import MyAccount from '../../components/panels/admin/ControlPanel/MyAccount/MyAccount';

import Domains from '../../components/panels/admin/TrackingDomains/Domains';
import AddDomain from '../../components/panels/admin/TrackingDomains/AddDomain';
import UpdateDomain from '../../components/panels/admin/TrackingDomains/UpdateDomain';
import DomainSnapshot from '../../components/panels/admin/TrackingDomains/DomainSnapshot';

import Conversions from '../../components/panels/admin/Conversions/Conversions';
import ConversionsSnapshot from '../../components/panels/admin/Conversions/ConversionsSnapshot';
import Notifications from '../../components/common/Layout/Notification/Notifictions';
import ErrorPage from '../../components/common/Errorpage/index';

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="offers" element={<ProtectedRoute component={Offers} requiredPermissions={['offers_view']} />} />
        <Route path="offers/add" element={<ProtectedRoute component={AddOffers} requiredPermissions={['offers_add']} />} />
        <Route path="offers/snapshot/:offerId" element={<ProtectedRoute component={OfferSnapshot} requiredPermissions={['offers_view']} />} />
        <Route path="offers/snapshot/:offerId/resource" element={<ProtectedRoute component={ResourceTabs} requiredPermissions={['offers_view']} />} />
        <Route path="offers/applications/:offerId" element={<ProtectedRoute component={OfferApplications} requiredPermissions={['offers_view']} />} />
        <Route path="offers/allofferapplications" element={<ProtectedRoute component={AllOfferApplications} requiredPermissions={['offers_view']} />} />
        <Route path="offers/edit/:offerId" element={<ProtectedRoute component={OfferUpdate} requiredPermissions={['offers_edit', 'offers_view']} />} />

        <Route path="publisher-accounts" element={<ProtectedRoute component={PublisherAccounts} requiredPermissions={['publishers_view']} />} />
        <Route path="publisher-accounts/:publisherId" element={<ProtectedRoute component={PublisherSnapshot} requiredPermissions={['publishers_view']} />} />
        <Route path="publisher-accounts/edit/:publisherId" element={<ProtectedRoute component={PublisherUpdate} requiredPermissions={['publishers_view', 'publishers_edit']} />} />
        <Route path="publisher-accounts/add" element={<ProtectedRoute component={AddPublisherAccounts} requiredPermissions={['publishers_add']} />} />
        <Route path="publisher-accounts/publishers/:publisherId" element={<ProtectedRoute component={PublisherUsers} requiredPermissions={['publishers_view']} />} />
        <Route path="publisher-accounts/publishers/add/:publisherId" element={<ProtectedRoute component={PublisherAddUser} requiredPermissions={['publishers_add']} />} />
        <Route path="publisher-accounts/publishers/:publisherId/:userId" element={<ProtectedRoute component={PublisherViewUser} requiredPermissions={['publishers_view']} />} />
        <Route path="publisher-accounts/publishers/payment-history/:userId" element={<ProtectedRoute component={PaymentHistory} requiredPermissions={['publishers_view']} />} />
        <Route path="due-payments/:userId" element={<ProtectedRoute component={DuoPaymentList} requiredPermissions={['']} />} />
        <Route path="publisher-accounts/publishers/edit/:userId" element={<ProtectedRoute component={PublisherUpdateUser} requiredPermissions={['publishers_view', 'publishers_edit']} />} />


        <Route path="advertisers" element={<ProtectedRoute component={Advertisers} requiredPermissions={['advertisers_view']} />} />
        <Route path="advertisers/add" element={<ProtectedRoute component={AddAdvertisers} requiredPermissions={['advertisers_add']} />} />
        <Route path="advertisers/:advertiserId" element={<ProtectedRoute component={AdvertiserView} requiredPermissions={['advertisers_view']} />} />
        <Route path="advertisers/edit/:advertiserId" element={<ProtectedRoute component={AdvertiserUpdate} requiredPermissions={['advertisers_view', 'advertisers_edit']} />} />
        <Route path="advertisers/users/:advertiserId" element={<ProtectedRoute component={AdvertiserUsers} requiredPermissions={['advertisers_view']} />} />
        <Route path="advertisers/users/add/:advertiserId" element={<ProtectedRoute component={AdvertiserAddUser} requiredPermissions={['advertisers_add']} />} />
        <Route path="advertisers/users/:advertiserId/:userId" element={<ProtectedRoute component={AdvertiserUserView} requiredPermissions={['advertisers_view']} />} />
        <Route path="advertisers/users/edit/:advertiserId/:userId" element={<ProtectedRoute component={AdvertiserUpdateUser} requiredPermissions={['advertisers_view', 'advertisers_edit']} />} />

        <Route path="reporting/clicks" element={<Clicks />} />
        <Route path="reporting/clicks/:offerId" element={<PublisherByOffer />} />
        <Route path="reporting/clicksdetails/:clickId" element={<ClickDetails />} />
        <Route path="reporting/clicks/:offerId/:publisherId" element={<SalesById />} />

        <Route path="roles" element={<ProtectedRoute component={Roles} requiredPermissions={['roles_view']} />} />
        <Route path="roles/add" element={<ProtectedRoute component={AddRole} requiredPermissions={['roles_add']} />} />
        <Route path="roles/edit/:roleId" element={<ProtectedRoute component={UpdateRole} requiredPermissions={['roles_view', 'roles_edit']} />} />

        <Route path="accounts" element={<ProtectedRoute component={Accounts} requiredPermissions={['users_view']} />} />
        <Route path="account/:userId" element={<MyAccount />} />
        <Route path="account/edit/:userId" element={<ProtectedRoute component={UpdateAccount} requiredPermissions={['users_view', 'users_edit']} />} />
        <Route path="accounts/add" element={<ProtectedRoute component={AddAdminAccount} requiredPermissions={['users_add']} />} />

        <Route path="tracking-domains" element={<ProtectedRoute component={Domains} requiredPermissions={['tracking_domains_view']} />} />
        <Route path="tracking-domains/:Id" element={<ProtectedRoute component={DomainSnapshot} requiredPermissions={['tracking_domains_view']} />} />
        <Route path="tracking-domains/edit/:Id" element={<ProtectedRoute component={UpdateDomain} requiredPermissions={['tracking_domains_view', 'tracking_domains_edit']} />} />
        <Route path="tracking-domains/add" element={<ProtectedRoute component={AddDomain} requiredPermissions={['tracking_domains_add']} />} />

        <Route path="conversions" element={<ProtectedRoute component={Conversions} requiredPermissions={['conversions_view']} />} />
        <Route path="conversions/:Id" element={<ProtectedRoute component={ConversionsSnapshot} requiredPermissions={['conversions_view']} />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;

