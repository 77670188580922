import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { CircularProgress } from "@mui/material";
import { setUnReadCount } from "../../../../slices/customSlices/commonSlice";
import { timeAgo } from "../../../../utils/Utility";

import {
	useGetNotificationsQuery as useAdminNotificationsQuery,
	useReadAllNotificationMutation as useAdminReadAllNotificationMutation,
	useReadNotificationMutation as useAdminReadNotificationMutation
} from "../../../../slices/apiSlices/adminApi";

import {
	useGetNotificationsQuery as useAdvertiserNotificationsQuery,
	useReadAllNotificationMutation as useAdvertiserReadAllNotificationMutation,
	useReadNotificationMutation as useAdvertiserReadNotificationMutation
} from "../../../../slices/apiSlices/advertiserApi";

import {
	useGetNotificationsQuery as usePublisherNotificationsQuery,
	useReadAllNotificationMutation as usePublisherReadAllNotificationMutation,
	useReadNotificationMutation as usePublisherReadNotificationMutation
} from "../../../../slices/apiSlices/publisherApi";

const NotificationsBox = () => {
	const location = useLocation();
	const currentPath = location.pathname;
	const user = currentPath.split('/')[1];

	const unreadCount = useSelector((state) => state.common.unReadCount);
	const dispatch = useDispatch();
	const [totalNotifications, setTotalNotifications] = useState(0);
	const [onAll, setOnAll] = useState(true);
	const [params, setParams] = useState({
		limit: 10,
		offset: 0,
		status: undefined,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);


	const {
		data,
		refetch,
	} = (user === "admin" ? useAdminNotificationsQuery :
		user === "advertiser" ? useAdvertiserNotificationsQuery :
			usePublisherNotificationsQuery)(params, {
				refetchOnMountOrArgChange: true,
				refetchOnReconnect: true,
				pollingInterval: 10000,
			});

	const [readNotification] = (user === "admin" ? useAdminReadNotificationMutation :
		user === "advertiser" ? useAdvertiserReadNotificationMutation :
			usePublisherReadNotificationMutation)();

	const [readAllNotification] = (user === "admin" ? useAdminReadAllNotificationMutation :
		user === "advertiser" ? useAdvertiserReadAllNotificationMutation :
			usePublisherReadAllNotificationMutation)();

	const notificationDropRef = useRef(null);
	const bellIconRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [notifications, setNotifications] = useState([]);

	const fetchMore = useCallback(() => {
		if (totalNotifications === 0 || notifications.length >= totalNotifications)
			return;

		setIsLoading(true);
		setError(null);
		setTimeout(() => {
			setIsLoading(false);
			try {
				setParams((prevParams) => ({
					...prevParams,
					limit: prevParams.limit + 10,
				}));
			} catch (error) {
				setError(error);
			}
		}, 1500);
	}, [notifications.length, totalNotifications]);

	const openNotifications = useCallback(() => {
		setIsOpen((prevOpen) => !prevOpen);
	}, []);

	const closeNotifications = useCallback(
		(event) => {
			if (
				isOpen &&
				notificationDropRef.current &&
				!notificationDropRef.current.contains(event.target) &&
				!bellIconRef.current.contains(event.target)
			) {
				setIsOpen(false);
			}
		},
		[isOpen]
	);

	useEffect(() => {
		document.addEventListener("click", closeNotifications);
		return () => {
			document.removeEventListener("click", closeNotifications);
		};
	}, [closeNotifications]);

	useEffect(() => {
		const fetchData = () => {
			setNotifications(data?.data?.notifications || []);
			dispatch(setUnReadCount(data?.data?.unreadCount));
			if (data) {
				setTotalNotifications(
					onAll ? data?.data?.total || 0 : data?.data?.unreadCount
				);
			}
		};
		fetchData();
	}, [data, onAll]);

	const handleScroll = useCallback(() => {
		const boxDiv = document.getElementById("scrollable-notifications");
		if (
			boxDiv.scrollTop + boxDiv.clientHeight >= boxDiv.scrollHeight &&
			!isLoading
		) {
			fetchMore();
		}
	}, [isLoading, fetchMore]);

	useEffect(() => {
		const boxdiv = document.getElementById("scrollable-notifications");
		boxdiv.addEventListener("scroll", handleScroll);
		return () => {
			boxdiv.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll]);

	const handleFilter = useCallback(
		(filter) => {
			setOnAll(filter === "");
			setParams((prevParams) => ({ ...prevParams, status: filter }));
			fetchMore();
		},
		[fetchMore]
	);

	const handleReadAll = useCallback(async () => {
		if (data?.data?.unreadCount > 0) {
			const confirmation = window.confirm(
				"Are you sure you want to mark all notifications as read?"
			);
			if (confirmation) {
				await readAllNotification();
				refetch();
			}
		} else {
			alert("All notifications are already read.");
		}
	}, [dispatch, readAllNotification, refetch]);

	const markAsRead = useCallback(
		async (notificationId) => {
			const updatedNotifications = notifications.map((notification) => {
				if (
					notification._id === notificationId &&
					notification.read_at === null
				) {
					try {
						readNotification(notificationId);
						refetch();
					} catch (error) {
						console.error("Error updating read status:", error);
					}
				}
				return notification;
			});
			await Promise.all(updatedNotifications);
			setIsOpen(false);
		},
		[dispatch, notifications, readNotification]
	);


	return (
		<div className="position-relative">
			<span
				onClick={openNotifications}
				ref={bellIconRef}
				className="icon"
				id="notification-btn"
			>
				<FontAwesomeIcon
					color="#609bbd"
					style={{ fontSize: "25px" }}
					icon={faBell}
				/>
				{unreadCount > 0 && (
					<span className="badge c-badge badge-danger badge-pill position-absolute top-0 start-100 translate-middle">
						{unreadCount}
					</span>
				)}
			</span>
			<div
				className={`notification-area dropdown-menu-right ${isOpen ? "active" : ""
					}`}
				ref={notificationDropRef}
			>
				<div>
					<div className="notification-head">
						<div className="notification-filters">
							<div
								className={`all-notifications ${onAll ? "selected-color" : ""}`}
								onClick={() => handleFilter("")}
							>
								All
							</div>
							<div
								className={`Unread-notifications ${onAll ? "" : "selected-color"
									} `}
								onClick={() => handleFilter("unread")}
							>
								Unread
							</div>
						</div>
						{
							<div className="read-all" onClick={handleReadAll}>
								Read All
							</div>
						}
					</div>
					<div
						id="scrollable-notifications"
						className="scrollable-notifications"
						style={{
							maxHeight: "420px",
							minHeight: "420px",
							overflowY: "auto",
						}}
					>
						{notifications.length === 0 ? (
							<div className="no-notifications">No Notifications</div>
						) : (
							<div style={{ marginBottom: "20px" }}>
								{notifications?.map((notification, index) => (
									<Link
										to={notification.link_to}
										onClick={(e) => {
											markAsRead(notification._id, e);
										}}
										style={{ textDecoration: "none" }}
										key={index}
									>
										<div
											className={`single-notification card ${notification.read_at !== null
												? ""
												: "notification-unread"
												}`}
											style={{ border: "none" }}
										>
											<div className={`card-body `}>
												<p
													className="card-text"
													style={{
														fontWeight: "700",
														fontFamily: "sans-serif",
														color: "#181818",
													}}
												>
													{notification.message}
												</p>

												<p className="notification-time">
													{timeAgo(notification.createdAt)}
												</p>
											</div>
											<div className="notification-border"></div>
										</div>
									</Link>
								))}
								{isLoading && (
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											padding: "30px",
											marginBottom: "20px",
										}}
									>
										<CircularProgress
											style={{
												color: "#609bbd",
											}}
										/>
									</div>
								)}
								{error && <p>Error: {error.message}</p>}
								<div
									style={{
										position: "absolute",
										bottom: "0",
										backgroundColor: "rgb(233, 236, 239)",
										width: "100%",
										textAlign: "center",
										padding: "2px",
									}}
								>
									<Link
										to={`/${user}/notifications`}
										onClick={() => setIsOpen(false)}
										style={{ textDecoration: "none", color: "#609bbd" }}
									>
										See All Notifications
									</Link>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotificationsBox;
