import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";

function Index() {
  const collapsed = useSelector((state) => state.common.collapsed);
  return (
    <div>
      <Navbar />
      <div className={`sidebar-content ${collapsed ? "collapsed" : ""}`}>
        <div id="sidenav" className={` ${collapsed ? "collapsed" : ""}`}>
          <Sidebar />
        </div>
      </div>
      <div
        id="main-view"
        className="fit-outlet-container">
        <Outlet />
      </div>
    </div>
  );
}

export default Index;
