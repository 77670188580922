import React, { useEffect, useState } from "react";
// import "./Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../../slices/customSlices/commonSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const collapsed = useSelector((state) => state.common.collapsed);

  const [toggleState, setToggleState] = useState({
    dashboard: false,
    offersNav: false,
    publishersNav: false,
    advertisersNav: false,
    analyticsNav: false,
    reportingNav: false,
    controlNav: false,
  });

  useEffect(() => {
    Object.keys(toggleState).forEach((key) => {
      const myCollapse = document.getElementById(key);
      if (myCollapse) {
        const bsCollapse = new Collapse(myCollapse, { toggle: false });
        toggleState[key] ? bsCollapse.show() : bsCollapse.hide();
      }
    });
  }, [toggleState]); // Remove toggleState from here

  useEffect(() => {
    if (collapsed) {
      setToggleState({
        dashboard: false,
        offersNav: false,
        publishersNav: false,
        advertisersNav: false,
        analyticsNav: false,
        reportingNav: false,
        controlNav: false,
      });
    }
  }, [collapsed]);

  const handleToggle = (key) => {
    // if (collapsed) {
    //   dispatch(toggleSidebar());
    // }
    setToggleState((prevState) => {
      const updatedState = {};
      Object.keys(prevState)?.forEach((prevStateKey) => {
        updatedState[prevStateKey] =
          prevStateKey === key ? !prevState[key] : false;
      });
      return updatedState;
    });
  };

  return (
    <div>
      <div id="accordion" style={{ flex: "1 1 0%" }}>
        <div className="card">
          <div className="card-header">
            <Link
              onClick={() => handleToggle("dashboard")}
              to={`/advertiser/dashboard`}
            >
              <i className="custom-icon">
                <img src="/asset/icons/dashboard.svg" />
                {!collapsed && <span>Dashboard</span>}
              </i>
            </Link>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <Link to={`/advertiser/offer`}>
              <i className="custom-icon">
                <img src="/asset/icons/box-open.svg" />
                {!collapsed && <span>Offers</span>}
              </i>
            </Link>
          </div>
        </div>
        <div className="card">
          <div className="card-header folded">
            <a
              href="#"
              data-toggle="collapse"
              data-target="#reportingNav"
              className={toggleState.reportingNav ? "" : "collapsed"}
              onClick={() => {
                handleToggle("reportingNav");
                dispatch(toggleSidebar());
              }}
            >
              <i className="custom-icon">
                <img src="/asset/icons/reporting.svg" />
                {!collapsed && <span>Reporting
                  <FontAwesomeIcon className="arrow" icon={faChevronDown} />
                </span>}
              </i>
            </a>
            <div
              id="reportingNav"
              data-parent="#accordion"
              className="collapse"
            >
              <div className="list-group">
                {/* <a
										href="#"
										className="list-group-item list-group-item-action"
									>
										Offers
									</a>
									<a
										href="#"
										className="list-group-item list-group-item-action"
									>
										Publishers
									</a>
									<Link
										href="#"
										className="list-group-item list-group-item-action"
									>
										Advertisers
									</Link> */}
                <Link
                  to={`/advertiser/reporting/clicks`}
                  exact=""
                  className="list-group-item list-group-item-action"
                >
                  Clicks
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="card">
            <div className="card-header folded">
              <a
                href="#"
                data-toggle="collapse"
                data-target="#controlNav"
                className="collapsed"
                onClick={() => handleToggle("controlNav")}
              >
                <i className="custom-icon">
                  <img src="/asset/icons/control.svg" />
                  <span>Control Panal</span>
                </i>
                <FontAwesomeIcon className="arrow" icon={faChevronDown} />
              </a>
            </div>
          </div> */}
      </div>
      <div id="sidenavFooter" className="">
        <p> ©2019 AdspayingMedia.com</p>
        <FontAwesomeIcon icon={faArrowAltCircleLeft} />
      </div>
    </div>
  );
};

export default Sidebar;
